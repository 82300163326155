import { ActionIcon, Button, Container, Group, Modal, NumberInput, Space, Stack, Text, Tooltip } from '@mantine/core';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Controller, Path, SubmitHandler, useForm } from 'react-hook-form';
import { FaPen, FaSave } from 'react-icons/fa';
import { ICreateUserBodySalaryRates } from '../../../../clientApi/UsersApi/usersApiTypes';
import { useStores } from '../../../../App';
import { actionOnFocusNumberInput } from '../../../../const/utils';

interface IChangeManagerOptionsProps {
  userId: number,
  fullName: string,
  rateFromSales: number,
  rateFromPostSales: number,
}

interface IUpdateManagerOptionsBody {
  rateFromSales: number,
  rateFromPostSales: number,
}

function ChangeManagerOptions({
  userId,
  fullName,
  rateFromSales,
  rateFromPostSales,
}: IChangeManagerOptionsProps) {
  const { usersStore } = useStores();

  const defaultValues = {
    rateFromSales,
    rateFromPostSales,
  };
  const {
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm<IUpdateManagerOptionsBody>({ defaultValues });

  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateManagerOptions: SubmitHandler<IUpdateManagerOptionsBody> = async (data) => {
    setLoading(true);
    const body: ICreateUserBodySalaryRates = {
      rateFromSales: data.rateFromSales,
      rateFromPostSales: data.rateFromPostSales,
      rateFromSaleSources: 0,
      saleSourcesIds: [],
    };
    const errorData = await usersStore.updateUserSalaryRates(userId, body);
    if (errorData === null) {
      setOpened(false);
      usersStore.getUsers();
    } else if (Array.isArray(errorData)) {
      errorData
        .forEach((it) => setError(it[0] as Path<IUpdateManagerOptionsBody>, { message: it[1] }));
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={(
          <Text align="center" weight="bold">Изменение дохода</Text>
        )}
      >
        <Text>{`для пользователя ${fullName}`}</Text>
        <Space h="xl" />
        <form onSubmit={handleSubmit(updateManagerOptions)}>
          <Container>
            <Stack>
              <Controller
                name="rateFromSales"
                control={control}
                rules={{ required: 'Это поле должно быть заполнено' }}
                render={({ field }) => (
                  <NumberInput
                    {...field}
                    label="Процент от продаж"
                    withAsterisk
                    error={errors.rateFromSales?.message}
                    onFocus={actionOnFocusNumberInput}
                  />
                )}
              />
              <Controller
                name="rateFromPostSales"
                control={control}
                rules={{ required: 'Это поле должно быть заполнено' }}
                render={({ field }) => (
                  <NumberInput
                    {...field}
                    label="Процент от допродаж"
                    withAsterisk
                    error={errors.rateFromPostSales?.message}
                    onFocus={actionOnFocusNumberInput}
                  />
                )}
              />
            </Stack>
          </Container>
          <Space h="xl" />
          <Container size="xs" px="xs">
            <Group position="apart">
              <Button variant="subtle" onClick={() => reset(defaultValues)}>
                Сбросить
              </Button>
              <Group>
                <Button variant="subtle" onClick={() => setOpened(false)}>Отмена</Button>
                <Button loading={loading} type="submit" leftIcon={<FaSave />}>Сохранить</Button>
              </Group>
            </Group>
          </Container>
        </form>
      </Modal>
      <Tooltip withArrow label="Редактировать доп. информацию" openDelay={500} closeDelay={100}>
        <ActionIcon color="blue" onClick={() => setOpened(true)}>
          <FaPen size={15} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}

export default observer(ChangeManagerOptions);
