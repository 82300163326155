import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { configure } from 'mobx';
import './index.css';
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import App from './App';
import 'dayjs/locale/ru';

setTimeout(() => configure({
  enforceActions: 'never',
  reactionScheduler: (f) => setTimeout(f),
}));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <MantineProvider
    withNormalizeCSS
    withGlobalStyles
    theme={{
      datesLocale: 'ru',
      dateFormat: 'DD MMM YYYY',
      components: {
        Button: {
          styles: {
            root: {
              '&[data-loading]': { backgroundColor: '#228be6' },
            },
          },
        },
      },
    }}
  >
    <BrowserRouter>
      <NotificationsProvider position="bottom-right" autoClose={3000} zIndex={9999}>
        <App />
      </NotificationsProvider>
    </BrowserRouter>
  </MantineProvider>,
);
