import { Autocomplete, Loader } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { FaSearch } from 'react-icons/fa';
import { requiredMsg } from '../../../../const/const';
import { AutocompleteProductItem } from '../../AutoCompleteItemComponents/AutocompleteProductItem';
import { IUpdateSaleBody } from '../../../../clientApi/SaleApi/saleApiTypes';
import { useStores } from '../../../../App';
import InputClearCross from '../../InputClearCross';

interface ISoldProductNameInputProps {
  required?: boolean,
  i: number,
}

interface IAutocompleteProductData {
  value: string,
  category: {
    name: string,
  },
  id: number,
  name: string,
}

function SoldProductNameInput({
  required,
  i,
}: ISoldProductNameInputProps) {
  const { clientApi: { productsApi } } = useStores();

  const {
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<IUpdateSaleBody>();

  const inputProductName = watch(`soldProducts.${i}.name`);

  const [data, setData] = useState<IAutocompleteProductData[]>([]);
  const [input, setInput] = useState(inputProductName || '');
  const [debounced] = useDebouncedValue(input, 300);
  const [loading, setLoading] = useState(false);
  const [isOnceFocused, setIsOnceFocused] = useState(false);

  async function fetchData(str: string) {
    setLoading(true);
    const [response] = await productsApi.getProductBySearchString({
      query: str,
    });
    if (response !== null) {
      setData(response.products.map((a) => ({
        value: a.name,
        category: {
          name: a.category.name,
        },
        id: a.id,
        name: a.name,
        primaryprice: a.primaryCostByn,
        availableQuantity: a.availableQuantity,
      })));
    }
    setLoading(false);
  }

  useEffect(() => {
    const str = input.trim();
    if (str.length !== 0 && isOnceFocused) {
      fetchData(str);
    }
  }, [debounced]);

  useEffect(() => {
    const str = input?.trim();
    if (str && isOnceFocused) {
      fetchData(str);
    }
  }, [isOnceFocused]);

  return (
    <Controller
      name={`soldProducts.${i}.productId`}
      control={control}
      rules={{ required: required && requiredMsg }}
      render={({ field: { onChange } }) => (
        <Autocomplete
          label="Товар"
          value={input}
          data={data}
          withAsterisk={required}
          limit={10}
          maxDropdownHeight="250px"
          filter={() => true}
          icon={loading ? <Loader size="xs" /> : <FaSearch />}
          error={errors.soldProducts && errors.soldProducts[i]?.productId?.message}
          itemComponent={AutocompleteProductItem}
          onChange={(val) => {
            setInput(val);
            onChange(null);
            setValue(`soldProducts.${i}.primaryPrice`, null);
            setValue(`soldProducts.${i}.name`, val);
            clearErrors('soldProducts');
          }}
          onItemSubmit={(item) => {
            onChange(item.id);
            setValue(`soldProducts.${i}.name`, item.name);
            setValue(`soldProducts.${i}.primaryPrice`, item.primaryprice);
            clearErrors('soldProducts');
          }}
          onFocus={() => setIsOnceFocused(true)}
          rightSection={(
            <InputClearCross action={() => {
              onChange(null);
              setInput('');
              setValue(`soldProducts.${i}.primaryPrice`, null);
              setValue(`soldProducts.${i}.name`, null);
              setData([]);
            }}
            />
          )}
          rightSectionWidth={20}
        />
      )}
    />
  );
}

SoldProductNameInput.defaultProps = {
  required: true,
};

export default observer(SoldProductNameInput);
