import dayjs from 'dayjs';
import { IQueryStringParams } from './types';

export function styleDatePickerActualDay(date: Date) {
  return date.toLocaleDateString() === new Date().toLocaleDateString()
    ? { border: '1px solid #228be6' }
    : {};
}

export function getUrlWithQuery(baseUrl: string, params?: IQueryStringParams) {
  const url = new URL(baseUrl);
  if (params !== undefined) {
    Object.entries(params).forEach((val) => {
      if (val[1] !== undefined && val[1] !== null && val[1] !== '') {
        if (!Array.isArray(val[1])) {
          url.searchParams.append(val[0], val[1].toString());
        } else {
          val[1].forEach((a) => url.searchParams.append(val[0], a.toString()));
        }
      }
    });
  }
  return url.href;
}

export function actionOnFocusNumberInput(event: React.FocusEvent<HTMLInputElement, Element>) {
  setTimeout(() => event.target.setSelectionRange(0, 0));
}

export function downloadFile(blob: Blob, fileName: string) {
  const aElement = document.createElement('a');
  aElement.setAttribute('download', fileName);
  const href = URL.createObjectURL(blob);
  aElement.href = href;
  aElement.setAttribute('target', '_blank');
  aElement.click();
  URL.revokeObjectURL(href);
}

export function getFileNameContentByDateRange(
  dateRangeStart: string | null,
  dateRangeEnd: string | null,
) {
  if (dateRangeStart === dateRangeEnd && dateRangeStart !== null && dateRangeEnd !== null) {
    const date = `за ${dayjs(dateRangeStart).format('DD.MM.YYYY')}`;
    return date;
  }
  const startDate = dateRangeStart === null
    ? ''
    : `с ${dayjs(dateRangeStart).format('DD.MM.YYYY')} `;
  const endDate = dateRangeEnd === null
    ? `по ${dayjs(new Date()).format('DD.MM.YYYY')}`
    : `по ${dayjs(dateRangeEnd).format('DD.MM.YYYY')}`;
  return `${startDate}${endDate}`;
}

export function getFileNameContentForPrintingCheck(
  date: string | null,
  deliveryType: string | null | undefined,
) {
  const startDate = date === null ? '' : dayjs(date).format('DD.MM.YYYY');
  return `${startDate}${deliveryType !== null && deliveryType !== undefined ? `, ${deliveryType}` : ''}`;
}

export function getFileName(
  title: string,
  content: string,
  extension: string,
) {
  return `ERP ${title} ${content}.${extension}`;
}

export function getDateStringForQueryParams(date: Date) {
  return dayjs(date).format('YYYY-MM-DD');
}

export function getSortingDate(date1: string, date2: string) {
  if (new Date(date1) > new Date(date2)) {
    return {
      startDate: new Date(date2),
      endDate: new Date(date1),
    };
  }
  return {
    startDate: new Date(date1),
    endDate: new Date(date2),
  };
}
