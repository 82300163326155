import { Modal, Text, ActionIcon, Tooltip } from '@mantine/core';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { FaPen } from 'react-icons/fa';
import { useStores } from '../../../App';
import { ICashboxItem, IPutResponse, ISendCashboxBody } from '../../../clientApi/CashboxApi/cashboxApiTypes';
import FormWrapper from '../../_Shared/FormElements/FormWrapper';
import AddCashboxItemFormContent from './AddCashboxItemFormContent';

interface IUpdateCashboxItemBtnProps {
  id: number,
  item: ICashboxItem,
  fetchItems: () => void,
}

function UpdateCashboxItemBtn({
  id,
  item,
  fetchItems,
}: IUpdateCashboxItemBtnProps) {
  const { clientApi: { cashboxApi } } = useStores();

  const [isOpened, setIsOpened] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const defaultValues = {
    categoryId: item.categoryId,
    amount: item.amount,
    currencyId: item.currencyId,
    paymentMethodTypeId: item.paymentMethod.id,
    comment: item.comment,
  };

  async function fetchData(data: ISendCashboxBody) {
    setIsFormLoading(true);
    const response = await cashboxApi.putCashbox(data, id);
    setIsFormLoading(false);
    return response;
  }

  async function actionAfterSuccess() {
    setIsOpened(false);
    fetchItems();
  }

  return (
    <>
      <Modal
        opened={isOpened}
        onClose={() => setIsOpened(false)}
        title={(
          <Text align="center" weight="bold">
            Обновление записи в кассе
          </Text>
        )}
      >
        <FormWrapper<ISendCashboxBody, IPutResponse>
          isFormLoading={isFormLoading}
          defaultValues={defaultValues}
          withResetBtn
          fetchData={fetchData}
          actionAfterSuccess={actionAfterSuccess}
          onCancelAction={() => setIsOpened(false)}
        >
          <AddCashboxItemFormContent />
        </FormWrapper>
      </Modal>
      <Tooltip withArrow label="Изменить" openDelay={500} closeDelay={100}>
        <ActionIcon color="blue" onClick={() => setIsOpened(true)}>
          <FaPen size={20} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}

export default observer(UpdateCashboxItemBtn);
