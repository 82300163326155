import { Group } from '@mantine/core';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useReactToPrint } from 'react-to-print';
import { useStores } from '../../../App';
import PageTitle from '../../_Shared/PageTitle';
import UpdateContentBtn from '../../_Shared/UpdateContentBtn';
import DeliveriesCardsWrapper from './DeliveriesForCurrentUserTable/DeliveriesCardsWrapper';
import DeliveriesPrintComponent from './DeliveriesPrintComponent';
import PrintBtn from '../../_Shared/PrintBtn';
import DeliveriesForCurrentUserTable from './DeliveriesForCurrentUserTable/DeliveriesForCurrentUserTable';

function DeliveriesForCurrentUser() {
  const {
    deliveriesForCurrentUserStore: {
      getDeliveriesForCurrentUser,
      isLoading,
      items,
      totalItemsCount,
      onDragEnd,
    },
    profileStore: { profilePermissions: { MyDeliveries: deliveriesPermission } },
  } = useStores();

  useEffect(() => {
    getDeliveriesForCurrentUser();
  }, []);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <PageTitle text="Мои доставки" />
      <div style={{ display: 'none' }}>
        <DeliveriesPrintComponent
          ref={componentRef}
          items={items}
        />
      </div>
      <Group position="right" spacing={4}>
        <UpdateContentBtn
          action={getDeliveriesForCurrentUser}
          isDisabled={isLoading}
        />
        <PrintBtn
          isDisabled={isLoading || items.length === 0}
          onClick={handlePrint}
        />
      </Group>
      <DeliveriesCardsWrapper
        loading={isLoading}
        totalItemsCount={totalItemsCount}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <DeliveriesForCurrentUserTable
            deliveriesPermission={deliveriesPermission}
            items={items}
            fetchItems={getDeliveriesForCurrentUser}
          />
        </DragDropContext>
      </DeliveriesCardsWrapper>
    </>
  );
}

export default observer(DeliveriesForCurrentUser);
