import { Select } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { useStores } from '../../../../App';
import { requiredMsg } from '../../../../const/const';
import SelectUsersItem from '../../AutoCompleteItemComponents/SelectUsersItem';

interface IManagerInputProps {
  required?: boolean,
}

function ManagerInput({
  required,
}: IManagerInputProps) {
  const { usersStore: { selectUsersData } } = useStores();

  const {
    control,
    formState: { errors },
  } = useFormContext<{ managerId: number | null }>();

  return (
    <Controller
      name="managerId"
      control={control}
      rules={{ required: required && requiredMsg }}
      render={({ field: { value, onChange } }) => (
        <Select
          value={value ? value.toString() : null}
          onChange={(val) => onChange(val ? +val : null)}
          clearable
          data={selectUsersData}
          label="Менеджер"
          withAsterisk={required}
          error={errors.managerId?.message}
          itemComponent={SelectUsersItem}
        />
      )}
    />
  );
}

ManagerInput.defaultProps = {
  required: true,
};

export default observer(ManagerInput);
