import { Select } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { useStores } from '../../../../App';
import { requiredMsg } from '../../../../const/const';

interface ISoldProductStorageInputProps {
  required?: boolean,
  i: number,
}

interface ISoldProductInput {
  soldProducts: {
    storageId: number | null,
  }[],
}

function SoldProductStorageInput({
  required,
  i,
}: ISoldProductStorageInputProps) {
  const { productStorageLookupStore: { selectItems } } = useStores();

  const {
    control,
    formState: { errors },
  } = useFormContext<ISoldProductInput>();

  return (
    <Controller
      name={`soldProducts.${i}.storageId`}
      control={control}
      rules={{ required: required && requiredMsg }}
      render={({ field: { value, onChange } }) => (
        <Select
          value={value ? value.toString() : null}
          onChange={(val) => onChange(val ? +val : null)}
          clearable
          data={selectItems}
          label="Где лежит"
          withAsterisk={required}
          error={errors.soldProducts && errors.soldProducts[i]?.storageId?.message}
        />
      )}
    />
  );
}

SoldProductStorageInput.defaultProps = {
  required: true,
};

export default observer(SoldProductStorageInput);
