import { NumberInput, Select, Stack, Textarea } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { useStores } from '../../../App';
import { ISendCashboxBody } from '../../../clientApi/CashboxApi/cashboxApiTypes';
import { dataCurrencySelect, requiredMsg } from '../../../const/const';
import { actionOnFocusNumberInput } from '../../../const/utils';
import InputClearCross from '../../_Shared/InputClearCross';

function AddCashboxItemFormContent() {
  const {
    control,
    formState: { errors },
  } = useFormContext<ISendCashboxBody>();

  const {
    spendingCategoryLookupStore: { selectItems },
    paymentMethodLookupStore,
  } = useStores();

  return (
    <Stack>
      <Controller
        name="categoryId"
        control={control}
        rules={{ required: requiredMsg }}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value ? value.toString() : null}
            onChange={(val) => onChange(val ? +val : undefined)}
            clearable
            data={selectItems}
            label="Категория приходов/расходов"
            withAsterisk
            error={errors.categoryId?.message}
          />
        )}
      />
      <Controller
        name="amount"
        control={control}
        rules={{ required: requiredMsg }}
        render={({ field: { value, onChange } }) => (
          <NumberInput
            value={value === undefined ? undefined : value}
            onChange={(val) => onChange(val === undefined ? undefined : val)}
            label="Сумма"
            precision={2}
            withAsterisk
            error={errors.amount?.message}
            onFocus={actionOnFocusNumberInput}
          />
        )}
      />
      <Controller
        name="currencyId"
        control={control}
        rules={{ required: requiredMsg }}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value ? value.toString() : null}
            onChange={(val) => onChange(val ? +val : null)}
            clearable
            data={dataCurrencySelect}
            label="Валюта"
            withAsterisk
            error={errors.currencyId?.message}
          />
        )}
      />
      <Controller
        name="paymentMethodTypeId"
        control={control}
        rules={{ required: requiredMsg }}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value ? value.toString() : null}
            onChange={(val) => onChange(val ? +val : null)}
            clearable
            data={paymentMethodLookupStore.selectItems}
            label="Метод оплаты"
            withAsterisk
            error={errors.paymentMethodTypeId?.message}
          />
        )}
      />
      <Controller
        name="comment"
        control={control}
        rules={{ required: requiredMsg }}
        render={({ field: { value, onChange } }) => (
          <Textarea
            value={value || ''}
            onChange={({ currentTarget: { value: val } }) => onChange(val || null)}
            label="Комментарий"
            withAsterisk
            autosize
            minRows={2}
            maxRows={4}
            error={errors.comment?.message}
            rightSection={<InputClearCross action={() => onChange(null)} />}
          />
        )}
      />
    </Stack>
  );
}

export default observer(AddCashboxItemFormContent);
