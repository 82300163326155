import { Group, Avatar, Text } from '@mantine/core';
import { forwardRef } from 'react';

interface ISelectUsersItemProps {
  image: string,
  label: string,
  description: string,
}

const SelectUsersItem = forwardRef<HTMLDivElement, ISelectUsersItemProps>(({
  image,
  label,
  description,
  ...others
}: ISelectUsersItemProps, ref) => (
  <div ref={ref} {...others}>
    <Group noWrap spacing={4}>
      <Avatar src={image} radius="xl" size="sm" />
      <div>
        <Text size="sm">{label}</Text>
        <Text size="xs" color="dimmed">
          {description}
        </Text>
      </div>
    </Group>
  </div>
));

export default SelectUsersItem;
