export const NullValueLabel = '—';

export function getCustomerFullName(
  customer: {firstName: string | null, middleName: string | null, lastName: string | null},
) {
  return [customer.firstName, customer.middleName, customer.lastName].filter((it) => it !== null).join(' ');
}

export function formatSaleId(saleId: number | string, withSharp = true): string {
  return `${withSharp ? '#' : ''}${String(saleId).padStart(5, '0')}`;
}
