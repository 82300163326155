import { ActionIcon, Box, Text, Button, Container, Group, Modal, Space, Tooltip } from '@mantine/core';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { FaExclamationTriangle, FaTrashAlt } from 'react-icons/fa';
import { useStores } from '../../../App';

interface IDeletePaymentLookupBtnProps {
  id: number,
  currentLabel: string,
}

function DeletePaymentLookupBtn({
  id,
  currentLabel,
}: IDeletePaymentLookupBtnProps) {
  const { paymentMethodLookupStore } = useStores();

  const [visible, setVisible] = useState(false);

  const deleteLookup = async () => {
    await paymentMethodLookupStore.deletePaymentMethodLookup(id);
    setVisible(false);
    paymentMethodLookupStore.getPaymentMethodLookup();
  };

  return (
    <>
      <Modal
        opened={visible}
        onClose={() => setVisible(false)}
        title={(
          <Text align="center" weight="bold">
            Подтверждение
          </Text>
        )}
      >
        <Container>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <FaExclamationTriangle size={40} color="orange" />
            <Text>
              {`Вы уверены что хотите удалить способ оплаты: ${currentLabel}?`}
            </Text>
          </Box>
        </Container>
        <Space h="xl" />
        <Container size="xs" px="xs">
          <Group position="right">
            <Button variant="subtle" onClick={() => setVisible(false)}>Отмена</Button>
            <Button
              loading={paymentMethodLookupStore.isFormLoading}
              onClick={deleteLookup}
            >
              Удалить
            </Button>
          </Group>
        </Container>
      </Modal>
      <Tooltip withArrow label="Удалить">
        <ActionIcon color="red" onClick={() => setVisible(true)}>
          <FaTrashAlt size={20} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}

export default observer(DeletePaymentLookupBtn);
