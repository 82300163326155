import { createStyles, Group, Image, Title } from '@mantine/core';
import logoImg from '../../../../assets/logo.png';
import { transitionDelay } from '../NavBar';

const useStyles = createStyles(() => ({
  logoTitle: {
    fontFamily: 'Trebuchet MS',
    color: 'rgb(91,96,100)',
  },

  boxWithTransition: {
    transition: transitionDelay,
  },
  logoContainerIsClose: {
    paddingLeft: '7px',
  },
}));

interface ILogoProps {
  isNavbarOpen?: boolean,
}

export default function Logo({
  isNavbarOpen,
}: ILogoProps) {
  const { classes, cx } = useStyles();
  return (
    <Group
      spacing={12}
      position="center"
      className={cx(classes.boxWithTransition, { [classes.logoContainerIsClose]: !isNavbarOpen })}
    >
      {isNavbarOpen}
      <Image
        width={isNavbarOpen ? 48 : 32}
        height={isNavbarOpen ? 48 : 32}
        src={logoImg}
        className={classes.boxWithTransition}
      />
      <Title size="h2" className={classes.logoTitle}>
        Jef-Jef ERP
      </Title>
    </Group>
  );
}

Logo.defaultProps = {
  isNavbarOpen: true,
};
