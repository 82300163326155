import { Button, Container, Group, Modal, Space, Text, Tooltip, Select, ActionIcon } from '@mantine/core';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Controller, Path, SubmitHandler, useForm } from 'react-hook-form';
import { FaEdit, FaSave } from 'react-icons/fa';
import { useStores } from '../../../../App';
import { INewRoleBody } from '../../../../clientApi/UsersApi/usersApiTypes';
import { IChangeUserRoleProps, IRoleListInput } from '../../types';
import { getSelectRolesObj } from '../utils';

function ChangeUserRole({
  isDisable,
  id,
  fullName,
  currentRole,
  setCurrentRole,
}: IChangeUserRoleProps) {
  const [opened, setOpened] = useState(false);
  const [dataRoles, setDataRoles] = useState<IRoleListInput[]>([]);
  const { control, handleSubmit, formState: { errors }, reset, setError } = useForm<INewRoleBody>();

  const {
    lookupsStore: { userRolesLookup },
    usersStore: { isFormLoading, putUserRole, getUsers },
  } = useStores();

  function switchModal() {
    setOpened((state) => !state);
    reset();
  }

  const changeRole: SubmitHandler<INewRoleBody> = async (data) => {
    const errorData = await putUserRole(id, data);
    if (errorData === null) {
      const newRoleValue = data.newRoleKey;
      const newRoleLabel = userRolesLookup.find((a) => a.key === newRoleValue)?.label as string;
      setDataRoles(getSelectRolesObj(newRoleValue, newRoleLabel, userRolesLookup));
      setCurrentRole(newRoleLabel);
      reset();
      setOpened(false);
      getUsers();
    } else if (Array.isArray(errorData)) {
      errorData.forEach((it) => setError(it[0] as Path<INewRoleBody>, { message: it[1] }));
    }
  };

  useEffect(() => {
    const currentRoleValue = userRolesLookup.find((a) => a.label === currentRole)?.key as string;
    setDataRoles(getSelectRolesObj(currentRoleValue, currentRole, userRolesLookup));
  }, []);

  return (
    <>
      <Modal
        opened={opened}
        onClose={switchModal}
        title={(
          <Text align="center" weight="bold">
            Изменение роли
          </Text>
        )}
      >
        <Container>
          <Text>
            {`для пользователя ${fullName}`}
          </Text>
        </Container>
        <Space h="xl" />
        <form onSubmit={handleSubmit(changeRole)}>
          <Controller
            name="newRoleKey"
            control={control}
            rules={{ required: 'Это поле должно быть заполнено' }}
            render={({ field }) => (
              <Select
                {...field}
                label="Выберите новую роль"
                placeholder="Список доступных ролей"
                withAsterisk
                data={dataRoles}
              />
            )}
          />
          {errors.newRoleKey && <Text size="xs" color="red">{errors.newRoleKey.message}</Text>}
          <Space h="xl" />
          <Container size="xs" px="xs">
            <Group position="right">
              <Button variant="subtle" onClick={switchModal}>Отмена</Button>
              <Button loading={isFormLoading} type="submit" leftIcon={<FaSave />}>Сохранить</Button>
            </Group>
          </Container>
        </form>
      </Modal>
      <Tooltip withArrow label="Изменить роль">
        <ActionIcon disabled={isDisable} color="blue" onClick={switchModal}>
          <FaEdit size={20} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}

export default observer(ChangeUserRole);
