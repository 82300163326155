import { Button, Container, Group, Modal, Space, Text, Tooltip, Box, ActionIcon } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { FaExclamationTriangle, FaKey } from 'react-icons/fa';
import { useStores } from '../../../../App';
import { notificationsData } from '../../../../store/const';
import { IResetUserPassProps } from '../../types';

function ResetUserPassword({
  isDisable,
  id,
  fullName,
}: IResetUserPassProps) {
  const [opened, setOpened] = useState(false);
  function switchModal() {
    setOpened((state) => !state);
  }

  const { usersStore: { putUserPassword, isFormLoading } } = useStores();

  async function resetPassword() {
    const response = await putUserPassword(id);
    if (response !== null) {
      showNotification(notificationsData.passwordMsg(
        response.newPassword,
        fullName,
      ));
      setOpened(false);
    }
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={switchModal}
        title={(
          <Text align="center" weight="bold">
            Подтверждение
          </Text>
        )}
      >
        <Container>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <FaExclamationTriangle size={40} color="orange" />
            <Text>
              {`Вы уверены что хотите сбросить пароль пользователя ${fullName}?`}
            </Text>
          </Box>
        </Container>
        <Space h="xl" />
        <Container size="xs" px="xs">
          <Group position="right">
            <Button variant="subtle" onClick={switchModal}>Нет</Button>
            <Button loading={isFormLoading} onClick={resetPassword}>Да</Button>
          </Group>
        </Container>
      </Modal>
      <Tooltip withArrow label="Сбросить пароль">
        <ActionIcon disabled={isDisable} color="blue" onClick={switchModal}>
          <FaKey size={20} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}

export default observer(ResetUserPassword);
