import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { createStyles, Group, LoadingOverlay, ScrollArea } from '@mantine/core';
import { observer } from 'mobx-react';
import { useStores } from '../../App';
import NavBar from './NavBar/NavBar';
import MobileNavBar from './NavBar/MobileNavBar';

const useStyles = createStyles((theme) => ({
  appContainer: {
    height: '100vh',
    width: '100vw',

    [theme.fn.smallerThan(768)]: {
      flexDirection: 'column',
    },
  },
  contentContainer: {
    backgroundColor: theme.colors.gray[2],
    padding: '10px',
    width: '100%',
    height: '100%',

    [theme.fn.smallerThan(768)]: {
      overflowY: 'hidden',
      padding: '3px',
    },
    [theme.fn.largerThan(768)]: {
      overflowX: 'hidden',
    },
  },
  content: {
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '15px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    [theme.fn.smallerThan(768)]: {
      padding: '5px',
      borderRadius: '5px',
    },
  },
}));

function MainLayout() {
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 768px)').matches,
  );

  const { classes } = useStyles();

  const { profileStore, loginStore, lookupsStore, usersStore, taskTicketsStore } = useStores();

  async function start() {
    const data = await profileStore.getCurrentProfile();
    if (data !== null) {
      lookupsStore.initAllLookups();
      usersStore.getUsers();
      taskTicketsStore.startToDoCountInterval();
    }
  }

  useEffect(() => {
    start();
    window
      .matchMedia('(min-width: 768px)')
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);

  return (
    <Group spacing={0} className={classes.appContainer} noWrap>
      <LoadingOverlay
        visible={loginStore.isLoading || profileStore.isLoading}
        overlayBlur={2}
        zIndex={999}
      />
      {matches && <NavBar />}
      {!matches && <MobileNavBar />}
      <div className={classes.contentContainer}>
        {!matches && (
          <ScrollArea
            scrollbarSize={5}
            className={classes.content}
          >
            <Outlet />
          </ScrollArea>
        )}
        {matches && (
          <div className={classes.content}>
            <Outlet />
          </div>
        )}
      </div>
    </Group>
  );
}

export default observer(MainLayout);
