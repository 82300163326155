import { ActionIcon, Button, Container, Group, Modal, MultiSelect, NumberInput, Space, Stack, Text, Tooltip } from '@mantine/core';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Controller, Path, SubmitHandler, useForm } from 'react-hook-form';
import { FaPen, FaSave } from 'react-icons/fa';
import { useStores } from '../../../../App';
import { ICreateUserBodySalaryRates } from '../../../../clientApi/UsersApi/usersApiTypes';
import { actionOnFocusNumberInput } from '../../../../const/utils';

interface IChangeAdvertiserOptionsProps {
  userId: number,
  fullName: string,
  rateFromSaleSources: number,
  saleSourcesIds: number[],
}

interface IUpdateAdvertiserOptionsBody {
  rateFromSaleSources: number,
  saleSourcesIds: number[],
}

function ChangeAdvertiserOptions({
  userId,
  fullName,
  rateFromSaleSources,
  saleSourcesIds,
}: IChangeAdvertiserOptionsProps) {
  const { saleSourceLookupStore: { selectItems }, usersStore } = useStores();

  const defaultValues = {
    rateFromSaleSources,
    saleSourcesIds,
  };
  const {
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm<IUpdateAdvertiserOptionsBody>({ defaultValues });

  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateAdvertiserOptions: SubmitHandler<IUpdateAdvertiserOptionsBody> = async (data) => {
    setLoading(true);
    const body: ICreateUserBodySalaryRates = {
      rateFromSales: 0,
      rateFromPostSales: 0,
      rateFromSaleSources: data.rateFromSaleSources,
      saleSourcesIds: data.saleSourcesIds,
    };
    const errorData = await usersStore.updateUserSalaryRates(userId, body);

    if (errorData === null) {
      setOpened(false);
      usersStore.getUsers();
    } else if (Array.isArray(errorData)) {
      errorData
        .forEach((it) => setError(it[0] as Path<IUpdateAdvertiserOptionsBody>, { message: it[1] }));
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={(
          <Text align="center" weight="bold">Изменение дохода</Text>
        )}
      >
        <Text>{`для пользователя ${fullName}`}</Text>
        <Space h="xl" />
        <form onSubmit={handleSubmit(updateAdvertiserOptions)}>
          <Container>
            <Stack>
              <Controller
                name="rateFromSaleSources"
                control={control}
                rules={{ required: 'Это поле должно быть заполнено' }}
                render={({ field }) => (
                  <NumberInput
                    {...field}
                    label="Процент от продаж"
                    withAsterisk
                    error={errors.rateFromSaleSources?.message}
                    onFocus={actionOnFocusNumberInput}
                  />
                )}
              />
              <Controller
                name="saleSourcesIds"
                control={control}
                rules={{ required: 'Это поле должно быть заполнено' }}
                render={({ field: { value, onChange } }) => (
                  <MultiSelect
                    value={value === null ? undefined : value.map((it) => it.toString())}
                    onChange={(val) => onChange(val.map((it) => Number(it)))}
                    label="Источники продаж"
                    withAsterisk
                    clearable
                    error={errors.saleSourcesIds?.message}
                    data={selectItems}
                  />
                )}
              />
            </Stack>
          </Container>
          <Space h="xl" />
          <Container size="xs" px="xs">
            <Group position="apart">
              <Button variant="subtle" onClick={() => reset(defaultValues)}>
                Сбросить
              </Button>
              <Group>
                <Button variant="subtle" onClick={() => setOpened(false)}>Отмена</Button>
                <Button loading={loading} type="submit" leftIcon={<FaSave />}>Сохранить</Button>
              </Group>
            </Group>
          </Container>
        </form>
      </Modal>
      <Tooltip withArrow label="Редактировать доп. информацию" openDelay={500} closeDelay={100}>
        <ActionIcon color="blue" onClick={() => setOpened(true)}>
          <FaPen size={15} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}

export default observer(ChangeAdvertiserOptions);
