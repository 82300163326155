import { addressAnalyticsExportSales } from '../../const/const';
import { getFileNameContentByDateRange, getFileName } from '../../const/utils';
import HttpClient from '../HttpClient';
import { IGetAnalyticsExportSalesProps } from './analyticsApiTypes';

export default class AnalyticsApi {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getAnalyticsExportSales(params: IGetAnalyticsExportSalesProps) {
    return this
      .httpClient
      .Get<null>(
        addressAnalyticsExportSales,
        { ...params },
        getFileName(
          'Аналитика',
          getFileNameContentByDateRange(params.FilterStartDate, params.FilterEndDate),
          'xlsx',
        ),
      );
  }
}
