import { Textarea } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { requiredMsg } from '../../../../const/const';
import InputClearCross from '../../InputClearCross';

interface IDeliveryAddressInputProps {
  required?: boolean,
}

interface IDeliveryInput {
  delivery: {
    address: string | null,
  },
}

function DeliveryAddressInput({
  required,
}: IDeliveryAddressInputProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<IDeliveryInput>();

  return (
    <Controller
      name="delivery.address"
      control={control}
      rules={{ required: required && requiredMsg }}
      render={({ field: { value, onChange } }) => (
        <Textarea
          value={value || ''}
          onChange={({ currentTarget: { value: val } }) => onChange(val || null)}
          label="Адрес"
          withAsterisk={required}
          autosize
          minRows={2}
          maxRows={4}
          error={errors.delivery?.address?.message}
          rightSection={<InputClearCross action={() => onChange(null)} />}
        />
      )}
    />
  );
}

DeliveryAddressInput.defaultProps = {
  required: true,
};

export default observer(DeliveryAddressInput);
