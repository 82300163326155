import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Group } from '@mantine/core';
import AddReceiptsBtn from './AddReceipts/AddReceiptsBtn';
import TableReceipts from './TableReceipts';
import PageTitle from '../_Shared/PageTitle';
import UpdateContentBtn from '../_Shared/UpdateContentBtn';
import FilterByProduct from '../_Shared/Filters/FilterByProduct';
import { useStores } from '../../App';
import { IReceipt } from '../../clientApi/ReceiptsApi/receiptsApiTypes';
import CloseAllCardsBtn from '../Delivery/DeliveriesActions/CloseAllCardsBtn';
import OpenAllCardsBtn from '../Delivery/DeliveriesActions/OpenAllCardsBtn';

function Receipts() {
  const {
    clientApi: { receiptsApi },
    profileStore: { profilePermissions: { Receipts: receiptsPermission } },
  } = useStores();

  const pageSize = 10;
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [page, setPage] = useState(1);
  const [filterItemId, setFilterItemId] = useState<number | null>(null);
  const [productFilterLabel, setProductFilterLabel] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState<IReceipt[]>([]);

  const [expandedRecordIds, setExpandedRecordIds] = useState<number[]>([]);

  async function fetchItems() {
    setIsLoading(true);
    const [response] = await receiptsApi.getReceipts({
      PageNumber: page,
      PageSize: pageSize,
      FilterByProductId: filterItemId,
    });
    if (response !== null) {
      setExpandedRecordIds([]);
      setItems(response.items);
      setTotalItemsCount(response.totalItemsCount);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchItems();
  }, [page, filterItemId]);

  return (
    <>
      <PageTitle text="Приходы" />
      <Group position="apart">
        <Box sx={{ minWidth: '300px' }}>
          <FilterByProduct
            placeholder="Поиск по названию товара"
            setFilterValue={setFilterItemId}
            filterLabel={productFilterLabel}
            setFilterLabel={setProductFilterLabel}
          />
        </Box>
        <Group spacing={4}>
          {receiptsPermission !== 'read-only' && (
            <AddReceiptsBtn
              fetchItems={fetchItems}
            />
          )}
          <UpdateContentBtn
            action={fetchItems}
            isDisabled={isLoading}
          />
          <CloseAllCardsBtn
            action={() => setExpandedRecordIds([])}
            isDisabled={expandedRecordIds.length === 0}
          />
          <OpenAllCardsBtn
            action={() => setExpandedRecordIds(items.map((it) => it.id))}
            isDisabled={items.length === expandedRecordIds.length}
          />
        </Group>
      </Group>
      <TableReceipts
        items={items}
        isLoading={isLoading}
        totalItemsCount={totalItemsCount}
        page={page}
        pageSize={pageSize}
        onPageChange={(p) => setPage(p)}
        fetchItems={fetchItems}
        expandedRecordIds={expandedRecordIds}
        setExpandedRecordIds={setExpandedRecordIds}
      />
    </>
  );
}

export default observer(Receipts);
