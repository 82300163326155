import { Select, Stack } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { useStores } from '../../../App';
import { IGetSalesExportChequesForm } from '../../../clientApi/SaleApi/saleApiTypes';
import { requiredMsg } from '../../../const/const';
import { styleDatePickerActualDay } from '../../../const/utils';

function CheckPrintingFormContent() {
  const { deliveryTypeLookupStore: { selectItems } } = useStores();

  const {
    control,
    formState: { errors },
  } = useFormContext<IGetSalesExportChequesForm>();

  return (
    <Stack>
      <Controller
        name="filterByDate"
        control={control}
        rules={{ required: requiredMsg }}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            value={value ? new Date(value) : null}
            onChange={(val) => onChange(val ? val.toLocaleDateString().split('.').reverse().join('-') : null)}
            label="Дата"
            placeholder="Выберите дату"
            clearable
            error={errors.filterByDate?.message}
            dayStyle={styleDatePickerActualDay}
            withAsterisk
          />
        )}
      />
      <Controller
        name="filterByDeliveryType"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value ? value.toString() : null}
            onChange={(val) => onChange(val ? +val : null)}
            clearable
            data={selectItems}
            label="Способ доставки"
            error={errors.filterByDeliveryType?.message}
          />
        )}
      />
    </Stack>
  );
}

export default observer(CheckPrintingFormContent);
