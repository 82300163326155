import { Burger, createStyles, Drawer, Group, Navbar, ScrollArea } from '@mantine/core';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStores } from '../../../App';
import LinksGroup from './LinksGroup/LinksGroup';
import Logo from './Logo/Logo';
import UserMenu from './UserMenu/UserMenu';

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    width: '100%',
    zIndex: 25,
    height: '100%',
  },

  header: {
    padding: '5px 10px',
    width: '100vw',
    backgroundColor: 'white',
    zIndex: 199,
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  drawer: {
    [theme.fn.largerThan(768)]: {
      display: 'none',
    },
  },
  drawerBody: {
    height: '100%',
    paddingTop: '45px',
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  footer: {
    marginLeft: -theme.spacing.md / 2,
    marginRight: -theme.spacing.md / 2,
    marginBottom: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

function MobileNavBar() {
  const { classes } = useStyles();

  const { profileStore: { navLinksData } } = useStores();

  const [isOpened, setIsOpened] = useState(false);

  const links = navLinksData.map((item) => (
    <LinksGroup
      icon={item.icon}
      label={item.label}
      key={item.label}
      links={item.links}
      noGroupLink={item.link}
      onLinkClick={() => setIsOpened(false)}
    />
  ));

  return (
    <>
      <Group position="apart" noWrap className={classes.header}>
        <Burger
          opened={isOpened}
          onClick={() => setIsOpened((o) => !o)}
        />
        <Logo />
      </Group>
      <Drawer
        opened={isOpened}
        onClose={() => setIsOpened(false)}
        withCloseButton={false}
        zIndex={198}
        size={300}
        className={classes.drawer}
        classNames={{ body: classes.drawerBody }}
      >
        <Navbar p="md" className={classes.navbar}>
          <Navbar.Section grow className={classes.links} component={ScrollArea}>
            {links}
          </Navbar.Section>

          <Navbar.Section className={classes.footer}>
            <UserMenu onLinkClick={() => setIsOpened(false)} />
          </Navbar.Section>
        </Navbar>
      </Drawer>
    </>
  );
}

export default observer(MobileNavBar);
