import { ActionIcon, Box, Card, Group, Spoiler, Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { FaPenSquare, FaRegCalendarAlt, FaRegCalendarCheck, FaRegCheckCircle } from 'react-icons/fa';
import { dataPriorityTypes } from '../../../const/const';
import 'dayjs/locale/ru';
import { ITaskTicket } from '../../../clientApi/TaskTicketsApi/taskTicketsApiTypes';
import { DateAndTime } from '../../_Shared/DateViewComponents/DateAndTime';
import { useStores } from '../../../App';

interface ITaskTicketProps {
  item: ITaskTicket,
  openChangeItemModal: (() => void) | false,
}

export default function TaskTicket({
  item,
  openChangeItemModal,
}: ITaskTicketProps) {
  const {
    profileStore: { profilePermissions: { TaskTickets: taskTicketsPermission } },
  } = useStores();

  return (
    <Card
      style={{ width: '250px', borderLeft: `solid 5px ${item.isFinished ? 'gray' : dataPriorityTypes[item.priority.id - 1].color}` }}
      shadow="sm"
      radius="md"
      withBorder
      p={8}
    >
      <Stack spacing={4}>
        <Group position="apart">
          <Text>
            {dataPriorityTypes[item.priority.id - 1].label}
          </Text>
          {openChangeItemModal !== false && taskTicketsPermission === 'read-and-edit' && (
            <ActionIcon onClick={openChangeItemModal} color="blue" size="lg">
              <FaPenSquare size={26} />
            </ActionIcon>
          )}
        </Group>
        <Title order={4} color={item.isFinished ? 'gray' : 'black'}>
          {item.header}
        </Title>
        <Spoiler maxHeight={65} showLabel="..." hideLabel="Свернуть">
          {item.description}
        </Spoiler>
        <Group position="apart" align="end">
          <Stack spacing={4} align="flex-start" justify="flex-start">
            <Box>
              <Text>
                Дата получения
              </Text>
              <Group spacing="xs">
                <ThemeIcon variant="light">
                  <FaRegCalendarAlt size={16} />
                </ThemeIcon>
                <DateAndTime date={item.startDateTimeUtc} isOneLine />
              </Group>
            </Box>
            {item.finishedAtDateTimeUtc && (
              <Box>
                <Text>
                  Дата выполнения
                </Text>
                <Group spacing="xs">
                  <ThemeIcon variant="light">
                    <FaRegCalendarCheck size={16} />
                  </ThemeIcon>
                  <DateAndTime date={item.finishedAtDateTimeUtc} isOneLine />
                </Group>
              </Box>
            )}
          </Stack>
          <ThemeIcon color={item.isFinished ? 'green' : 'gray'}>
            <FaRegCheckCircle />
          </ThemeIcon>
        </Group>
      </Stack>
    </Card>
  );
}
