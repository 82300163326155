import { Group, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { NullValueLabel } from '../../../utils/stringUtils';

type TAcceptedDate = Date | string | null;

interface IDateProps {
  date: TAcceptedDate,
}

interface ITimeRangeProps {
  startDate: TAcceptedDate,
  endDate: TAcceptedDate,
}

interface IDateWithTimeRangeProps {
  startDate: TAcceptedDate,
  endDate: TAcceptedDate,
  isOneLine?: boolean,
}

interface IDateAndTimeProps {
  date: TAcceptedDate,
  isOneLine?: boolean,
}

export function DateAndTime({
  date,
  isOneLine,
}: IDateAndTimeProps) {
  if (date === null) {
    return <Text>{NullValueLabel}</Text>;
  }
  return isOneLine ? (
    <Text>{dayjs(date).format('DD.MM.YYYY, HH:mm')}</Text>
  ) : (
    <Stack spacing={0}>
      <Text>{dayjs(date).format('DD.MM.YYYY')}</Text>
      <Text>{dayjs(date).format('HH:mm')}</Text>
    </Stack>
  );
}

DateAndTime.defaultProps = {
  isOneLine: false,
};

export function FormattedDate({
  date,
}: IDateProps) {
  if (date === null) {
    return <Text>{NullValueLabel}</Text>;
  }
  return <Text>{dayjs(date).format('DD.MM.YYYY')}</Text>;
}

export function getTime(date: TAcceptedDate) {
  if (date === null) {
    return NullValueLabel;
  }
  return dayjs(date).format('HH:mm');
}

export function TimeRange({
  startDate,
  endDate,
}: ITimeRangeProps) {
  if (startDate === null && endDate === null) {
    return <Text>{NullValueLabel}</Text>;
  }
  return <Text>{`${getTime(startDate)} - ${getTime(endDate)}`}</Text>;
}

export function DateWithTimeRange({
  startDate,
  endDate,
  isOneLine,
}: IDateWithTimeRangeProps) {
  if (startDate === null && endDate === null) {
    return <Text>{NullValueLabel}</Text>;
  }
  return isOneLine ? (
    <Group spacing={0}>
      <FormattedDate date={startDate} />
      {', '}
      <TimeRange startDate={startDate} endDate={endDate} />
    </Group>
  ) : (
    <Stack spacing={0}>
      <FormattedDate date={startDate} />
      <TimeRange startDate={startDate} endDate={endDate} />
    </Stack>
  );
}

DateWithTimeRange.defaultProps = {
  isOneLine: false,
};
