import { Checkbox, Group, Select, Stack, Textarea, TextInput } from '@mantine/core';
import { DatePicker, TimeInput } from '@mantine/dates';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { useStores } from '../../../App';
import { ICreateTaskTicketBody } from '../../../clientApi/TaskTicketsApi/taskTicketsApiTypes';
import { dataPriorityTypes, requiredMsg } from '../../../const/const';
import { styleDatePickerActualDay } from '../../../const/utils';
import SelectUsersItem from '../../_Shared/AutoCompleteItemComponents/SelectUsersItem';
import InputClearCross from '../../_Shared/InputClearCross';

function CreateTaskFormContent() {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<ICreateTaskTicketBody>();

  const isTaskForAll = watch('isTaskForAll');

  const { usersStore: { selectUsersData } } = useStores();

  return (
    <Stack>
      <Controller
        name="header"
        control={control}
        rules={{ required: requiredMsg }}
        render={({ field: { value, onChange } }) => (
          <TextInput
            value={value || ''}
            onChange={({ currentTarget: { value: val } }) => onChange(val || null)}
            label="Заголовок"
            withAsterisk
            error={errors.header?.message}
            rightSection={<InputClearCross action={() => onChange(null)} />}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        rules={{ required: requiredMsg }}
        render={({ field: { value, onChange } }) => (
          <Textarea
            value={value || ''}
            onChange={({ currentTarget: { value: val } }) => onChange(val || null)}
            label="Описание"
            withAsterisk
            autosize
            minRows={2}
            maxRows={4}
            error={errors.description?.message}
            rightSection={<InputClearCross action={() => onChange(null)} />}
          />
        )}
      />
      <Controller
        name="priorityId"
        control={control}
        rules={{ required: requiredMsg }}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value ? value.toString() : null}
            onChange={(val) => onChange(val ? +val : null)}
            clearable
            data={dataPriorityTypes}
            label="Приоритет"
            withAsterisk
            error={errors.priorityId?.message}
          />
        )}
      />
      <Controller
        name="startDateTimeUtc"
        control={control}
        rules={{ required: 'Дата и время должны быть заполнены' }}
        render={({ field: { value, onChange } }) => (
          <Group align="flex-start" grow>
            <DatePicker
              value={value}
              onChange={(date) => {
                const newDate = date
                  ? new Date(date.setHours(value.getHours(), value.getMinutes()))
                  : null;
                onChange(newDate);
              }}
              label="Дата начала задачи"
              withAsterisk
              placeholder="Выберите дату"
              error={errors.startDateTimeUtc?.message}
              clearable={false}
              dayStyle={styleDatePickerActualDay}
            />
            <TimeInput
              value={value}
              onChange={(date) => {
                const newDate = new Date(date.setFullYear(
                  value.getFullYear(),
                  value.getMonth(),
                  value.getDate(),
                ));
                onChange(newDate);
              }}
              label="Время"
              withAsterisk
            />
          </Group>
        )}
      />
      <Controller
        name="assigneeUserId"
        control={control}
        rules={{ required: isTaskForAll ? false : requiredMsg }}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value ? value.toString() : null}
            onChange={(val) => onChange(val ? +val : null)}
            clearable
            data={selectUsersData}
            label="Назначить пользователя"
            withAsterisk
            disabled={isTaskForAll}
            error={errors.assigneeUserId?.message}
            itemComponent={SelectUsersItem}
          />
        )}
      />
      <Controller
        name="isTaskForAll"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Checkbox
            checked={value}
            onChange={(event) => {
              onChange(event);
              const check = event.currentTarget.checked;
              if (check) {
                setValue('assigneeUserId', null);
              }
            }}
            label="Задача для всех"
          />
        )}
      />
    </Stack>
  );
}

export default observer(CreateTaskFormContent);
