import { makeAutoObservable } from 'mobx';
import { IDeliveriesItem, IGetDeliveriesParams } from '../clientApi/DeliveriesApi/deliveriesApiTypes';
import RootStore from './RootStore';

export default class DeliveriesStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  items: IDeliveriesItem[] = [];

  totalItemsCount = 0;

  openedItems: string[] = [];

  setOpenedItems = (value: string[]) => {
    this.openedItems = value;
  };

  get isOpenAllBtnDisable() {
    return this.totalItemsCount === this.openedItems.length;
  }

  setOpenAll = () => {
    this.openedItems = this.items.map((_, i) => i.toString());
  };

  get isCloseAllBtnDisable() {
    return this.openedItems.length === 0;
  }

  setCloseAll = () => {
    this.openedItems = [];
  };

  isLoading = false;

  isFormLoading = false;

  getDeliveries = async (params: IGetDeliveriesParams) => {
    this.isLoading = true;
    this.openedItems = [];
    const data = await this.rootStore.clientApi.deliveriesApi.getDeliveries(params);
    if (data[0] !== null) {
      this.items = data[0].items;
      this.totalItemsCount = data[0].totalItemsCount;
    }
    this.isLoading = false;
  };
}
