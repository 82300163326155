import { forwardRef } from 'react';
import { Text, MantineColor, SelectItemProps } from '@mantine/core';

interface ItemProps extends SelectItemProps {
  color: MantineColor;
  description: string;
}

const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ description, value, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text>{value}</Text>
      <Text size="xs" color="dimmed">
        {description}
      </Text>
    </div>
  ),
);

export default AutoCompleteItem;
