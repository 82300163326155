import { Select, Group, TextInput, Checkbox, Stack, Textarea } from '@mantine/core';
import { DatePicker, TimeInput } from '@mantine/dates';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { useStores } from '../../../App';
import { IUpdateTaskTicketBody } from '../../../clientApi/TaskTicketsApi/taskTicketsApiTypes';
import { dataPriorityTypes, requiredMsg } from '../../../const/const';
import { styleDatePickerActualDay } from '../../../const/utils';
import InputClearCross from '../../_Shared/InputClearCross';

function UpdateTaskFormContent() {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<IUpdateTaskTicketBody>();

  const isTaskForAll = watch('isTaskForAll');

  const { usersStore: { selectUsersData } } = useStores();

  return (
    <Stack>
      <Controller
        name="header"
        control={control}
        rules={{ required: requiredMsg }}
        render={({ field: { value, onChange } }) => (
          <TextInput
            value={value || ''}
            onChange={({ currentTarget: { value: val } }) => onChange(val || undefined)}
            label="Заголовок"
            withAsterisk
            error={errors.header?.message}
            rightSection={<InputClearCross action={() => onChange('')} />}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        rules={{ required: requiredMsg }}
        render={({ field: { value, onChange } }) => (
          <Textarea
            value={value || ''}
            onChange={({ currentTarget: { value: val } }) => onChange(val || undefined)}
            label="Описание"
            withAsterisk
            autosize
            minRows={2}
            maxRows={4}
            error={errors.description?.message}
            rightSection={<InputClearCross action={() => onChange('')} />}
          />
        )}
      />
      <Controller
        name="priorityId"
        control={control}
        rules={{ required: requiredMsg }}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value ? value.toString() : undefined}
            onChange={(val) => onChange(val ? +val : undefined)}
            clearable
            data={dataPriorityTypes}
            label="Приоритет"
            withAsterisk
            error={errors.priorityId?.message}
          />
        )}
      />
      <Controller
        name="startDateTimeUtc"
        control={control}
        rules={{ required: 'Дата и время должны быть заполнены' }}
        render={({ field: { value, onChange } }) => (
          <Group align="flex-start" grow>
            <DatePicker
              value={value}
              onChange={(date) => {
                const newDate = date
                  ? new Date(date.setHours(value.getHours(), value.getMinutes()))
                  : null;
                onChange(newDate);
              }}
              label="Дата начала задачи"
              withAsterisk
              placeholder="Выберите дату"
              error={errors.startDateTimeUtc?.message}
              clearable={false}
              dayStyle={styleDatePickerActualDay}
            />
            <TimeInput
              value={value}
              onChange={(date) => {
                const newDate = new Date(date.setFullYear(
                  value.getFullYear(),
                  value.getMonth(),
                  value.getDate(),
                ));
                onChange(newDate);
              }}
              label="Время"
              withAsterisk
            />
          </Group>
        )}
      />
      <Controller
        name="assigneeUserId"
        control={control}
        rules={{ required: isTaskForAll ? false : requiredMsg }}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value ? value.toString() : undefined}
            onChange={(val) => onChange(val ? +val : undefined)}
            clearable
            data={selectUsersData}
            label="Назначить пользователя"
            withAsterisk
            disabled={isTaskForAll}
            error={errors.assigneeUserId?.message}
          />
        )}
      />
      <Group position="center" grow>
        <Controller
          name="isTaskForAll"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={value}
              onChange={(event) => {
                onChange(event);
                const check = event.currentTarget.checked;
                if (check) {
                  setValue('assigneeUserId', undefined);
                }
              }}
              label="Задача для всех"
            />
          )}
        />
        <Controller
          name="isFinished"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={value}
              onChange={onChange}
              label="Закончена"
            />
          )}
        />
      </Group>
    </Stack>
  );
}

export default observer(UpdateTaskFormContent);
