import { ActionIcon, Tooltip } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { FaCopy, FaCheck } from 'react-icons/fa';

interface ICopyButtonWithIcon {
  copyValue: string,
}

export default function CopyButtonWithIcon({ copyValue }: ICopyButtonWithIcon) {
  const clipboard = useClipboard();

  const icon = clipboard.copied ? (
    <FaCheck size={20} />
  ) : (
    <FaCopy size={20} />
  );

  return (
    <Tooltip
      label="Скопировано!"
      offset={5}
      position="bottom"
      radius="xl"
      transition="slide-down"
      transitionDuration={100}
      opened={clipboard.copied}
    >
      <ActionIcon
        variant="subtle"
        color="main"
        size="xs"
        onClick={() => clipboard.copy(copyValue)}
      >
        {icon}
      </ActionIcon>
    </Tooltip>
  );
}
