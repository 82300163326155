import { Modal, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useStores } from '../../../../App';
import { IProfileNewPasswordBody } from '../../../../clientApi/ProfileApi/profileApiTypes';
import FormWrapper from '../../../_Shared/FormElements/FormWrapper';
import ChangeProfilePasswordFormContent from './ChangeProfilePasswordFormContent';

interface IChangeProfilePassModalProps {
  isOpened: boolean,
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>,
}

const defaultValues = {
  oldPassword: null,
  newPassword: null,
  repeatNewPassword: null,
};

function ChangeProfilePassModal({
  isOpened,
  setIsOpened,
}: IChangeProfilePassModalProps) {
  const [isFormLoading, setIsFormLoading] = useState(false);

  const { profileStore } = useStores();

  async function fetchData(data: IProfileNewPasswordBody) {
    setIsFormLoading(true);
    const response = await profileStore.putProfilePassword(data);
    setIsFormLoading(false);
    return response;
  }

  return (
    <Modal
      opened={isOpened}
      onClose={() => setIsOpened(false)}
      title={(
        <Text align="center" weight="bold">
          Смена пароля
        </Text>
      )}
    >
      <FormWrapper<IProfileNewPasswordBody, null>
        defaultValues={defaultValues}
        isFormLoading={isFormLoading}
        fetchData={fetchData}
        actionAfterSuccess={() => setIsOpened(false)}
      >
        <ChangeProfilePasswordFormContent />
      </FormWrapper>
    </Modal>
  );
}

export default observer(ChangeProfilePassModal);
