import { Modal, Text, Container, TextInput, Space, Group, Button, ActionIcon, Tooltip, Stack, NumberInput, Select } from '@mantine/core';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useForm, SubmitHandler, Controller, Path } from 'react-hook-form';
import { FaMoneyBillWave, FaPen, FaSave } from 'react-icons/fa';
import { useStores } from '../../../App';
import { IPaymentMethodsLookupItem, ISendPaymentLookupBody } from '../../../clientApi/LookupsApi/lookupsApiTypes';
import { dataCashTypes, requiredMsg } from '../../../const/const';
import { actionOnFocusNumberInput } from '../../../const/utils';
import InputClearCross from '../../_Shared/InputClearCross';

interface IUpdatePaymentLookupBtnProps {
  id: number,
  item: IPaymentMethodsLookupItem,
}

function UpdatePaymentLookupBtn({
  id,
  item,
}: IUpdatePaymentLookupBtnProps) {
  const { paymentMethodLookupStore } = useStores();
  const [visible, setVisible] = useState(false);

  const defaultValues: ISendPaymentLookupBody = {
    label: item.label,
    cashType: item.cashType,
    fee: item.fee,
  };

  const {
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm<ISendPaymentLookupBody>({ defaultValues });

  function closeModal() {
    setVisible(false);
    reset(defaultValues);
  }

  const updateLookup: SubmitHandler<ISendPaymentLookupBody> = async (data) => {
    const dataErrors = await paymentMethodLookupStore.putPaymentMethodLookup(data, id);
    if (Array.isArray(dataErrors)) {
      dataErrors
        .forEach((it) => setError(it[0] as Path<ISendPaymentLookupBody>, { message: it[1] }));
    } else if (dataErrors === null) {
      closeModal();
      paymentMethodLookupStore.getPaymentMethodLookup();
    }
  };

  return (
    <>
      <Modal
        opened={visible}
        onClose={closeModal}
        title={(
          <Text align="center" weight="bold">
            Обновление способа оплаты
          </Text>
        )}
      >
        <form onSubmit={handleSubmit(updateLookup)}>
          <Stack>
            <Controller
              name="label"
              control={control}
              rules={{ required: requiredMsg }}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  value={value || ''}
                  onChange={({ currentTarget: { value: val } }) => onChange(val || undefined)}
                  label="Название"
                  withAsterisk
                  icon={<FaMoneyBillWave />}
                  error={errors.label?.message}
                  rightSection={<InputClearCross action={() => onChange('')} />}
                />
              )}
            />
            <Controller
              name="fee"
              control={control}
              rules={{ required: requiredMsg }}
              render={({ field }) => (
                <NumberInput
                  {...field}
                  label="Комиссия (%)"
                  precision={2}
                  min={0}
                  withAsterisk
                  error={errors.fee?.message}
                  onFocus={actionOnFocusNumberInput}
                />
              )}
            />
            <Controller
              name="cashType"
              control={control}
              rules={{ required: requiredMsg }}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value ? value.toString() : undefined}
                  onChange={(val) => onChange(val ? +val : undefined)}
                  clearable
                  data={dataCashTypes}
                  label="Тип"
                  withAsterisk
                  error={errors.cashType?.message}
                />
              )}
            />
          </Stack>
          <Space h="xl" />
          <Container size="xs" px="xs">
            <Group position="right">
              <Button variant="subtle" onClick={closeModal}>Отмена</Button>
              <Button loading={paymentMethodLookupStore.isFormLoading} type="submit" leftIcon={<FaSave />}>Сохранить</Button>
            </Group>
          </Container>
        </form>
      </Modal>
      <Tooltip withArrow label="Изменить">
        <ActionIcon color="blue" onClick={() => setVisible(true)}>
          <FaPen size={20} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}

export default observer(UpdatePaymentLookupBtn);
