import { Group, NumberInput, Stack, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { ISendDeliveryAddForm } from '../../../../clientApi/SaleApi/saleApiTypes';
import { requiredMsg } from '../../../../const/const';

interface IDeliveryEndDateTimeInputProps {
  required?: boolean,
}

interface IDeliveryInput {
  delivery: ISendDeliveryAddForm,
}

function DeliveryEndDateTimeInput({
  required,
}: IDeliveryEndDateTimeInputProps) {
  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext<IDeliveryInput>();

  return (
    <Stack spacing={3}>
      <Text size={14} weight={500} color="#212529">Время доставки</Text>
      <Group noWrap spacing={6}>
        <Controller
          name="delivery.inputStartTime"
          control={control}
          rules={{ required: required && requiredMsg }}
          render={({ field: { onChange, value } }) => (
            <NumberInput
              value={value === null ? undefined : value}
              onChange={(val) => {
                clearErrors(['delivery.endDateTimeUtc', 'delivery.startDateTimeUtc']);
                onChange(val === undefined ? null : val);
              }}
              max={23}
              min={0}
              step={1}
              precision={0}
              rightSection=": 00"
              rightSectionWidth={40}
              hideControls
              style={{ width: '75px' }}
              withAsterisk={required}
            />
          )}
        />
        <Text>&ndash;</Text>
        <Controller
          name="delivery.inputEndTime"
          control={control}
          rules={{ required: required && requiredMsg }}
          render={({ field: { onChange, value } }) => (
            <NumberInput
              value={value === null ? undefined : value}
              onChange={(val) => {
                clearErrors(['delivery.endDateTimeUtc', 'delivery.startDateTimeUtc']);
                onChange(val === undefined ? null : val);
              }}
              max={23}
              min={0}
              step={1}
              precision={0}
              rightSection=": 00"
              rightSectionWidth={40}
              hideControls
              style={{ width: '75px' }}
              withAsterisk={required}
            />
          )}
        />
      </Group>
      {errors.delivery?.endDateTimeUtc?.message && (
        <Text color="#fa5252" size={12}>{errors.delivery?.endDateTimeUtc?.message}</Text>
      )}
    </Stack>
  );
}

DeliveryEndDateTimeInput.defaultProps = {
  required: true,
};

export default observer(DeliveryEndDateTimeInput);
