import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Container, Grid, Group, Text } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { Link } from 'react-router-dom';
import PageTitle from '../_Shared/PageTitle';
import UpdateContentBtn from '../_Shared/UpdateContentBtn';
import { useStores } from '../../App';
import { IUserSalaryShortData } from '../../clientApi/SalariesApi/salariesApiTypes';
import FilterByDate from '../_Shared/Filters/FilterByDate';
import { getDateStringForQueryParams } from '../../const/utils';

const year = new Date().getFullYear();
const month = new Date().getMonth();
const date1 = getDateStringForQueryParams(new Date(year, month, 1));
const date2 = getDateStringForQueryParams(new Date(year, month + 1, 0));

function Salaries() {
  const { clientApi: { salariesApi } } = useStores();

  const [startDate, setStartDate] = useState<string | null>(date1);
  const [endDate, setEndDate] = useState<string | null>(date2);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState<IUserSalaryShortData[]>([]);

  async function fetchItems() {
    setIsLoading(true);
    const [response] = await salariesApi.getSalaries({
      EndDate: endDate,
      StartDate: startDate,
    });
    if (response !== null) {
      setItems(response.salaries);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchItems();
  }, [startDate, endDate]);

  return (
    <div>
      <PageTitle text="Зарплаты" />
      <Container>
        <Grid>
          <Grid.Col span="auto">
            <FilterByDate
              placeholder="Выберите дату"
              label="Начало периода"
              setFilterValue={setStartDate}
              value={startDate}
              clearable={false}
            />
          </Grid.Col>
          <Grid.Col span="auto">
            <FilterByDate
              placeholder="Выберите дату"
              label="Конец периода"
              setFilterValue={setEndDate}
              value={endDate}
              clearable={false}
            />
          </Grid.Col>
        </Grid>
      </Container>

      <Group position="right">
        <UpdateContentBtn
          action={fetchItems}
          isDisabled={isLoading}
        />
      </Group>
      <Box sx={{
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <DataTable<IUserSalaryShortData>
          noRecordsText="По данному запросу записей не найдено"
          highlightOnHover
          fetching={isLoading}
          records={items}
          idAccessor="user.userId"
          columns={[
            {
              accessor: 'user.fullName',
              width: '250px',
              title: 'Имя',
              render: ({ user }) => (
                <Link to={`/salary/${user.userId}`}>
                  <Text>{user.fullName}</Text>
                </Link>
              ),
            },
            {
              accessor: 'salarySumFromSales',
              width: '150px',
              title: 'От продаж',
              render: (it) => (
                <Text>
                  {it.salarySumFromSales}
                  <Text span size="xs">{` (${it.salaryRates.rateFromSales}%)`}</Text>
                </Text>
              ),
            },
            {
              accessor: 'salarySumFromPostSales',
              width: '150px',
              title: 'От допродаж',
              render: (it) => (
                <Text>
                  {it.salarySumFromPostSales}
                  <Text span size="xs">{` (${it.salaryRates.rateFromPostSales}%)`}</Text>
                </Text>
              ),
            },
            {
              accessor: 'salarySumFromSources',
              width: '150px',
              title: 'От рекламы',
              render: (it) => (
                <Text>
                  {it.salarySumFromSources}
                  <Text span size="xs">{` (${it.salaryRates.rateFromSaleSources}%)`}</Text>
                </Text>
              ),
            },
            {
              accessor: 'salarySum',
              width: '150px',
              title: 'Итог',
            },
          ]}
        />
      </Box>
    </div>
  );
}

export default observer(Salaries);
