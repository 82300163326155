import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button, Container, Grid, LoadingOverlay, ScrollArea } from '@mantine/core';
import { FaArrowCircleLeft, FaEye } from 'react-icons/fa';
import PageTitle from '../_Shared/PageTitle';
import SaleEditForm from './SaleEditPage/SaleEditForm';
import { IUpdateSaleBody } from '../../clientApi/SaleApi/saleApiTypes';
import { useStores } from '../../App';
import { formatSaleId } from '../../utils/stringUtils';
import EmptyStateTitle from '../_Shared/EmptyStateTitle';

function SaleEditPage() {
  const { saleId } = useParams() as unknown as { saleId: string };
  const [isLoading, setIsLoading] = useState(true);
  const [defaultValues, setDefaultValues] = useState<null | IUpdateSaleBody>(null);

  const { clientApi: { saleApi } } = useStores();

  const [isCorrectSaleId, setIsCorrectSaleId] = useState(true);

  const fetchSale = async () => {
    setIsLoading(true);
    const id = +saleId;
    if (Number.isFinite(id)) {
      const data = await saleApi.getSaleById(id);
      if (data !== null) {
        const startDate = data.delivery.startDateTimeUtc
          ? new Date(data.delivery.startDateTimeUtc) : null;
        const endDate = data.delivery.endDateTimeUtc
          ? new Date(data.delivery.endDateTimeUtc) : null;
        setDefaultValues({
          statusId: data.status.id,
          managerId: data.manager.userId,
          sourceId: data.source.id,
          commentAboutSale: data.comment,
          customer: {
            customerId: data.customer.id,
            phone: data.customer.phone,
            firstName: data.customer.firstName,
            middleName: data.customer.middleName,
            lastName: data.customer.lastName,
            commentAboutCustomer: data.customer.comment,
          },
          soldProducts: data.soldProducts.map((it) => ({
            productId: it.productId,
            storageId: it.storage.id,
            isOrdered: it.isOrdered,
            quantity: it.quantity,
            salePriceByn: it.salePriceByn,
            isPostSale: it.isPostSale,
            id: it.id,
            name: it.productName,
            primaryPrice: (it
              .receiptQueueItems
              .reduce((a, b) => a + b.primaryPriceByn, 0) / it.receiptQueueItems.length) || null,
          })),
          payments: data.payments.map((it) => ({
            paymentMethodTypeId: it.paymentMethodType.id,
            paymentAmount: it.paymentAmount,
          })),
          delivery: {
            courierId: data.delivery.courier?.userId || null,
            deliveryTypeId: data.delivery.deliveryType.id,
            priceForCustomer: data.delivery.priceForCustomer,
            primaryPrice: data.delivery.primaryPrice,
            address: data.delivery.address,
            trackNumber: data.delivery.trackNumber,
            startDateTimeUtc: startDate,
            endDateTimeUtc: endDate,
            inputStartTime: startDate ? startDate.getHours() : null,
            inputEndTime: endDate ? endDate.getHours() : null,
            pickedDate: startDate,
          },
          taskTickets: data.taskTickets.map((it) => ({
            header: it.header,
            description: it.description,
            priorityId: it.priority.id,
            startDateTimeUtc: new Date(it.startDateTimeUtc),
            assigneeUserId: it.assigneeUser?.userId || null,
            isTaskForAll: it.isTaskForAll,
            id: it.id,
            isFinished: it.isFinished,
          })),
        });
      } else {
        setIsCorrectSaleId(false);
      }
    } else {
      setIsCorrectSaleId(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSale();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'flex-start',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Grid align="flex-end" pb="sm">
        <Grid.Col sm="auto">
          <Link to="/sales" relative="route" style={{ textDecoration: 'none', float: 'right' }}>
            <Button variant="subtle" leftIcon={<FaArrowCircleLeft />}>
              К продажам
            </Button>
          </Link>
        </Grid.Col>
        <Grid.Col sm="content">
          <PageTitle paddingBottom={0}>{`Продажа ${formatSaleId(saleId)}`}</PageTitle>
        </Grid.Col>
        <Grid.Col sm="auto">
          {isCorrectSaleId && (
            <Link to={`/sales/${saleId}`} relative="path" style={{ textDecoration: 'none', float: 'left' }}>
              <Button variant="subtle" leftIcon={<FaEye />}>
                Просмотр
              </Button>
            </Link>
          )}
        </Grid.Col>
      </Grid>

      {!isCorrectSaleId && (
        <EmptyStateTitle title={`Продажи с id '${saleId}' не существует`} />
      )}

      <ScrollArea offsetScrollbars sx={{ width: '100%', height: '100%', display: 'flex' }}>
        <LoadingOverlay visible={isLoading} />
        {defaultValues && (
        <Container size={900}>
          <SaleEditForm
            saleId={+saleId}
            defaultValues={defaultValues}
          />
        </Container>
        )}
      </ScrollArea>
    </div>
  );
}

export default observer(SaleEditPage);
