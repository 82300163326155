import { Select } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { useStores } from '../../../App';
import { ISendDeliveryStatusID } from '../../../clientApi/DeliveriesApi/deliveriesApiTypes';
import { requiredMsg } from '../../../const/const';

function DeliveryUpdateStatusFormContent() {
  const {
    control,
    formState: { errors },
  } = useFormContext<ISendDeliveryStatusID>();

  const { lookupsStore: { deliverySelectSaleStatusLookup } } = useStores();

  return (
    <Controller
      name="newStatusId"
      control={control}
      rules={{ required: requiredMsg }}
      render={({ field: { value, onChange } }) => (
        <Select
          value={value ? value.toString() : null}
          onChange={(val) => onChange(val ? +val : null)}
          label="Статус доставки"
          clearable
          data={deliverySelectSaleStatusLookup}
          withAsterisk
          error={errors.newStatusId?.message}
        />
      )}
    />
  );
}

export default observer(DeliveryUpdateStatusFormContent);
