import { Container, FileInput } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { FaUpload } from 'react-icons/fa';
import { IImgInput } from '../../../../clientApi/ProfileApi/profileApiTypes';
import { requiredMsg } from '../../../../const/const';
import { checkSizeImg, checkTypeImg } from '../../utils';

function ChangeProfileImgFormContent() {
  const {
    control,
    formState: { errors },
  } = useFormContext<IImgInput>();

  return (
    <Container>
      <Controller
        name="newAvatarBase64"
        control={control}
        rules={{
          required: requiredMsg,
          validate: {
            size: checkSizeImg,
            type: checkTypeImg,
          },
        }}
        render={({ field }) => (
          <FileInput
            {...field}
            label="Выберите изображение"
            withAsterisk
            icon={<FaUpload size={14} />}
            accept="image/png,image/jpeg"
            error={errors.newAvatarBase64?.message}
            clearable
          />
        )}
      />
    </Container>
  );
}

export default observer(ChangeProfileImgFormContent);
