import { NumberInput } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { requiredMsg } from '../../../../const/const';
import { actionOnFocusNumberInput } from '../../../../const/utils';

interface ISoldProductSalePriceBynInputProps {
  required?: boolean,
  i: number,
}

interface ISoldProductInput {
  soldProducts: {
    salePriceByn: number | null,
  }[],
}

function SoldProductSalePriceBynInput({
  required,
  i,
}: ISoldProductSalePriceBynInputProps) {
  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext<ISoldProductInput>();

  return (
    <Controller
      name={`soldProducts.${i}.salePriceByn`}
      control={control}
      rules={{ required: required && requiredMsg }}
      render={({ field: { value, onChange } }) => (
        <NumberInput
          value={value === null ? undefined : value}
          onChange={(val) => {
            onChange(val === undefined ? null : val);
            clearErrors('soldProducts');
          }}
          label="Цена/шт"
          precision={2}
          min={0}
          withAsterisk={required}
          error={errors.soldProducts && errors.soldProducts[i]?.salePriceByn?.message}
          onFocus={actionOnFocusNumberInput}
        />
      )}
    />
  );
}

SoldProductSalePriceBynInput.defaultProps = {
  required: true,
};

export default observer(SoldProductSalePriceBynInput);
