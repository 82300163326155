import { IRole } from '../types';

export function getSelectRolesObj(value: string, label: string, roles: IRole[]) {
  const currRole = { value, label, group: 'Текущая роль', disabled: true };
  const otherRoles = roles
    .filter((role) => role.key !== value)
    .map((role) => ({ value: role.key, label: role.label, group: 'Новая роль' }));
  return [currRole, ...otherRoles];
}

export function func() {
  //
}
