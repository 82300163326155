import { NumberInput } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { requiredMsg } from '../../../../const/const';
import { actionOnFocusNumberInput } from '../../../../const/utils';

interface IDeliveryPriceForCustomerInputProps {
  required?: boolean,
}

interface ISoldProductInput {
  delivery: {
    priceForCustomer: number | null,
  },
}

function DeliveryPriceForCustomerInput({
  required,
}: IDeliveryPriceForCustomerInputProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<ISoldProductInput>();

  return (
    <Controller
      name="delivery.priceForCustomer"
      control={control}
      rules={{ required: required && requiredMsg }}
      render={({ field: { value, onChange } }) => (
        <NumberInput
          value={value === null ? undefined : value}
          onChange={(val) => onChange(val === undefined ? null : val)}
          label="Цена"
          precision={2}
          min={0}
          withAsterisk={required}
          error={errors.delivery?.priceForCustomer?.message}
          onFocus={actionOnFocusNumberInput}
        />
      )}
    />
  );
}

DeliveryPriceForCustomerInput.defaultProps = {
  required: true,
};

export default observer(DeliveryPriceForCustomerInput);
