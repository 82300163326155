import { ActionIcon, Button, Container, Group, Stack } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { useStores } from '../../App';
import { ISaleAuditItem } from '../../clientApi/SaleApi/saleApiTypes';
import PageTitle from '../_Shared/PageTitle';
import { formatSaleId } from '../../utils/stringUtils';
import EmptyStateTitle from '../_Shared/EmptyStateTitle';

function SaleAudit() {
  const { saleId } = useParams() as unknown as { saleId: string };
  const [items, setItems] = useState<ISaleAuditItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isCorrectSaleId, setIsCorrectSaleId] = useState(true);

  const { clientApi: { saleApi } } = useStores();

  async function fetchItems() {
    setIsLoading(true);
    const id = +saleId;
    if (Number.isFinite(id)) {
      const data = await saleApi.getSaleAudit(id);
      if (data !== null) {
        setItems(data.items.reverse());
      } else {
        setIsCorrectSaleId(false);
      }
    } else {
      setIsCorrectSaleId(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Stack sx={{ height: '100%' }}>
      <Group noWrap spacing={4} position="center" align="baseline">
        {isCorrectSaleId ? (
          <Link to={`/sales/${saleId}`} relative="route" style={{ textDecoration: 'none' }}>
            <ActionIcon color="blue" variant="subtle">
              <FaArrowCircleLeft size={16} />
            </ActionIcon>
          </Link>
        ) : (
          <Link to="/sales" relative="route" style={{ textDecoration: 'none' }}>
            <Button variant="subtle" leftIcon={<FaArrowCircleLeft />}>
              К продажам
            </Button>
          </Link>
        )}
        <PageTitle paddingBottom={0}>
          {`История изменения продажи ${formatSaleId(saleId)}`}
        </PageTitle>
      </Group>
      <Container sx={{ overflow: 'hidden', height: '100%', minWidth: '767px' }}>
        {isCorrectSaleId ? (
          <DataTable<ISaleAuditItem>
            noRecordsText="По данному запросу записей не найдено"
            highlightOnHover
            fetching={isLoading}
            records={items}
            columns={[
              {
                accessor: 'createdDateUtc',
                title: 'Дата',
                render: ({
                  createdDateUtc,
                }) => new Date(createdDateUtc).toLocaleString().slice(0, -3),
              },
              {
                accessor: 'creatorUser.fullName',
                title: 'Кто изменил',
              },
              {
                accessor: 'text',
                title: 'Изменение',
              },
            ]}
          />
        ) : (
          <EmptyStateTitle title={`Продажи с id '${saleId}' не существует`} />
        )}
      </Container>
    </Stack>
  );
}

export default observer(SaleAudit);
