import { addressStock, addressStorageBalance } from '../../const/const';
import HttpClient from '../HttpClient';
import { IGetStorageBalanceResponse, IGetStorageParams, IStorageResponseWithPagination } from './storageApiTypes';

export default class StorageApi {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getStorage(params: IGetStorageParams) {
    const data = await this
      .httpClient
      .Get<IStorageResponseWithPagination>(addressStock, { ...params });
    return data;
  }

  async getStorageBalance() {
    const data = await this
      .httpClient
      .Get<IGetStorageBalanceResponse>(addressStorageBalance);
    return data;
  }
}
