import { makeAutoObservable } from 'mobx';
import { SelectItem } from '@mantine/core';
import { ICreateUserBody, ICreateUserBodySalaryRates, INewRoleBody, IUser } from '../clientApi/UsersApi/usersApiTypes';
import RootStore from './RootStore';

export default class UsersStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  isLoading = false;

  isFormLoading = false;

  usersData: IUser[] = [];

  selectUsersData: SelectItem[] = [];

  getUsers = async () => {
    this.isLoading = true;
    const data = await this.rootStore.clientApi.usersApi.getUsers();
    if (data[0] !== null) {
      this.usersData = data[0].users;
      this.selectUsersData = data[0]
        .users
        .map((item) => ({
          value: item.id.toString(),
          label: item.fullName,
          image: item.avatarBase64,
          description: item.roleLabel,
        }));
    }
    this.isLoading = false;
  };

  postUser = async (body: ICreateUserBody) => {
    this.isFormLoading = true;
    const data = await this.rootStore.clientApi.usersApi.postUser(body);
    this.isFormLoading = false;
    return data;
  };

  postBlockUser = async (id: number) => {
    this.isFormLoading = true;
    const response = await this.rootStore.clientApi.usersApi.postBlockUser(id);
    this.isFormLoading = false;
    return response;
  };

  postUnblockUser = async (id: number) => {
    this.isFormLoading = true;
    const response = await this.rootStore.clientApi.usersApi.postUnblockUser(id);
    this.isFormLoading = false;
    return response;
  };

  putUserRole = async (id: number, body: INewRoleBody) => {
    this.isFormLoading = true;
    const data = await this.rootStore.clientApi.usersApi.putUserRole(id, body);
    this.isFormLoading = false;
    return data[1];
  };

  putUserPassword = async (id: number) => {
    this.isFormLoading = true;
    const data = await this.rootStore.clientApi.usersApi.putUserPassword(id);
    this.isFormLoading = false;
    return data[0];
  };

  updateUserSalaryRates = async (id: number, body: ICreateUserBodySalaryRates) => {
    this.isFormLoading = true;
    const data = await this.rootStore.clientApi.usersApi.putUserSalaryRates(id, body);
    this.isFormLoading = false;
    return data[1];
  };
}
