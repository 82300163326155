import { TextInput, PasswordInput, Paper, Title, Container, Button, LoadingOverlay } from '@mantine/core';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Controller, Path, SubmitHandler, useForm } from 'react-hook-form';
import { FaSignInAlt } from 'react-icons/fa';

import { useStores } from '../../App';
import { IPostLoginBody } from '../../clientApi/LoginApi/loginApiTypes';

const defaultValues = {
  email: '',
  password: '',
};

function Login() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm<IPostLoginBody>({ defaultValues });

  const { profileStore, loginStore } = useStores();

  const signIn: SubmitHandler<IPostLoginBody> = async (data) => {
    const dataErrors = await loginStore.postLogin(data);
    if (Array.isArray(dataErrors)) {
      dataErrors.forEach((it) => setError(it[0] as Path<IPostLoginBody>, { message: it[1] }));
    }
  };

  useEffect(() => {
    profileStore.checkAuthorization();
  }, []);

  return (
    <Container size={420} style={{ position: 'relative' }} my={40}>
      <LoadingOverlay visible={profileStore.isLoading} overlayBlur={2} />
      <Title
        align="center"
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
      >
        Вход
      </Title>
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <form onSubmit={handleSubmit(signIn)}>
          <Controller
            rules={{ required: 'Это поле должно быть заполнено' }}
            name="email"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label="Email"
                placeholder="example@email.com"
                withAsterisk
                error={errors.email?.message}
              />
            )}
          />
          <Controller
            rules={{ required: 'Это поле должно быть заполнено' }}
            name="password"
            control={control}
            render={({ field }) => (
              <PasswordInput
                {...field}
                label="Пароль"
                placeholder="Ваш пароль"
                withAsterisk
                mt="md"
                error={errors.password?.message}
              />
            )}
          />
          <Button
            loading={loginStore.isLoading}
            type="submit"
            fullWidth
            mt="xl"
            leftIcon={<FaSignInAlt />}
          >
            Войти
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default observer(Login);
