import { FaChartPie, FaMoneyBillWave, FaShippingFast, FaShoppingBasket, FaStore, FaTruck } from 'react-icons/fa';

export const paymentMethodFormInfo = {
  addForm: {
    formTitle: 'Добавление способа оплаты',
    inputLabel: 'Способ оплаты',
    inputIcon: <FaMoneyBillWave />,
    resultMsg: (name: string) => `Добавлен новый способ оплаты: ${name}`,
  },
  updateForm: {
    formTitle: 'Обновление способа оплаты',
    formDescription: (name: string) => `Текущее название: ${name}`,
    inputLabel: 'Новое название',
    inputIcon: <FaMoneyBillWave />,
    resultMsg: (oldName: string, newName: string) => `Название изменено с "${oldName}" на "${newName}"`,
  },
  deleteForm: {
    formDescription: (name: string) => `Вы уверены что хотите удалить способ оплаты: ${name}?`,
    resultMsg: (name: string) => `Способ оплаты "${name}" успешно удалён`,
  },
};

export const productStorageFormInfo = {
  addForm: {
    formTitle: 'Добавление склада',
    inputLabel: 'Склад',
    inputIcon: <FaStore />,
    resultMsg: (name: string) => `Добавлен новый склад: ${name}`,
  },
  updateForm: {
    formTitle: 'Обновление склада',
    formDescription: (name: string) => `Текущее название: ${name}`,
    inputLabel: 'Новое название',
    inputIcon: <FaStore />,
    resultMsg: (oldName: string, newName: string) => `Название изменено с "${oldName}" на "${newName}"`,
  },
  deleteForm: {
    formDescription: (name: string) => `Вы уверены что хотите удалить склад: ${name}?`,
    resultMsg: (name: string) => `Склад "${name}" успешно удалён`,
  },
};

export const productSupplierFormInfo = {
  addForm: {
    formTitle: 'Добавление поставщика',
    inputLabel: 'Поставщик',
    inputIcon: <FaShippingFast />,
    resultMsg: (name: string) => `Добавлен новый поставщик: ${name}`,
  },
  updateForm: {
    formTitle: 'Обновление поставщика',
    formDescription: (name: string) => `Текущее название: ${name}`,
    inputLabel: 'Новое название',
    inputIcon: <FaShippingFast />,
    resultMsg: (oldName: string, newName: string) => `Название изменено с "${oldName}" на "${newName}"`,
  },
  deleteForm: {
    formDescription: (name: string) => `Вы уверены что хотите удалить поставщика: ${name}?`,
    resultMsg: (name: string) => `Поставщик "${name}" успешно удалён`,
  },
};

export const saleSourceFormInfo = {
  addForm: {
    formTitle: 'Добавление источника продаж',
    inputLabel: 'Источник продаж',
    inputIcon: <FaShoppingBasket />,
    resultMsg: (name: string) => `Добавлен новый источник продаж: ${name}`,
  },
  updateForm: {
    formTitle: 'Обновление источника продаж',
    formDescription: (name: string) => `Текущее название: ${name}`,
    inputLabel: 'Новое название',
    inputIcon: <FaShoppingBasket />,
    resultMsg: (oldName: string, newName: string) => `Название изменено с "${oldName}" на "${newName}"`,
  },
  deleteForm: {
    formDescription: (name: string) => `Вы уверены что хотите удалить источник продаж: ${name}?`,
    resultMsg: (name: string) => `Источник продаж "${name}" успешно удалён`,
  },
};

export const spendingCategoryFormInfo = {
  addForm: {
    formTitle: 'Добавление категории расходов',
    inputLabel: 'Категория расходов',
    inputIcon: <FaChartPie />,
    resultMsg: (name: string) => `Добавлена новая категория расходов: ${name}`,
  },
  updateForm: {
    formTitle: 'Обновление категории расходов',
    formDescription: (name: string) => `Текущее название: ${name}`,
    inputLabel: 'Новое название',
    inputIcon: <FaChartPie />,
    resultMsg: (oldName: string, newName: string) => `Название изменено с "${oldName}" на "${newName}"`,
  },
  deleteForm: {
    formDescription: (name: string) => `Вы уверены что хотите удалить категорию расходов: ${name}?`,
    resultMsg: (name: string) => `Категория расходов "${name}" успешно удалён`,
  },
};

export const deliveryTypeFormInfo = {
  addForm: {
    formTitle: 'Добавление способа доставки',
    inputLabel: 'Способ доставки',
    inputIcon: <FaTruck />,
    resultMsg: (name: string) => `Добавлен новый способ доставки: ${name}`,
  },
  updateForm: {
    formTitle: 'Обновление способа доставки',
    formDescription: (name: string) => `Текущее название: ${name}`,
    inputLabel: 'Новое название',
    inputIcon: <FaTruck />,
    resultMsg: (oldName: string, newName: string) => `Название изменено с "${oldName}" на "${newName}"`,
  },
  deleteForm: {
    formDescription: (name: string) => `Вы уверены что хотите удалить способ доставки: ${name}?`,
    resultMsg: (name: string) => `Способ доставки "${name}" успешно удалён`,
  },
};
