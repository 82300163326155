import { MantineSize, MultiSelect } from '@mantine/core';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useStores } from '../../../App';

interface IDeliveryTypeMultiSelectProps {
  label?: string,
  placeholder?: string,
  clearable?: boolean,
  size?: MantineSize,
  icon?: ReactNode,
  setFilterValue: React.Dispatch<React.SetStateAction<number[] | null>>,
  value: number[] | null,
}

function DeliveryTypeMultiSelect({
  label,
  placeholder,
  clearable,
  size,
  icon,
  setFilterValue,
  value,
}: IDeliveryTypeMultiSelectProps) {
  const { deliveryTypeLookupStore: { selectItems } } = useStores();
  return (
    <MultiSelect
      data={selectItems}
      label={label}
      placeholder={placeholder}
      clearable={clearable}
      size={size}
      icon={icon}
      onChange={(val) => setFilterValue(!val.length ? null : val.map((it) => +it))}
      value={value === null ? [] : value.map((a) => a.toString())}
    />
  );
}

DeliveryTypeMultiSelect.defaultProps = {
  label: undefined,
  placeholder: undefined,
  clearable: true,
  size: 'xs',
  icon: <FaSearch />,
};

export default observer(DeliveryTypeMultiSelect);
