/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export const address = process.env.REACT_APP_SERVER_ADDRESS as string;
export const addressApi = `${address}/api`;

// Auth
export const addressApiAuth = `${addressApi}/auth`;
export const addressLogin = `${addressApiAuth}/login`;
export const addressLogout = `${addressApiAuth}/logout`;

// Analytics
export const addressAnalytics = `${addressApi}/analytics`;
export const addressAnalyticsExportSales = `${addressAnalytics}/export-sales`;

// Cashier
export const addressCashier = `${addressApi}/cashier`;
export const addressCashierBalance = `${addressApi}/cashier/balance`;
export const addressCashierWithId = (id: number) => `${addressCashier}/${id}`;

// Products
export const addressProducts = `${addressApi}/products`;
export const addressProductsQuery = `${addressProducts}/query`;
export const addressProductsBatchSearch = `${addressProducts}/batch-search`;

// Profile
export const addressProfile = `${addressApi}/profile`;
export const getAddressProfileWithID = (id: number) => `${addressProfile}/${id}`;
export const addressProfilePassword = `${addressProfile}/password`;
export const addressProfileFullName = `${addressProfile}/fullname`;
export const addressProfileAvatar = `${addressProfile}/avatar`;
export const addressProfileSettings = `${addressProfile}/user-settings`;

// Receipts
export const addressReceipts = `${addressApi}/receipts`;
export const getAddressReceiptsWithID = (id: number) => `${addressReceipts}/${id}`;
export const getAddressUpdateExchangeRateWithID = (id: number) => `${getAddressReceiptsWithID(id)}/update-exchange-rate`;

// Suppliers Lookup
export const addressLookUp = `${addressApi}/lookup`;
export const addressSuppliers = `${addressLookUp}/product-suppliers`;
export const getAddressSuppliersWithID = (id: number) => `${addressSuppliers}/${id}`;

// User Roles Lookup
export const addressUserRoles = `${addressApi}/user-roles`;

// Users
export const addressUsers = `${addressApi}/users`;
export const getAddressUsersWithID = (id: number) => `${addressUsers}/${id}`;
export const getAddressBlockUser = (id: number) => `${getAddressUsersWithID(id)}/block`;
export const getAddressUnblockUser = (id: number) => `${getAddressUsersWithID(id)}/unblock`;
export const getAddressChangeRoleUser = (id: number) => `${getAddressUsersWithID(id)}/change-role`;
// TODO Don't use short form of the words (like 'Pass' for 'Password'). Refactor it
export const getAddressResetPassUser = (id: number) => `${getAddressUsersWithID(id)}/reset-password`;
export const getAddressUpdateUserSalaryRates = (id: number) => `${getAddressUsersWithID(id)}/salary-rates`;

// //////////////////////////////////
export enum CurrencyTypes {
  BYN = 1,
  USD = 2,
}

// export enum CashTypes {
//   Наличные = 1,
//   Безналичные = 2,
//   // Юр. лица = 3,
// }
export const CashTypes = ['', 'Наличные', 'Безналичные', 'Юр. лица'];

export const dataCurrencySelect = [
  { value: '1', label: 'BYN' },
  { value: '2', label: 'USD' },
];

export const dataPriorityTypes = [
  { value: '1', label: 'Низкий', color: 'yellow' },
  { value: '2', label: 'Средний', color: 'orange' },
  { value: '3', label: 'Высокий', color: 'red' },
];

export const dataCashTypes = [
  { value: '1', label: 'Наличные' },
  { value: '2', label: 'Безналичные' },
  { value: '3', label: 'Юр. лица' },
];

export const requiredMsg = 'Это поле должно быть заполнено';

// PaymentMethodLookup
export const addressPaymentMethodLookup = `${addressLookUp}/payment-methods`;
export const getAddressPaymentMethodLookupWithID = (id: number) => `${addressPaymentMethodLookup}/${id}`;

// ProductStorageLookup
export const addressProductStorageLookup = `${addressLookUp}/product-storages`;
export const getAddressProductStorageLookupWithID = (id: number) => `${addressProductStorageLookup}/${id}`;

// ProductSupplierLookup
export const addressProductSupplierLookup = `${addressLookUp}/product-suppliers`;
export const getAddressProductSupplierLookupWithID = (id: number) => `${addressProductSupplierLookup}/${id}`;

// SaleSourceLookup
export const addressSaleSourceLookup = `${addressLookUp}/sale-sources`;
export const getAddressSaleSourceLookupWithID = (id: number) => `${addressSaleSourceLookup}/${id}`;

// SpendingCategoryLookup
export const addressSpendingCategoryLookup = `${addressLookUp}/spending-category`;
export const getAddressSpendingCategoryLookupWithID = (id: number) => `${addressSpendingCategoryLookup}/${id}`;

// DeliveryTypeLookup
export const addressDeliveryTypeLookup = `${addressLookUp}/delivery-types`;
export const getAddressDeliveryTypeLookupWithID = (id: number) => `${addressDeliveryTypeLookup}/${id}`;

export const addressSimpleLookup = (path: string) => `${addressLookUp}/${path}`;
export const addressSimpleLookupWithId = (path: string, id: number) => `${addressSimpleLookup(path)}/${id}`;

export const getAddressLookupWithID = (path: string, id: number) => `${path}/${id}`;

// SaleStatusLookup
export const addressSaleStatusLookup = `${addressLookUp}/sale-statuses`;
export const getAddressSaleStatusLookupWithID = (id: number) => `${addressSaleStatusLookup}/${id}`;

// Sales
export const addressSales = `${addressApi}/sales`;
export const addressSalesWithId = (id: number) => `${addressSales}/${id}`;
export const addressSalesAuditWithId = (id: number) => `${addressSales}/${id}/audit`;
export const addressSalesCustomers = `${addressSales}/customers`;
export const addressSalesExportCheques = `${addressSales}/export-cheques`;
export const addressSaleByIdUpdateCommonProperties = (id: number) => `${addressSalesWithId(id)}/update-common-properties`;

// Deliveries
export const addressDeliveries = `${addressApi}/deliveries`;
export const addressDeliveriesForCurrentUser = `${addressDeliveries}/for-current-user`;
export const addressDeliveriesAssignCourier = (id: number) => `${addressDeliveries}/${id}/assign-courier`;
export const addressDeliveriesUpdateStatus = (id: number) => `${addressDeliveries}/${id}/update-status`;
export const addressDeliveriesUpdateComment = (id: number) => `${addressDeliveries}/${id}/update-comment`;

// TaskTickets
export const addressTaskTickets = `${addressApi}/task-tickets`;
export const addressTaskTicketsToDoCount = `${addressTaskTickets}/todo-count`;
export const addressTaskTicketsWithId = (id: number) => `${addressTaskTickets}/${id}`;

// Stock
export const addressStock = `${addressApi}/storage`;
export const addressStorageBalance = `${addressStock}/balance`;

// Salaries
export const addressSalaries = `${addressApi}/salaries`;
export const addressSalariesForCurrentUser = `${addressSalaries}/for-current-user`;
export const addressSalariesByUserId = (id: number) => `${addressSalaries}/${id}`;
