import { ErrorMessage } from '@hookform/error-message';
import { Checkbox, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';

interface ISoldProductIsOrderedInputProps {
  i: number,
}

interface ISoldProductInput {
  soldProducts: {
    isOrdered: boolean,
  }[],
}

function SoldProductIsOrderedInput({
  i,
}: ISoldProductIsOrderedInputProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<ISoldProductInput>();

  return (
    <>
      <Controller
        name={`soldProducts.${i}.isOrdered`}
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            checked={value}
            onChange={onChange}
            label="Заказан"
            pt="lg"
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={`soldProducts.${i}.isOrdered`}
        render={(e) => <Text color="red" size="sm">{e.message}</Text>}
      />
    </>
  );
}

export default observer(SoldProductIsOrderedInput);
