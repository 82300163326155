import HttpClient from '../HttpClient';
import { addressCashier, addressCashierBalance, addressCashierWithId } from '../../const/const';
import { ICashbox, IGetCashboxParams, IGetCashboxBalanceResponse, IPostResponse, IPutResponse, ISendCashboxBody } from './cashboxApiTypes';

export default class CashboxApi {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getCashboxItems(params: IGetCashboxParams) {
    const data = await this.httpClient.Get<ICashbox>(addressCashier, { ...params });
    return data;
  }

  async getCashboxBalance() {
    const data = await this.httpClient.Get<IGetCashboxBalanceResponse>(addressCashierBalance);
    return data;
  }

  async postCashbox(body: ISendCashboxBody) {
    const data = await this
      .httpClient
      .Post<IPostResponse, ISendCashboxBody>(addressCashier, body);
    return data;
  }

  async putCashbox(body: ISendCashboxBody, id: number) {
    const data = await this
      .httpClient
      .Put<IPutResponse, ISendCashboxBody>(addressCashierWithId(id), body);
    return data;
  }
}
