import { Container } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { ISaleShortViewModel, ISalesItemSoldProduct } from '../../../clientApi/SaleApi/saleApiTypes';
import EmptyStateTitle from '../../_Shared/EmptyStateTitle';
import TableCheckMark from '../../_Shared/TableCheckMark';

interface ITableSaleItemsProps {
  record: ISaleShortViewModel,
}

export default function TableSaleItems({ record: { soldProducts } }: ITableSaleItemsProps) {
  return (
    <Container>
      {soldProducts.length ? (
        <DataTable<ISalesItemSoldProduct>
          records={soldProducts}
          styles={{ header: { zIndex: 0 } }}
          columns={[
            {
              accessor: 'productName',
              title: 'Название',
            },
            {
              accessor: 'quantity',
              title: 'Кол-во',
            },
            {
              accessor: 'salePriceByn',
              title: 'Цена (BYN)',
            },
            {
              accessor: 'isOrdered',
              title: 'Заказан',
              render: (item) => <TableCheckMark type={item.isOrdered} />,
            },
          ]}
        />
      ) : (
        <EmptyStateTitle title="Товаров нет" />
      )}
    </Container>
  );
}
