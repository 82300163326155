import { Title } from '@mantine/core';
import { ReactNode } from 'react';

export interface ISectionTitle {
  children: ReactNode,
  paddingY?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number,
}

export default function SectionTitle({
  children,
  paddingY,
}: ISectionTitle) {
  return (
    <Title size="h5" align="center" color="gray.7" py={paddingY}>{children}</Title>
  );
}

SectionTitle.defaultProps = {
  paddingY: 'xs',
};
