import { Box, CSSObject, Table } from '@mantine/core';
import React from 'react';
import { IDeliveriesItem } from '../../../clientApi/DeliveriesApi/deliveriesApiTypes';
import { getCustomerFullName, NullValueLabel } from '../../../utils/stringUtils';
import { DateWithTimeRange } from '../../_Shared/DateViewComponents/DateAndTime';

interface IDeliveriesPrintComponentProps {
  items: IDeliveriesItem[],
}

const boxStyles: CSSObject = {
  width: '210mm',
  padding: '0 5mm 5mm',
  position: 'relative',
  height: '297mm',
};

const tableTheadStyle = (width: string): React.CSSProperties => ({
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: '10px',
  border: '1px solid #dee2e6',
  width,
});

const tableTbodyStyle = (width: string): React.CSSProperties => ({
  fontSize: '9px',
  border: '1px solid #dee2e6',
  width,
});

const DeliveriesPrintComponent = React.forwardRef<HTMLDivElement,
IDeliveriesPrintComponentProps>(({
  items,
}: IDeliveriesPrintComponentProps, ref) => (
  <Box ref={ref} sx={boxStyles}>
    <Table
      style={{ width: '100%', position: 'relative', top: '5mm' }}
      withBorder
      withColumnBorders
      verticalSpacing={4}
      horizontalSpacing={4}
    >
      <thead>
        <tr>
          <th style={tableTheadStyle('20mm')}>Дата и время</th>
          <th style={tableTheadStyle('25mm')}>Код. дост. + Адрес</th>
          <th style={tableTheadStyle('25mm')}>Телефон и ФИО</th>
          <th style={tableTheadStyle('30mm')}>[Кол-во] + Товар</th>
          <th style={tableTheadStyle('20mm')}>Где лежит</th>
          <th style={tableTheadStyle('20mm')}>$ сумм.</th>
          <th style={tableTheadStyle('20mm')}>Итого с доставкой</th>
          <th style={tableTheadStyle('20mm')}>Статус заказа</th>
          <th style={tableTheadStyle('20mm')}>Способ доставки</th>
        </tr>
      </thead>
      {items.map((it) => (
        <tbody key={it.id}>
          <tr>
            <td rowSpan={it.soldProducts.length} style={tableTbodyStyle('20mm')}>
              <DateWithTimeRange startDate={it.startDateTimeUtc} endDate={it.endDateTimeUtc} />
            </td>
            <td rowSpan={it.soldProducts.length} style={tableTbodyStyle('25mm')}>
              <p>{it.trackNumber || NullValueLabel}</p>
              <p>{it.address}</p>
            </td>
            <td rowSpan={it.soldProducts.length} style={tableTbodyStyle('25mm')}>
              <p>{getCustomerFullName(it.customer)}</p>
              <p>{it.customer.phone}</p>
            </td>
            <td style={tableTbodyStyle('30mm')}>
              {`[x${it.soldProducts[0].quantity}] ${it.soldProducts[0].productName}`}
            </td>
            <td style={tableTbodyStyle('20mm')}>
              {it.soldProducts[0].storage.label}
            </td>
            <td style={tableTbodyStyle('20mm')}>
              {`р.${(it.soldProducts[0].salePriceByn * it.soldProducts[0].quantity).toFixed(2)}`}
            </td>
            <td rowSpan={it.soldProducts.length} style={tableTbodyStyle('20mm')}>
              {`р.${(it
                .soldProducts
                .reduce((s, a) => s + (a.salePriceByn * a.quantity), 0)
              + it.priceForCustomer).toFixed(2)}`}
            </td>
            <td rowSpan={it.soldProducts.length} style={tableTbodyStyle('20mm')}>
              {it.status.label}
            </td>
            <td rowSpan={it.soldProducts.length} style={tableTbodyStyle('20mm')}>
              {it.deliveryType.label}
            </td>
          </tr>
          {it.soldProducts.slice(1).map((item) => (
            <tr key={item.id}>
              <td style={tableTbodyStyle('30mm')}>{`[x${item.quantity}] ${item.productName}`}</td>
              <td style={tableTbodyStyle('20mm')}>{item.storage.label}</td>
              <td style={tableTbodyStyle('20mm')}>{`р.${(item.salePriceByn * item.quantity).toFixed(2)}`}</td>
            </tr>
          ))}
        </tbody>
      ))}
    </Table>
  </Box>
));

export default DeliveriesPrintComponent;
