import { Group } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useReactToPrint } from 'react-to-print';
import PageTitle from '../../_Shared/PageTitle';
import { useStores } from '../../../App';
import UpdateContentBtn from '../../_Shared/UpdateContentBtn';
import DeliveriesPrintComponent from '../DeliveriesForCurrentUser/DeliveriesPrintComponent';
import { IDeliveriesItem } from '../../../clientApi/DeliveriesApi/deliveriesApiTypes';
import UsersMultiSelect from '../../_Shared/Filters/UsersMultiSelect';
import SaleStatusMultiSelect from '../../_Shared/Filters/SaleStatusMultiSelect';
import DeliveryTypeMultiSelect from '../../_Shared/Filters/DeliveryTypeMultiSelect';
import PrintBtn from '../../_Shared/PrintBtn';
import FilterByRangeDate from '../../_Shared/Filters/FilterByRangeDate';
import DeliveriesTable from './DeliveriesTable';

function Deliveries() {
  const [FilterByDeliveryTypeIds, setFilterByDeliveryTypeIds] = useState<number[] | null>(null);
  const [FilterByCourierIds, setFilterByCourierIds] = useState<number[] | null>(null);
  const [Statuses, setStatuses] = useState<number[] | null>(null);
  const [PageNumber, setPageNumber] = useState(1);
  const [PageSize] = useState(10);
  const [FilterByDeliveryDate, setFilterByDeliveryDate] = useState<(string | null)[]>([null, null]);

  const {
    deliveriesStore: {
      items,
      getDeliveries,
      isLoading,
      totalItemsCount,
    },
    clientApi: { deliveriesApi },
    profileStore: { profilePermissions: { AllDeliveries: deliveriesPermission } },
  } = useStores();

  async function fetchItems() {
    getDeliveries({
      PageNumber,
      PageSize,
      FilterByDeliveryTypeIds,
      FilterByCourierIds,
      FilterByDeliveryDateRangeStart: FilterByDeliveryDate[0],
      FilterByDeliveryDateRangeEnd: FilterByDeliveryDate[1],
      Statuses,
    });
  }

  useEffect(() => {
    fetchItems();
  }, [PageNumber, FilterByDeliveryTypeIds, FilterByCourierIds, FilterByDeliveryDate[1], Statuses]);

  const [itemsForPrint, setItemsForPrint] = useState<IDeliveriesItem[]>([]);
  const [isLoadingForPrint, setIsLoadingForPrint] = useState(false);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setItemsForPrint([]),
  });

  async function fetchItemsForPrint() {
    if (totalItemsCount > 10) {
      setIsLoadingForPrint(true);
      const [data] = await deliveriesApi.getDeliveries({
        PageNumber: 1,
        PageSize: 1000,
        FilterByDeliveryTypeIds,
        FilterByCourierIds,
        FilterByDeliveryDateRangeStart: FilterByDeliveryDate[0],
        FilterByDeliveryDateRangeEnd: FilterByDeliveryDate[1],
        Statuses,
      });
      if (data !== null) {
        setItemsForPrint(data.items);
      }
      setIsLoadingForPrint(false);
    } else {
      setItemsForPrint(items);
    }
  }

  useEffect(() => {
    if (itemsForPrint.length) {
      handlePrint();
    }
  }, [itemsForPrint]);

  return (
    <>
      <PageTitle text="Доставки" />
      <div style={{ display: 'none' }}>
        <DeliveriesPrintComponent
          ref={componentRef}
          items={itemsForPrint}
        />
      </div>
      <Group position="apart" noWrap>
        <Group position="apart" grow>
          <DeliveryTypeMultiSelect
            placeholder="Способ доставки"
            setFilterValue={setFilterByDeliveryTypeIds}
            value={FilterByDeliveryTypeIds}
          />
          <UsersMultiSelect
            placeholder="Курьер"
            setFilterValue={setFilterByCourierIds}
            value={FilterByCourierIds}
          />
          <SaleStatusMultiSelect
            placeholder="Статус"
            delivery
            setFilterValue={setStatuses}
            value={Statuses}
          />
          <FilterByRangeDate
            placeholder="Дата доставки"
            setFilterValue={setFilterByDeliveryDate}
            value={FilterByDeliveryDate}
          />
        </Group>
        <Group spacing={4} noWrap>
          <UpdateContentBtn
            action={fetchItems}
            isDisabled={isLoading}
          />
          <PrintBtn
            isLoading={isLoadingForPrint}
            isDisabled={isLoading || items.length === 0}
            onClick={fetchItemsForPrint}
          />
        </Group>
      </Group>
      <DeliveriesTable
        deliveriesPermission={deliveriesPermission}
        fetchItems={fetchItems}
        isLoading={isLoading}
        items={items}
        onPageChange={setPageNumber}
        page={PageNumber}
        pageSize={PageSize}
        totalItemsCount={totalItemsCount}
      />
    </>
  );
}

export default observer(Deliveries);
