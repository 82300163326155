import { Loader, Select } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useStores } from '../../../../App';
import { notificationsData } from '../../../../store/const';

interface IUpdateSaleDeliveryTypeProps {
  saleId: number,
  currentValue: number,
}

function UpdateSaleDeliveryType({
  saleId,
  currentValue,
}: IUpdateSaleDeliveryTypeProps) {
  const {
    deliveryTypeLookupStore: { selectItems },
    clientApi: { saleApi },
  } = useStores();

  const [isLoading, setIsLoading] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);

  async function onChange(value: number) {
    setIsLoading(true);
    const [, errorData] = await saleApi.putSaleUpdateCommonProperties(
      {
        deliveryTypeId: value,
        deliveryTypeIdSpecified: true,
      },
      saleId,
    );
    if (errorData === null) {
      setIsValidationError(false);
    }
    if (errorData) {
      setIsValidationError(true);
      showNotification(notificationsData.saleValidationErrorMsg(
        saleId,
        errorData.map((it) => it[1]),
      ));
    }
    setIsLoading(false);
  }

  return (
    <Select
      data={selectItems}
      onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
      onDoubleClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
      defaultValue={currentValue.toString()}
      icon={isLoading ? <Loader size="xs" /> : null}
      onChange={(value) => onChange(value === null ? 0 : +value)}
      error={isValidationError}
      styles={{
        input: {
          border: 'none',
        },
      }}
    />
  );
}

export default observer(UpdateSaleDeliveryType);
