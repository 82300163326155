/* eslint-disable no-unused-vars */
import { Avatar, Group, LoadingOverlay, ScrollArea, Stack, Text, ThemeIcon } from '@mantine/core';
import { observer } from 'mobx-react';
import { FaUsers } from 'react-icons/fa';
import { useStores } from '../../../App';
import { ITaskTicket } from '../../../clientApi/TaskTicketsApi/taskTicketsApiTypes';
import { IItems } from '../types';
import TaskTicket from './TaskTicket';

interface ITaskTicketsTableProps {
  loading: boolean,
  items: IItems;
  openChangeItemModal: (item: ITaskTicket) => void,
}

function TaskTicketsTable({
  loading,
  items,
  openChangeItemModal,
}: ITaskTicketsTableProps) {
  const { usersStore: { usersData } } = useStores();
  return (
    <ScrollArea style={{ height: '100%', width: '100%' }}>
      <LoadingOverlay visible={loading} zIndex={150} overlayBlur={2} />
      <Group noWrap align="flex-start">
        {Object.values(items).map((item) => (
          <Stack key={item[0].id}>
            {!item[0].assigneeUser ? (
              <Group spacing={7} position="center">
                <ThemeIcon variant="light">
                  <FaUsers />
                </ThemeIcon>
                <Text color="gray.7" weight={700}>
                  Для всех пользователей
                </Text>
              </Group>
            ) : (
              <Group spacing={7} position="center">
                <Avatar src={usersData.find((it) => it.id === item[0].assigneeUser?.userId)?.avatarBase64} radius="xl" size="sm" />
                <Text color="gray.7" weight={700}>
                  {item[0].assigneeUser.fullName}
                </Text>
              </Group>
            )}
            {item.map((it) => (
              <TaskTicket
                key={it.id}
                item={it}
                openChangeItemModal={() => openChangeItemModal(it)}
              />
            ))}
          </Stack>
        ))}
      </Group>
    </ScrollArea>
  );
}

export default observer(TaskTicketsTable);
