import { Box, ScrollArea, LoadingOverlay } from '@mantine/core';
import { useState } from 'react';
import EmptyStateTitle from '../../../_Shared/EmptyStateTitle';

const boxShadowBottom = 'rgb(0 0 0 / 5%) 0px 1px 3px, rgb(0 0 0 / 5%) 0px 10px 15px -5px, rgb(0 0 0 / 4%) 0px 7px 7px -5px';
const boxShadowTop = 'rgb(0 0 0 / 5%) 0px -1px 3px, rgb(0 0 0 / 5%) 0px -10px 15px -5px, rgb(0 0 0 / 4%) 0px -7px 7px -5px';
const boxShadow = `${boxShadowTop},${boxShadowBottom}`;

interface IDeliveriesCardsProps {
  loading: boolean,
  totalItemsCount: number,
  children: React.ReactNode,
}

export default function DeliveriesCardsWrapper({
  loading,
  totalItemsCount,
  children,
}: IDeliveriesCardsProps) {
  const [scrolled, setScrolled] = useState(false);
  return (
    <Box style={{ zIndex: '1', height: '100%', justifyContent: 'space-between', overflow: 'hidden', display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <ScrollArea
        offsetScrollbars
        my={5}
        style={{ height: '100%', position: 'relative', boxShadow: scrolled ? boxShadow : '' }}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <LoadingOverlay visible={loading} zIndex={150} overlayBlur={2} />
        {totalItemsCount === 0 && (
          <EmptyStateTitle pt={20} title="По данному запросу записей не найдено" />
        )}
        {children}
      </ScrollArea>
    </Box>
  );
}
