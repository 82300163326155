import { ActionIcon } from '@mantine/core';
import { observer } from 'mobx-react';
import { FaCompressAlt } from 'react-icons/fa';

interface ICloseAllCardsBtnProps {
  action: () => void,
  isDisabled: boolean,
}

function CloseAllCardsBtn({
  action,
  isDisabled,
}: ICloseAllCardsBtnProps) {
  return (
    <ActionIcon color="blue" size={34} onClick={action} disabled={isDisabled}>
      <FaCompressAlt size={20} />
    </ActionIcon>
  );
}

export default observer(CloseAllCardsBtn);
