import { Group, LoadingOverlay, ScrollArea, Stack, Text } from '@mantine/core';
import { ISaleShortViewModel } from '../../../clientApi/SaleApi/saleApiTypes';
import EmptyStateTitle from '../../_Shared/EmptyStateTitle';
import KanbanSalesCard from './KanbanSalesCard';

interface IKanbanSalesProps {
  items: ISaleShortViewModel[],
  loading: boolean,
}

interface IGroupingItems {
  id: number,
  title: string,
  items: ISaleShortViewModel[],
}

export default function KanbanSales({
  items,
  loading,
}: IKanbanSalesProps) {
  const groupingItems = items.reduce<IGroupingItems[]>((res, it) => {
    const { id } = it.status;
    const index = res.findIndex((el) => el.id === id);
    if (index >= 0) {
      res[index].items.push(it);
    } else {
      res.push({
        id,
        title: it.status.label,
        items: [it],
      });
    }
    return res;
  }, []).sort((a, b) => a.id - b.id);
  return (
    <ScrollArea offsetScrollbars style={{ height: '100%', width: '100%', zIndex: '1' }}>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      {groupingItems.length ? (
        <Group noWrap align="flex-start">
          {groupingItems.map((item) => (
            <Stack key={item.id} spacing="xs" pt="xs">
              <Text color="gray.7" align="center" weight={700}>
                {`${item.title} (${item.items.length})`}
              </Text>
              {item.items.map((it) => (
                <KanbanSalesCard
                  key={it.id}
                  item={it}
                />
              ))}
            </Stack>
          ))}
        </Group>
      ) : (
        <EmptyStateTitle pt={20} title="По данному запросу продаж не найдено" />
      )}
    </ScrollArea>
  );
}
