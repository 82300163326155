import { Group, Stack, Text } from '@mantine/core';
import CopyButtonWithIcon from '../CopyButtonWithIcon';

interface PasswordMsgProps {
  password: string,
  fullName: string,
}

export default function PasswordMsg({
  password,
  fullName,
}: PasswordMsgProps) {
  return (
    <Stack spacing={2}>
      <Text>{`Новый пароль для пользователя: ${fullName}`}</Text>
      <Group noWrap>
        <Text>{password}</Text>
        <CopyButtonWithIcon copyValue={password} />
      </Group>
    </Stack>
  );
}
