import { Modal, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { FaFileExport } from 'react-icons/fa';
import { useStores } from '../../../App';
import { IGetSalesExportChequesForm } from '../../../clientApi/SaleApi/saleApiTypes';
import FormWrapper from '../../_Shared/FormElements/FormWrapper';
import CheckPrintingFormContent from './CheckPrintingFormContent';

interface ICheckPrintingModalProps {
  isOpened: boolean,
  setClosed: () => void,
}

function CheckPrintingModal({
  isOpened,
  setClosed,
}: ICheckPrintingModalProps) {
  const {
    clientApi: { saleApi },
    deliveryTypeLookupStore: { selectItems },
  } = useStores();

  const [isFormLoading, setIsFormLoading] = useState(false);

  async function fetchData(data: IGetSalesExportChequesForm) {
    setIsFormLoading(true);
    const response = await saleApi.getSalesExportCheques(
      {
        FilterByDate: data.filterByDate,
        FilterByDeliveryType: data.filterByDeliveryType,
      },
      data.filterByDeliveryType === null
        ? null
        : selectItems.find((it) => +it.value === data.filterByDeliveryType)?.label,
    );
    setIsFormLoading(false);
    return response;
  }

  return (
    <Modal
      opened={isOpened}
      onClose={setClosed}
      title={(
        <Text align="center" weight="bold">
          Печать чеков
        </Text>
      )}
    >
      <FormWrapper<IGetSalesExportChequesForm, null>
        isFormLoading={isFormLoading}
        fetchData={fetchData}
        actionAfterSuccess={setClosed}
        submitBtnIcon={<FaFileExport />}
        submitBtnTitle="Экспортировать"
      >
        <CheckPrintingFormContent />
      </FormWrapper>
    </Modal>
  );
}

export default observer(CheckPrintingModal);
