import { useEffect, useState } from 'react';
import { Button, Checkbox, Group } from '@mantine/core';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { FaPlus } from 'react-icons/fa';
import TableSales from './TableSales/TableSales';
import PageTitle from '../_Shared/PageTitle';
import SwitchViewBtn from '../_Shared/SwitchViewBtn';
import KanbanSales from './KanbanSales/KanbanSales';
import UpdateContentBtn from '../_Shared/UpdateContentBtn';
import SaleStatusMultiSelect from '../_Shared/Filters/SaleStatusMultiSelect';
import UsersMultiSelect from '../_Shared/Filters/UsersMultiSelect';
import FilterByTextInput from '../_Shared/Filters/FilterByTextInput';
import FilterByProduct from '../_Shared/Filters/FilterByProduct';
import DeliveryTypeMultiSelect from '../_Shared/Filters/DeliveryTypeMultiSelect';
import PrintBtn from '../_Shared/PrintBtn';
import CheckPrintingModal from './CheckPrinting/CheckPrintingModal';
import FilterByRangeDate from '../_Shared/Filters/FilterByRangeDate';
import CloseAllCardsBtn from '../Delivery/DeliveriesActions/CloseAllCardsBtn';
import OpenAllCardsBtn from '../Delivery/DeliveriesActions/OpenAllCardsBtn';
import { useStores } from '../../App';
import { ISaleShortViewModel } from '../../clientApi/SaleApi/saleApiTypes';

function Sales() {
  const {
    clientApi: { saleApi },
    profileStore: { profilePermissions: { Sales: salesPermission } },
  } = useStores();

  const [items, setItems] = useState<ISaleShortViewModel[]>([]);
  const [loading, setLoading] = useState(false);

  const [PageNumber, setPageNumber] = useState(1);
  const [PageSize, setPageSize] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [statusesFilter, setStatusesFilter] = useState<number[] | null>(null);
  const [productIdFilter, setProductIdFilter] = useState<number | null>(null);
  const [productFilterLabel, setProductFilterLabel] = useState('');
  const [phoneFilter, setPhoneFilter] = useState<string | null>(null);
  const [FilterByManagerIds, setFilterByManagerIds] = useState<number[] | null>(null);
  const [FilterByDeliveryTypeIds, setFilterByDeliveryTypeIds] = useState<number[] | null>(null);
  const [FilterByCourierIds, setFilterByCourierIds] = useState<number[] | null>(null);
  const [FilterByDeliveryDate, setFilterByDeliveryDate] = useState<(string | null)[]>([null, null]);
  const [GetOnlyActive, setGetOnlyActive] = useState(false);

  const [isCheckPrintingModalOpened, setCheckPrintingModalOpened] = useState(false);
  const [expandedRecordIds, setExpandedRecordIds] = useState<number[]>([]);

  async function fetchItems() {
    setLoading(true);
    const [response] = await saleApi.getSales({
      PageNumber,
      PageSize,
      Statuses: statusesFilter,
      ProductId: productIdFilter,
      PhoneSearchString: phoneFilter,
      FilterByManagerIds,
      FilterByDeliveryTypeIds,
      FilterByCourierIds,
      FilterByDeliveryDateRangeStart: FilterByDeliveryDate[0],
      FilterByDeliveryDateRangeEnd: FilterByDeliveryDate[1],
      GetOnlyActive,
    });
    if (response !== null) {
      setExpandedRecordIds([]);
      setItems(response.items);
      setTotalItemsCount(response.totalItemsCount);
    }
    setLoading(false);
  }

  const [viewType, setViewType] = useState('table');
  useEffect(() => {
    if (viewType === 'kanban') {
      setPageSize(500);
      setGetOnlyActive(true);
    } else {
      setPageSize(10);
      setGetOnlyActive(false);
    }
  }, [viewType]);

  useEffect(() => {
    if (viewType === 'kanban'
      && statusesFilter === null
      && productIdFilter === null
      && phoneFilter === null
      && FilterByManagerIds === null
      && FilterByDeliveryTypeIds === null
      && FilterByCourierIds === null
      && FilterByDeliveryDate[0] === null
      && FilterByDeliveryDate[1] === null
      && GetOnlyActive === false) {
      setGetOnlyActive(true);
    } else {
      fetchItems();
    }
  }, [
    PageNumber,
    statusesFilter,
    productIdFilter,
    phoneFilter,
    FilterByManagerIds,
    FilterByDeliveryTypeIds,
    FilterByCourierIds,
    FilterByDeliveryDate[1],
    GetOnlyActive,
  ]);

  return (
    <>
      <CheckPrintingModal
        isOpened={isCheckPrintingModalOpened}
        setClosed={() => setCheckPrintingModalOpened(false)}
      />
      <Group position="apart" pb={8}>
        <SwitchViewBtn
          setViewType={setViewType}
          value={viewType}
          data={[
            { value: 'table', label: 'Таблица' },
            { value: 'kanban', label: 'Канбан' },
          ]}
        />
        <PageTitle text="Продажи" />
        <Group spacing={4}>
          {salesPermission !== 'read-only' && (
            <Link to="create" relative="path">
              <Button variant="subtle" leftIcon={<FaPlus />}>
                Добавить
              </Button>
            </Link>
          )}
          <UpdateContentBtn
            action={fetchItems}
            isDisabled={loading}
          />
          {viewType === 'table' && (
            <>
              <CloseAllCardsBtn
                action={() => setExpandedRecordIds([])}
                isDisabled={expandedRecordIds.length === 0}
              />
              <OpenAllCardsBtn
                action={() => setExpandedRecordIds(items.map((it) => it.id))}
                isDisabled={items.length === expandedRecordIds.length}
              />
            </>
          )}
          <PrintBtn
            onClick={() => setCheckPrintingModalOpened(true)}
          />
        </Group>
      </Group>
      {viewType === 'kanban' && (
        <Group position="left">
          <>
            <FilterByProduct
              placeholder="Поиск по названию товара"
              setFilterValue={setProductIdFilter}
              setFilterLabel={setProductFilterLabel}
              filterLabel={productFilterLabel}
            />
            <FilterByTextInput
              placeholder="Поиск по номеру телефона"
              setFilterValue={setPhoneFilter}
              value={phoneFilter}
            />
            <SaleStatusMultiSelect
              placeholder="Статус продажи"
              setFilterValue={setStatusesFilter}
              value={statusesFilter}
            />
            <UsersMultiSelect
              placeholder="Менеджер"
              setFilterValue={setFilterByManagerIds}
              value={FilterByManagerIds}
            />
            <DeliveryTypeMultiSelect
              placeholder="Способ доставки"
              setFilterValue={setFilterByDeliveryTypeIds}
              value={FilterByDeliveryTypeIds}
            />
            <UsersMultiSelect
              placeholder="Курьер"
              setFilterValue={setFilterByCourierIds}
              value={FilterByCourierIds}
            />
            <FilterByRangeDate
              placeholder="Дата доставки"
              setFilterValue={setFilterByDeliveryDate}
              value={FilterByDeliveryDate}
            />
            <Checkbox
              label="Только активные"
              checked={GetOnlyActive}
              onChange={(event) => setGetOnlyActive(event.currentTarget.checked)}
              disabled={!(statusesFilter !== null
                || productIdFilter !== null
                || phoneFilter !== null
                || FilterByManagerIds !== null
                || FilterByDeliveryTypeIds !== null
                || FilterByCourierIds !== null
                || FilterByDeliveryDate[0] !== null
                || FilterByDeliveryDate[1] !== null)}
            />
          </>
        </Group>
      )}
      {viewType === 'table' && (
        <TableSales
          items={items}
          loading={loading}
          totalItemsCount={totalItemsCount}
          PageSize={PageSize}
          PageNumber={PageNumber}
          setPageNumber={setPageNumber}
          setStatusesFilter={setStatusesFilter}
          setPhoneFilter={setPhoneFilter}
          setFilterByManagerIds={setFilterByManagerIds}
          setFilterByDeliveryDate={setFilterByDeliveryDate}
          setFilterByDeliveryTypeIds={setFilterByDeliveryTypeIds}
          setFilterByCourierIds={setFilterByCourierIds}
          setProductIdFilter={setProductIdFilter}
          FilterByManagerIds={FilterByManagerIds}
          statusesFilter={statusesFilter}
          FilterByDeliveryDate={FilterByDeliveryDate}
          FilterByDeliveryTypeIds={FilterByDeliveryTypeIds}
          FilterByCourierIds={FilterByCourierIds}
          phoneFilter={phoneFilter}
          productFilterLabel={productFilterLabel}
          setProductFilterLabel={setProductFilterLabel}
          expandedRecordIds={expandedRecordIds}
          setExpandedRecordIds={setExpandedRecordIds}
        />
      )}
      {viewType === 'kanban' && (
        <KanbanSales
          items={items}
          loading={loading}
        />
      )}
    </>
  );
}

export default observer(Sales);
