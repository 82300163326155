import { Select } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { useStores } from '../../../../App';
import { requiredMsg } from '../../../../const/const';

interface ISaleStatusInputProps {
  required?: boolean,
}

function SaleStatusInput({
  required,
}: ISaleStatusInputProps) {
  const { lookupsStore: { selectSaleStatusLookup } } = useStores();

  const {
    control,
    formState: { errors },
  } = useFormContext<{ statusId: number | null }>();

  return (
    <Controller
      name="statusId"
      control={control}
      rules={{ required: required && requiredMsg }}
      render={({ field: { value, onChange } }) => (
        <Select
          value={value ? value.toString() : null}
          onChange={(val) => onChange(val ? +val : null)}
          clearable
          data={selectSaleStatusLookup}
          label="Статус продажи"
          withAsterisk={required}
          error={errors.statusId?.message}
        />
      )}
    />
  );
}

SaleStatusInput.defaultProps = {
  required: true,
};

export default observer(SaleStatusInput);
