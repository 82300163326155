import { Textarea } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { ISendDeliveryComment } from '../../../clientApi/DeliveriesApi/deliveriesApiTypes';
import InputClearCross from '../../_Shared/InputClearCross';

function DeliveryUpdateCommentFormContent() {
  const {
    control,
    formState: { errors },
  } = useFormContext<ISendDeliveryComment>();

  return (
    <Controller
      name="newComment"
      control={control}
      render={({ field: { value, onChange } }) => (
        <Textarea
          value={value || ''}
          onChange={({ currentTarget: { value: val } }) => onChange(val || null)}
          label="Комментарий"
          autosize
          minRows={2}
          maxRows={4}
          error={errors.newComment?.message}
          rightSection={<InputClearCross action={() => onChange(null)} />}
        />
      )}
    />
  );
}

export default observer(DeliveryUpdateCommentFormContent);
