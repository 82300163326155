import { ActionIcon, CloseButton, FileButton, Group, Loader, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import { read, utils } from 'xlsx';
import { CurrencyTypes } from '../../../const/const';

function checkTypeFile(file: File) {
  const { type } = file;
  return !!(type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || type === 'application/vnd.ms-excel');
}

function getReceiptsData(file: File): Promise<{[key: string]: string}[]> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target?.result;
      const workbook = read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json: {[key: string]: string}[] = utils.sheet_to_json(worksheet);
      resolve(json);
    };
    reader.readAsBinaryString(file);
  });
}

const fieldsData = ['Код', 'Производитель', 'Артикул', 'Наименование товара', 'Кол-во', 'Цена', 'Валюта'];

export interface IExcelFileDataItem {
  quantity?: number,
  primaryCost?: number,
  primaryCostCurrencyId?: number,
  article?: string,
  vendorName?: string,
  productName?: string,
}

interface IXlsxInputProps {
  setExcelFileData: React.Dispatch<React.SetStateAction<IExcelFileDataItem[]>>
  isBatchSearchLoading: boolean,
}

function XlsxInput({
  setExcelFileData,
  isBatchSearchLoading,
}: IXlsxInputProps) {
  const [error, setError] = useState<null | string>(null);

  const [file, setFile] = useState<File | null>(null);
  const resetRef = useRef<() => void>(null);

  const clearFile = () => {
    setFile(null);
    resetRef.current?.();
    setError(null);
  };

  async function setFileData(val: File) {
    if (checkTypeFile(val)) {
      setError(null);
      setFile(val);
      const data = await getReceiptsData(val);
      const mapData = data.map((it) => Object.entries(it as Record<string, unknown>));
      if (mapData.every((a) => a.every((b) => fieldsData.includes(b[0])))) {
        const fileData: IExcelFileDataItem[] = data
          .map((it) => ({
            quantity: Number.isFinite(it['Кол-во']) ? Number(it['Кол-во']) : undefined,
            primaryCost: Number.isFinite(it['Цена']) ? Number(it['Цена']) : undefined,
            primaryCostCurrencyId: it['Валюта'] === 'BYN' || it['Валюта'] === 'USD' ? CurrencyTypes[it['Валюта']] : undefined,
            article: it['Артикул'] ? it['Артикул'].toString() : undefined,
            vendorName: it['Производитель'] ? it['Производитель'].toString() : undefined,
            productName: it['Наименование товара'] ? it['Наименование товара'].toString() : undefined,
          }));
        setExcelFileData(fileData);
      } else {
        setError('Файл содержит неверный формат данных');
      }
    } else {
      setError('Неверный формат файла');
    }
  }

  useEffect(() => {
    if (file !== null) {
      setFileData(file);
    } else {
      setError(null);
    }
  }, [file]);

  return (
    <Group spacing={4}>
      <FileButton
        resetRef={resetRef}
        accept=".xlsx,.xls"
        onChange={setFile}
      >
        {(props) => (
          <ActionIcon {...props}>
            <FaFileExcel color="#12b886" size={24} />
          </ActionIcon>
        )}
      </FileButton>
      {isBatchSearchLoading && <Loader size="xs" />}
      {error && !isBatchSearchLoading && <Text color="red" size="xs">{error}</Text>}
      {file && !error && !isBatchSearchLoading && <Text size="xs">{file.name}</Text>}
      {file && !isBatchSearchLoading && <CloseButton onClick={clearFile} />}
    </Group>
  );
}

export default observer(XlsxInput);
