import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { SimpleGrid, SimpleGridBreakpoint } from '@mantine/core';
import CommonSectionForm from './CommonSectionForm';
import CustomerSectionForm from './CustomerSectionForm';
import SoldProductsSectionForm from './SoldProductsSectionForm';
import DeliverySectionForm from './DeliverySectionForm';
import PaymentsSectionForm from './PaymentsSectionForm';
import TaskTicketsSectionForm from './TaskTicketsSectionForm';
import FormWrapper from '../../_Shared/FormElements/FormWrapper';
import { useStores } from '../../../App';
import { IUpdateSaleBody } from '../../../clientApi/SaleApi/saleApiTypes';

interface ISaleEditForm {
  saleId: number,
  defaultValues: IUpdateSaleBody,
}

function SaleEditForm({
  saleId,
  defaultValues,
}: ISaleEditForm) {
  const navigate = useNavigate();
  const breakpoints: SimpleGridBreakpoint[] = [
    { maxWidth: 900, cols: 1, spacing: 'md' },
  ];

  const [isFormLoading, setIsFormLoading] = useState(false);

  const { clientApi: { saleApi } } = useStores();

  function actionAfterSuccess() {
    navigate(`/sales/${saleId}`);
  }

  async function fetchData(data: IUpdateSaleBody) {
    setIsFormLoading(true);
    if (data.delivery.pickedDate !== null) {
      const year = data.delivery.pickedDate.getFullYear();
      const month = data.delivery.pickedDate.getMonth();
      const day = data.delivery.pickedDate.getDate();
      const startTime = data.delivery.inputStartTime;
      const endTime = data.delivery.inputEndTime;
      data.delivery.startDateTimeUtc = new Date(year, month, day, startTime ?? 0);
      data.delivery.endDateTimeUtc = new Date(year, month, day, endTime ?? 23);
    } else {
      data.delivery.startDateTimeUtc = null;
      data.delivery.endDateTimeUtc = null;
    }
    const response = await saleApi.putSale(data, saleId);
    setIsFormLoading(false);
    return response;
  }

  return (
    <FormWrapper<IUpdateSaleBody, null>
      isFormLoading={isFormLoading}
      withResetBtn
      defaultValues={defaultValues}
      actionAfterSuccess={actionAfterSuccess}
      fetchData={fetchData}
    >
      <SimpleGrid cols={2} breakpoints={breakpoints}>
        <CommonSectionForm />
        <CustomerSectionForm />
      </SimpleGrid>

      <SoldProductsSectionForm />

      <SimpleGrid cols={2} breakpoints={breakpoints}>
        <DeliverySectionForm />
        <PaymentsSectionForm />
        <TaskTicketsSectionForm />
      </SimpleGrid>
    </FormWrapper>
  );
}

export default observer(SaleEditForm);
