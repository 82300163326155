/* eslint-disable no-unused-vars */
import { ActionIcon, Box, Text, Button, Container, Group, Modal, Space, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { FaExclamationTriangle, FaTrashAlt } from 'react-icons/fa';
import { IDeleteLookupForm } from '../../../../types';

interface IDeleteLookupBtnProps {
  id: number,
  currentLabel: string,
  deleteForm: IDeleteLookupForm,
  fetchItems: () => Promise<void>,
  fetchDeleteItem: (id: number) => Promise<void>;
  isFormLoading: boolean,
}

export default function DeleteLookupBtn({
  id,
  currentLabel,
  deleteForm,
  fetchItems,
  fetchDeleteItem,
  isFormLoading,
}: IDeleteLookupBtnProps) {
  const [visible, setVisible] = useState(false);

  function switchModal() {
    setVisible((s) => !s);
  }

  const deleteLookup = async () => {
    await fetchDeleteItem(id);
    setVisible(false);
    fetchItems();
  };

  return (
    <>
      <Modal
        opened={visible}
        onClose={() => setVisible(false)}
        title={(
          <Text align="center" weight="bold">
            Подтверждение
          </Text>
        )}
      >
        <Container>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <FaExclamationTriangle size={40} color="orange" />
            <Text>
              {deleteForm.formDescription(currentLabel)}
            </Text>
          </Box>
        </Container>
        <Space h="xl" />
        <Container size="xs" px="xs">
          <Group position="right">
            <Button variant="subtle" onClick={switchModal}>Отмена</Button>
            <Button loading={isFormLoading} onClick={deleteLookup}>Удалить</Button>
          </Group>
        </Container>
      </Modal>
      <Tooltip withArrow label="Удалить">
        <ActionIcon color="red" onClick={() => setVisible(true)}>
          <FaTrashAlt size={20} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}
