import HttpClient from '../HttpClient';
import { addressReceipts, getAddressReceiptsWithID, getAddressUpdateExchangeRateWithID } from '../../const/const';
import { IGetReceiptsParams, IReceiptsResponse, ISendReceiptBody, IUpdateExchangeRateReceiptBody } from './receiptsApiTypes';

export default class ReceiptsApi {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getReceipts(params: IGetReceiptsParams) {
    const data = await this.httpClient.Get<IReceiptsResponse>(addressReceipts, { ...params });
    return data;
  }

  async putUpdateExchangeRate(body: IUpdateExchangeRateReceiptBody, id: number) {
    const data = await this
      .httpClient
      .Put<null, IUpdateExchangeRateReceiptBody>(getAddressUpdateExchangeRateWithID(id), body);
    return data;
  }

  async postReceipt(body: ISendReceiptBody) {
    const data = await this
      .httpClient
      .Post<null, ISendReceiptBody>(addressReceipts, body);
    return data;
  }

  async putReceipt(body: ISendReceiptBody, id: number) {
    const data = await this
      .httpClient
      .Put<null, ISendReceiptBody>(getAddressReceiptsWithID(id), body);
    return data;
  }
}
