import { DatePicker } from '@mantine/dates';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { ISendDeliveryAddForm } from '../../../../clientApi/SaleApi/saleApiTypes';
import { requiredMsg } from '../../../../const/const';
import { styleDatePickerActualDay } from '../../../../const/utils';

interface IDeliveryStartDateTimeInputProps {
  required?: boolean,
}

interface IDeliveryInput {
  delivery: ISendDeliveryAddForm,
}

function DeliveryStartDateTimeInput({
  required,
}: IDeliveryStartDateTimeInputProps) {
  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext<IDeliveryInput>();

  return (
    <Controller
      name="delivery.pickedDate"
      control={control}
      rules={{ required: required && requiredMsg }}
      render={({ field: { onChange, value } }) => (
        <DatePicker
          value={value}
          onChange={(val) => {
            clearErrors(['delivery.endDateTimeUtc', 'delivery.startDateTimeUtc']);
            onChange(val);
          }}
          label="Дата доставки"
          placeholder="Выберите дату"
          clearable
          withAsterisk={required}
          error={errors.delivery?.startDateTimeUtc?.message}
          dayStyle={styleDatePickerActualDay}
        />
      )}
    />
  );
}

DeliveryStartDateTimeInput.defaultProps = {
  required: true,
};

export default observer(DeliveryStartDateTimeInput);
