import { Menu, UnstyledButton, Group, Avatar, createStyles, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { FaAngleDown, FaRegUser, FaSignOutAlt } from 'react-icons/fa';
import { useStores } from '../../../../App';
import CustomNavLink from '../LinksGroup/CustomNavLink';

const useStyles = createStyles((theme) => ({
  user: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',
    width: '100%',
    height: '50px',

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    },
  },
  userActive: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
  },
}));

interface IUserMenuProps {
  onLinkClick?: () => void,
  isNavbarOpen?: boolean,
}

function UserMenu({
  onLinkClick,
  isNavbarOpen,
}: IUserMenuProps) {
  const { classes, cx } = useStyles();
  const [userMenuOpened, setUserMenuOpened] = useState(false);

  const { loginStore, profileStore: { profileData: { avatarBase64, fullName } } } = useStores();

  return (
    <Menu
      width={236}
      position="top"
      transition="pop-top-right"
      onClose={() => setUserMenuOpened(false)}
      onOpen={() => setUserMenuOpened(true)}
    >
      <Menu.Target>
        <UnstyledButton
          p={0}
          className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
        >
          <Group position="apart" noWrap>
            <Group
              spacing={isNavbarOpen ? 7 : 16}
              noWrap
              pl={isNavbarOpen ? 0 : 2}
            >
              <Avatar src={avatarBase64} alt={fullName} radius="xl" size={30} />
              <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
                {fullName}
              </Text>
            </Group>
            <FaAngleDown size={12} />
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <CustomNavLink
          to="/profile"
          label="Профиль"
          icon={FaRegUser}
          onClick={onLinkClick ? () => {
            setUserMenuOpened(false);
            onLinkClick();
          } : undefined}
        />
        <Menu.Divider />
        <Menu.Item onClick={loginStore.postLogout} icon={<FaSignOutAlt size={14} />}>
          Выход
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

export default observer(UserMenu);

UserMenu.defaultProps = {
  onLinkClick: undefined,
  isNavbarOpen: true,
};
