/* eslint-disable no-unused-vars */
import TableLookup from './TableLookup/TableLookup';
import PageTitle from '../../../_Shared/PageTitle';
import { IFormInfo } from '../../types';
import { ISimpleLookupItem, ISendSimpleLookupBody } from '../../../../clientApi/LookupsApi/lookupsApiTypes';
import { TErrors } from '../../../../clientApi/apiTypes';

interface ILookupContentProps {
  title: string,
  formInfo: IFormInfo;
  isLoading: boolean;
  isFormLoading: boolean;
  items: ISimpleLookupItem[];
  fetchAddItem: (body: ISendSimpleLookupBody) => Promise<TErrors | null | false>;
  fetchChangeItem: (body: ISendSimpleLookupBody,
    id: number) => Promise<TErrors | null | false>;
  fetchDeleteItem: (id: number) => Promise<void>;
  fetchItems: () => Promise<void>,
}

export default function LookupContent({
  title,
  formInfo,
  isLoading,
  isFormLoading,
  items,
  fetchAddItem,
  fetchChangeItem,
  fetchDeleteItem,
  fetchItems,
}: ILookupContentProps) {
  return (
    <>
      <PageTitle text={title} />
      <TableLookup
        formInfo={formInfo}
        isLoading={isLoading}
        isFormLoading={isFormLoading}
        items={items}
        fetchAddItem={fetchAddItem}
        fetchChangeItem={fetchChangeItem}
        fetchDeleteItem={fetchDeleteItem}
        fetchItems={fetchItems}
      />
    </>
  );
}
