import { NumberInput } from '@mantine/core';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ICreateSaleBody } from '../../../../clientApi/SaleApi/saleApiTypes';
import { requiredMsg } from '../../../../const/const';
import { actionOnFocusNumberInput } from '../../../../const/utils';

interface IPaymentAmountInputProps {
  required?: boolean,
  i: number,
}

function PaymentAmountInput({
  required,
  i,
}: IPaymentAmountInputProps) {
  const {
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<ICreateSaleBody>();

  const sumByProducts = watch('soldProducts').reduce((res, it) => res + (it.salePriceByn || 0) * (it.quantity || 0), 0);
  const totalAmount = (watch('delivery.priceForCustomer') || 0) + sumByProducts;

  const payments = watch('payments');

  useEffect(() => {
    if (payments.length === 1) {
      setValue(`payments.${i}.paymentAmount`, totalAmount);
      clearErrors(`payments.${i}.paymentAmount`);
      clearErrors('payments');
    }
  }, [totalAmount]);

  return (
    <Controller
      name={`payments.${i}.paymentAmount`}
      control={control}
      rules={{ required: required && requiredMsg }}
      render={({ field: { value, onChange } }) => (
        <NumberInput
          value={value !== null ? value : undefined}
          onChange={(val) => {
            onChange(val === undefined ? null : val);
            clearErrors('payments');
          }}
          label="Сумма платежа"
          precision={2}
          min={0}
          withAsterisk={required}
          error={errors.payments && errors.payments[i]?.paymentAmount?.message}
          onFocus={actionOnFocusNumberInput}
        />
      )}
    />
  );
}

PaymentAmountInput.defaultProps = {
  required: true,
};

export default observer(PaymentAmountInput);
