import { Select } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { useStores } from '../../../../App';
import { requiredMsg } from '../../../../const/const';
import SelectUsersItem from '../../AutoCompleteItemComponents/SelectUsersItem';

interface IDeliveryCourierInputProps {
  required?: boolean,
}

interface IDeliveryTypeInput {
  delivery: {
    courierId: number | null,
  },
}

function DeliveryCourierInput({
  required,
}: IDeliveryCourierInputProps) {
  const { usersStore: { selectUsersData } } = useStores();

  const {
    control,
    formState: { errors },
  } = useFormContext<IDeliveryTypeInput>();

  return (
    <Controller
      name="delivery.courierId"
      control={control}
      rules={{ required: required && requiredMsg }}
      render={({ field: { value, onChange } }) => (
        <Select
          value={value ? value.toString() : null}
          onChange={(val) => onChange(val ? +val : null)}
          clearable
          data={selectUsersData}
          label="Курьер"
          withAsterisk={required}
          error={errors.delivery?.courierId?.message}
          itemComponent={SelectUsersItem}
        />
      )}
    />
  );
}

DeliveryCourierInput.defaultProps = {
  required: true,
};

export default observer(DeliveryCourierInput);
