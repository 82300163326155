import { ActionIcon, Text, Modal, Tooltip, Stack } from '@mantine/core';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { FaPen } from 'react-icons/fa';
import { useStores } from '../../../App';
import { ISendDeliveryComment } from '../../../clientApi/DeliveriesApi/deliveriesApiTypes';
import FormWrapper from '../../_Shared/FormElements/FormWrapper';
import DeliveryUpdateCommentFormContent from './DeliveryUpdateCommentFormContent';

interface IDeliveryUpdateCommentBtnProps {
  deliveryID: number,
  currentComment: string,
  fetchItems: () => Promise<void>,
}

function DeliveryUpdateCommentBtn({
  deliveryID,
  currentComment,
  fetchItems,
}: IDeliveryUpdateCommentBtnProps) {
  const { clientApi: { deliveriesApi } } = useStores();

  const [opened, setOpened] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const defaultValues = {
    newComment: currentComment === null ? '' : currentComment,
  };

  async function fetchData(data: ISendDeliveryComment) {
    setIsFormLoading(true);
    const response = await deliveriesApi.putUpdateCommentDelivery(data, deliveryID);
    setIsFormLoading(false);
    return response;
  }

  function actionAfterSuccess() {
    setOpened(false);
    fetchItems();
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={(
          <Text align="center" weight="bold">
            Изменение комментария
          </Text>
        )}
      >
        <FormWrapper<ISendDeliveryComment, null>
          isResetAfterClose
          defaultValues={defaultValues}
          isFormLoading={isFormLoading}
          fetchData={fetchData}
          actionAfterSuccess={actionAfterSuccess}
          onCancelAction={() => setOpened(false)}
        >
          <Stack>
            {currentComment ? (
              <Text>
                {`Текущий комментарий: ${currentComment}`}
              </Text>
            ) : (
              <Text>
                Текущего комментария нет
              </Text>
            )}
            <DeliveryUpdateCommentFormContent />
          </Stack>
        </FormWrapper>
      </Modal>
      <Tooltip withArrow label="Изменить комментарий" openDelay={500} closeDelay={100}>
        <ActionIcon color="blue" onClick={() => setOpened(true)}>
          <FaPen size={15} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}

export default observer(DeliveryUpdateCommentBtn);
