import { NavLink, ThemeIcon } from '@mantine/core';
import { IconType } from 'react-icons';
import { Link, useMatch } from 'react-router-dom';

interface CustomLinkProp {
  to: string,
  label: string,
  icon?: IconType,
  className? : string,
  onClick?: () => void,
}

export default function CustomNavLink({
  to,
  label,
  icon: Icon,
  className,
  onClick,
}: CustomLinkProp) {
  const match = useMatch(to);
  const active = !!match;

  return (
    <NavLink
      component={Link}
      to={to}
      label={label}
      active={active}
      icon={Icon ? (
        <ThemeIcon variant="light">
          <Icon />
        </ThemeIcon>
      ) : null}
      className={className}
      onClick={onClick}
    />
  );
}
CustomNavLink.defaultProps = {
  icon: null,
  className: '',
  onClick: undefined,
};
