import { Avatar, Box, Group, Text } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { observer } from 'mobx-react';
import { useStores } from '../../../App';
import { IUser } from '../types';
import ChangeAdvertiserOptions from './UserActions/ChangeAdvertiserOptions';
import ChangeManagerOptions from './UserActions/ChangeManagerOptions';
import UserActions from './UserActions/UserActions';

function TableUsers() {
  const {
    usersStore: { usersData, isLoading },
    profileStore: { profilePermissions: { Users: usersPermission } },
  } = useStores();

  return (
    <Box sx={{ overflow: 'hidden', height: '100%' }}>
      <DataTable<IUser>
        noRecordsText="По данному запросу записей не найдено"
        highlightOnHover
        fetching={isLoading}
        records={usersData}
        columns={[
          {
            accessor: 'avatarBase64',
            title: '',
            width: '30px',
            render: ({ avatarBase64 }) => (
              <Avatar size={26} src={avatarBase64} radius={26} />
            ),
          },
          {
            accessor: 'fullName',
            title: 'Имя',
          },
          {
            accessor: 'email',
            title: 'Почта',
          },
          {
            accessor: 'roleLabel',
            title: 'Роль',
          },
          {
            accessor: 'otherInfo',
            title: 'Доп. информация',
            render: (user) => {
              if (user.roleKey === 'Manager') {
                return (
                  <Group>
                    <Box>
                      <Text>{`% от продаж: ${user.salaryRates.rateFromSales}`}</Text>
                      <Text>{`% от допродаж: ${user.salaryRates.rateFromPostSales}`}</Text>
                    </Box>
                    {usersPermission === 'read-and-edit' && (
                      <ChangeManagerOptions
                        userId={user.id}
                        fullName={user.fullName}
                        rateFromSales={user.salaryRates.rateFromSales}
                        rateFromPostSales={user.salaryRates.rateFromPostSales}
                      />
                    )}
                  </Group>
                );
              }
              if (user.roleKey === 'Advertiser') {
                return (
                  <Group>
                    <Box>
                      <Text>{`% от источников: ${user.salaryRates.rateFromSaleSources}`}</Text>
                      <Text>{`источники: ${user.salaryRates.saleSources.map((it) => it.label).join(', ')}`}</Text>
                    </Box>
                    {usersPermission === 'read-and-edit' && (
                      <ChangeAdvertiserOptions
                        userId={user.id}
                        fullName={user.fullName}
                        rateFromSaleSources={user.salaryRates.rateFromSaleSources}
                        saleSourcesIds={user.salaryRates.saleSources.map((it) => it.id)}
                      />
                    )}
                  </Group>
                );
              }
              return (
                <Text>-</Text>
              );
            },
          },
          {
            accessor: 'actions',
            title: '',
            hidden: usersPermission !== 'read-and-edit',
            render: (user) => (
              <UserActions
                user={user}
              />
            ),
          },
        ]}
      />
    </Box>
  );
}

export default observer(TableUsers);
