import { Space, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';

interface IFilterInTableCellProps {
  title?: string,
  children: ReactNode,
}

function FilterInTableCell({
  title,
  children,
}: IFilterInTableCellProps) {
  return (
    <>
      <div style={{ all: 'initial' }}>
        {children}
      </div>
      <Space h="xs" />
      {title && (
        <Text>{title}</Text>
      )}
    </>
  );
}

FilterInTableCell.defaultProps = {
  title: null,
};

export default observer(FilterInTableCell);
