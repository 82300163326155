import { ErrorMessage } from '@hookform/error-message';
import { Grid, Stack } from '@mantine/core';
import { useFormContext } from 'react-hook-form';
import CommentAboutCustomerInput from '../../_Shared/FormElements/textInputs/CommentAboutCustomerInput';
import CustomerFirstNameInput from '../../_Shared/FormElements/textInputs/CustomerFirstNameInput';
import CustomerLastNameInput from '../../_Shared/FormElements/textInputs/CustomerLastNameInput';
import CustomerMiddleNameInput from '../../_Shared/FormElements/textInputs/CustomerMiddleNameInput';
import CustomerPhoneInput from '../../_Shared/FormElements/textInputs/CustomerPhoneInput';
import SectionTitle from '../../_Shared/SectionTitle';

export default function CustomerSectionForm() {
  const { formState: { errors } } = useFormContext();

  return (
    <Stack spacing="xs" sx={{ minWidth: '300px' }}>
      <SectionTitle>Клиент</SectionTitle>
      <ErrorMessage errors={errors} name="customer" />

      <Grid gutter="xs">
        <Grid.Col sm={6}>
          <CustomerPhoneInput />
        </Grid.Col>
        <Grid.Col sm={6}>
          <CustomerFirstNameInput required={false} />
        </Grid.Col>
      </Grid>

      <Grid gutter="xs">
        <Grid.Col sm={6}>
          <CustomerMiddleNameInput required={false} />
        </Grid.Col>
        <Grid.Col sm={6}>
          <CustomerLastNameInput required={false} />
        </Grid.Col>
      </Grid>

      <CommentAboutCustomerInput required={false} />
    </Stack>
  );
}
