import HttpClient from '../HttpClient';
import { addressProductsBatchSearch, addressProductsQuery } from '../../const/const';
import { IGetExactProductsBySearchModelResponse, IGetExactProductsBySearchModelRequest, IGetProductBySearchStringParams, IGetProductBySearchStringResponse } from './productsApiTypes';

export default class ProductsApi {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getProductBySearchString(params: IGetProductBySearchStringParams) {
    const data = await this
      .httpClient
      .Get<IGetProductBySearchStringResponse>(addressProductsQuery, { ...params });
    return data;
  }

  async putProductsBatchSearch(body: IGetExactProductsBySearchModelRequest) {
    const data = await this
      .httpClient
      .Put<
        IGetExactProductsBySearchModelResponse,
        IGetExactProductsBySearchModelRequest
      >(addressProductsBatchSearch, body, false);
    return data;
  }
}
