/* eslint-disable no-unused-vars */
import { Modal, Text, Container, TextInput, Space, Group, Button, ActionIcon, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { useForm, SubmitHandler, Controller, Path } from 'react-hook-form';
import { FaPen, FaSave } from 'react-icons/fa';
import { TErrors } from '../../../../../../clientApi/apiTypes';
import { ISendSimpleLookupBody } from '../../../../../../clientApi/LookupsApi/lookupsApiTypes';
import { requiredMsg } from '../../../../../../const/const';
import InputClearCross from '../../../../../_Shared/InputClearCross';
import { IUpdateLookupForm } from '../../../../types';

interface IUpdateLookupBtnProps {
  id: number,
  currentLabel: string,
  updateForm: IUpdateLookupForm,
  fetchChangeItem: (body: ISendSimpleLookupBody,
    id: number) => Promise<TErrors | null | false>;
  fetchItems: () => Promise<void>,
  isFormLoading: boolean,
}

export default function UpdateLookupBtn({
  id,
  currentLabel,
  updateForm,
  fetchChangeItem,
  fetchItems,
  isFormLoading,
}: IUpdateLookupBtnProps) {
  const [visible, setVisible] = useState(false);

  const defaultValues = {
    label: currentLabel,
  };

  const {
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm<ISendSimpleLookupBody>({ defaultValues });

  function switchModal() {
    setVisible((s) => !s);
    reset(defaultValues);
  }

  const updateLookup: SubmitHandler<ISendSimpleLookupBody> = async (data) => {
    const dataErrors = await fetchChangeItem(data, id);
    if (Array.isArray(dataErrors)) {
      dataErrors
        .forEach((it) => setError(it[0] as Path<ISendSimpleLookupBody>, { message: it[1] }));
    } else if (dataErrors === null) {
      setVisible(false);
      reset(defaultValues);
      fetchItems();
    }
  };

  return (
    <>
      <Modal
        opened={visible}
        onClose={switchModal}
        title={(
          <Text align="center" weight="bold">
            {updateForm.formTitle}
          </Text>
        )}
      >
        <form onSubmit={handleSubmit(updateLookup)}>
          <Text>
            {updateForm.formDescription(currentLabel)}
          </Text>
          <Space h="xl" />
          <Container>
            <Controller
              name="label"
              control={control}
              rules={{ required: requiredMsg }}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  value={value}
                  onChange={({ currentTarget: { value: val } }) => onChange(val)}
                  label={updateForm.inputLabel}
                  withAsterisk
                  icon={updateForm.inputIcon}
                  error={errors.label?.message}
                  rightSection={<InputClearCross action={() => onChange('')} />}
                />
              )}
            />
          </Container>
          <Space h="xl" />
          <Container size="xs" px="xs">
            <Group position="right">
              <Button variant="subtle" onClick={switchModal}>Отмена</Button>
              <Button loading={isFormLoading} type="submit" leftIcon={<FaSave />}>Сохранить</Button>
            </Group>
          </Container>
        </form>
      </Modal>
      <Tooltip withArrow label="Изменить название">
        <ActionIcon color="blue" onClick={() => setVisible(true)}>
          <FaPen size={20} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}
