import { Container, Text } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { IReceipt, IReceiptItem } from '../../clientApi/ReceiptsApi/receiptsApiTypes';
import EmptyStateTitle from '../_Shared/EmptyStateTitle';

export interface ITableRowReceiptsProps {
  record: IReceipt,
}

export default function TableReceiptItems({ record: { receiptItems } }: ITableRowReceiptsProps) {
  return (
    <Container size="md" py="xs" px="lg">
      {receiptItems.length ? (
        <DataTable<IReceiptItem>
          records={receiptItems}
          idAccessor="productId"
          styles={{ header: { zIndex: 0 } }}
          columns={[
            {
              accessor: 'productName',
              title: 'Название товара',
            },
            {
              accessor: 'quantity',
              title: 'Кол-во',
            },
            {
              accessor: 'primaryCostByn',
              title: 'Цена (BYN)',
              render: (it) => (
                <Text>
                  {it.primaryCostByn.toFixed(2)}
                  {it.primaryCostByn !== it.specifiedPrimaryCost && (
                    <Text span size="xs">{` (${it.specifiedPrimaryCost.toFixed(2)}$)`}</Text>
                  )}
                </Text>
              ),
            },
          ]}
        />
      ) : (
        <EmptyStateTitle title="Товаров нет" />
      )}
    </Container>
  );
}
