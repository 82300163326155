import { addressSalaries, addressSalariesByUserId, addressSalariesForCurrentUser } from '../../const/const';
import HttpClient from '../HttpClient';
import { IGetSalariesParams, IGetSalariesResponse, IGetUserSalaryResponse } from './salariesApiTypes';

export default class SalariesApi {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getSalaries(params: IGetSalariesParams) {
    const data = await this
      .httpClient
      .Get<IGetSalariesResponse>(addressSalaries, { ...params });
    return data;
  }

  async getSalariesForCurrentUser(params: IGetSalariesParams) {
    const data = await this
      .httpClient
      .Get<IGetUserSalaryResponse>(addressSalariesForCurrentUser, { ...params });
    return data;
  }

  async getSalariesByUserId(id: number, params: IGetSalariesParams) {
    const data = await this
      .httpClient
      .Get<IGetUserSalaryResponse>(addressSalariesByUserId(id), { ...params });
    return data;
  }
}
