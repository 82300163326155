import { MantineSize, TextInput } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { observer } from 'mobx-react';
import { ReactNode, useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import InputClearCross from '../InputClearCross';

interface IFilterByTextInputProps {
  label?: string,
  placeholder?: string,
  size?: MantineSize,
  icon?: ReactNode,
  setFilterValue: React.Dispatch<React.SetStateAction<string | null>>,
  value: string | null,
}

function FilterByTextInput({
  label,
  placeholder,
  size,
  icon,
  setFilterValue,
  value,
}: IFilterByTextInputProps) {
  const [filterVal, setFilterVal] = useState<string | null>(value);

  const [debouncedVal] = useDebouncedValue<string | null>(filterVal, 300);

  useEffect(() => {
    setFilterValue(debouncedVal);
  }, [debouncedVal]);

  return (
    <TextInput
      label={label}
      placeholder={placeholder}
      size={size}
      icon={icon}
      onChange={({ target: { value: val } }) => setFilterVal(val || null)}
      value={filterVal || ''}
      rightSection={(
        <InputClearCross action={() => {
          setFilterValue(null);
          setFilterVal(null);
        }}
        />
      )}
      rightSectionWidth={20}
    />
  );
}

FilterByTextInput.defaultProps = {
  label: undefined,
  placeholder: undefined,
  size: 'xs',
  icon: <FaSearch />,
};

export default observer(FilterByTextInput);
