import { ActionIcon, Text, Modal, Tooltip, Stack } from '@mantine/core';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { FaPen } from 'react-icons/fa';
import { useStores } from '../../../App';
import { ISendDeliveryStatusID } from '../../../clientApi/DeliveriesApi/deliveriesApiTypes';
import FormWrapper from '../../_Shared/FormElements/FormWrapper';
import DeliveryUpdateStatusFormContent from './DeliveryUpdateStatusFormContent';

interface IDeliveryUpdateStatusBtnProps {
  deliveryID: number,
  currentStatusID: number,
  currentStatusLabel: string,
  fetchItems: () => Promise<void>,
}

function DeliveryUpdateStatusBtn({
  deliveryID,
  currentStatusID,
  currentStatusLabel,
  fetchItems,
}: IDeliveryUpdateStatusBtnProps) {
  const { clientApi: { deliveriesApi } } = useStores();

  const [opened, setOpened] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const defaultValues = {
    newStatusId: currentStatusID,
  };

  async function fetchData(data: ISendDeliveryStatusID) {
    setIsFormLoading(true);
    const response = await deliveriesApi.putUpdateStatusDelivery(data, deliveryID);
    setIsFormLoading(false);
    return response;
  }

  function actionAfterSuccess() {
    setOpened(false);
    fetchItems();
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={(
          <Text align="center" weight="bold">
            Изменение статуса доставки
          </Text>
        )}
      >
        <FormWrapper<ISendDeliveryStatusID, null>
          isResetAfterClose
          defaultValues={defaultValues}
          isFormLoading={isFormLoading}
          fetchData={fetchData}
          actionAfterSuccess={actionAfterSuccess}
          onCancelAction={() => setOpened(false)}
        >
          <Stack>
            <Text>
              {`Текущий статус доставки: ${currentStatusLabel}`}
            </Text>
            <DeliveryUpdateStatusFormContent />
          </Stack>
        </FormWrapper>
      </Modal>
      <Tooltip withArrow label="Изменить статус" openDelay={500} closeDelay={100}>
        <ActionIcon color="blue" onClick={() => setOpened(true)}>
          <FaPen size={15} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}

export default observer(DeliveryUpdateStatusBtn);
