import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Group, Loader, Text } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import PageTitle from '../_Shared/PageTitle';
import UpdateContentBtn from '../_Shared/UpdateContentBtn';
import FilterByProduct from '../_Shared/Filters/FilterByProduct';
import { useStores } from '../../App';
import { IStorageItemViewModel } from '../../clientApi/StorageApi/storageApiTypes';

function Storage() {
  const { clientApi: { storageApi } } = useStores();

  const [pageSize] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState<IStorageItemViewModel[]>([]);
  const [filterItemId, setFilterItemId] = useState<number | null>(null);
  const [productFilterLabel, setProductFilterLabel] = useState('');

  const [storageBalance, setStorageBalance] = useState('');
  const [isStorageBalanceLoading, setIsStorageBalanceLoading] = useState(false);

  async function fetchStorageItems() {
    setIsLoading(true);
    const [response] = await storageApi.getStorage({
      FilterByProductId: filterItemId,
      PageNumber: page,
      PageSize: pageSize,
    });
    if (response !== null) {
      setItems(response.items);
      setTotalItemsCount(response.totalItemsCount);
    }

    setIsLoading(false);
  }

  async function fetchStorageBalance() {
    setIsStorageBalanceLoading(true);
    const [response] = await storageApi.getStorageBalance();
    if (response !== null) {
      setStorageBalance(response.balanceByn.toFixed(2));
    }
    setIsStorageBalanceLoading(false);
  }

  function fetchItems() {
    fetchStorageItems();
    fetchStorageBalance();
  }

  useEffect(() => {
    fetchStorageBalance();
  }, []);

  useEffect(() => {
    fetchStorageItems();
  }, [page, filterItemId]);

  return (
    <>
      <PageTitle text="Склад" />
      <Group spacing={8} noWrap>
        <Text size="sm">Баланс склада:</Text>
        <Text span>{`${storageBalance} BYN`}</Text>
        {isStorageBalanceLoading && <Loader size="xs" />}
      </Group>
      <Group position="apart" px={4}>
        <Box sx={{ minWidth: '300px' }}>
          <FilterByProduct
            placeholder="Поиск по названию товара"
            setFilterValue={setFilterItemId}
            filterLabel={productFilterLabel}
            setFilterLabel={setProductFilterLabel}
          />
        </Box>
        <UpdateContentBtn
          action={fetchItems}
          isDisabled={isLoading}
        />
      </Group>

      <Box sx={{ overflow: 'hidden', height: '100%' }}>
        <DataTable<IStorageItemViewModel>
          noRecordsText="По данному запросу записей не найдено"
          highlightOnHover
          fetching={isLoading}
          records={items}
          idAccessor="product.id"
          columns={[
            { accessor: 'product.name', title: 'Наименование товара' },
            {
              accessor: 'primaryCostByn',
              title: 'Себестоимость',
              render: ({ primaryCostByn }) => primaryCostByn.toFixed(2),
            },
            { accessor: 'countAvailable', title: 'Доступно' },
            { accessor: 'countReserved', title: 'Бронь' },
            { accessor: 'countWrittenOff', title: 'Продано' },
            { accessor: 'countAll', title: 'Всего' },
          ]}
          totalRecords={totalItemsCount}
          recordsPerPage={pageSize}
          page={page}
          onPageChange={(p) => setPage(p)}
          paginationText={({ from, to, totalRecords }) => `Записи ${from} - ${to} из ${totalRecords}`}
        />
      </Box>
    </>
  );
}

export default observer(Storage);
