import { observer } from 'mobx-react';
import { useStores } from '../../../App';
import { spendingCategoryFormInfo } from '../const';
import LookupContent from './LookupContent/LookupContent';

function SpendingCategoryLookup() {
  const { spendingCategoryLookupStore: store } = useStores();

  return (
    <LookupContent
      title="Категории расходов"
      formInfo={spendingCategoryFormInfo}
      isLoading={store.isLoading}
      isFormLoading={store.isFormLoading}
      items={store.items}
      fetchAddItem={store.postSpendingCategoryLookup}
      fetchChangeItem={store.putSpendingCategoryLookup}
      fetchDeleteItem={store.deleteSpendingCategoryLookup}
      fetchItems={store.getSpendingCategoryLookup}
    />
  );
}

export default observer(SpendingCategoryLookup);
