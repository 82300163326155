import { Autocomplete, Loader } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { FaSearch } from 'react-icons/fa';
import { getCustomerFullName } from '../../../../utils/stringUtils';
import AutoCompleteItem from '../../AutoCompleteItemComponents/AutocompletePhoneItem';
import { requiredMsg } from '../../../../const/const';
import { useStores } from '../../../../App';
import InputClearCross from '../../InputClearCross';

interface IAutocompletePhoneData {
  value: string,
  id: number | null,
  description: string,
  firstname: string | null,
  middlename: string | null,
  lastname: string | null,
  comment: string | null,
}

interface ICustomerInput {
  customer: {
    customerId: number | null,
    phone: string | null,
    firstName: string | null,
    middleName: string | null,
    lastName: string | null,
    commentAboutCustomer: string | null,
  },
}

interface ICustomerPhoneInputProps {
  required?: boolean,
}

function CustomerPhoneInput({
  required,
}: ICustomerPhoneInputProps) {
  const { clientApi: { saleApi } } = useStores();

  const {
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<ICustomerInput>();
  const inputPhone = watch('customer.phone');
  const [data, setData] = useState<IAutocompletePhoneData[]>([]);
  const [input, setInput] = useState('');
  const [debounced] = useDebouncedValue(input, 300);
  const [loading, setLoading] = useState(false);
  const [isOnceFocused, setIsOnceFocused] = useState(false);

  async function fetchData(str: string) {
    setLoading(true);
    const [response] = await saleApi.getSalesCustomers({
      PhoneSearchString: str,
    });
    if (response !== null) {
      setData(response.customers.map((it) => ({
        value: it.phone,
        id: it.id,
        description: getCustomerFullName(it),
        firstname: it.firstName,
        middlename: it.middleName,
        lastname: it.lastName,
        comment: it.comment,
      })));
    }
    setLoading(false);
  }

  useEffect(() => {
    const str = input.trim();
    if (str.length !== 0) {
      fetchData(str);
    }
  }, [debounced]);

  useEffect(() => {
    const str = inputPhone?.trim();
    if (str && isOnceFocused) {
      fetchData(str);
    }
  }, [isOnceFocused]);

  return (
    <Controller
      name="customer.phone"
      control={control}
      rules={{ required: required && requiredMsg }}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          label="Телефон клиента"
          data={data}
          value={value || ''}
          withAsterisk={required}
          onChange={(val) => {
            setInput(val);
            setValue('customer.customerId', null);
            onChange(val);
          }}
          onItemSubmit={(item: IAutocompletePhoneData) => {
            setValue('customer.customerId', item.id);
            setValue('customer.firstName', item.firstname);
            setValue('customer.middleName', item.middlename);
            setValue('customer.lastName', item.lastname);
            setValue('customer.commentAboutCustomer', item.comment);
            clearErrors([
              'customer.firstName',
              'customer.middleName',
              'customer.lastName',
              'customer.commentAboutCustomer']);
          }}
          error={errors.customer?.phone?.message}
          icon={loading ? <Loader size="xs" /> : <FaSearch />}
          itemComponent={AutoCompleteItem}
          onFocus={() => setIsOnceFocused(true)}
          rightSection={(
            <InputClearCross action={() => {
              setValue('customer.customerId', null);
              onChange(null);
              setData([]);
            }}
            />
          )}
        />
      )}
    />
  );
}

CustomerPhoneInput.defaultProps = {
  required: true,
};

export default observer(CustomerPhoneInput);
