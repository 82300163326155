import React from 'react';
import { SegmentedControl, SegmentedControlItem } from '@mantine/core';

interface ISwitchViewBtnProps {
  setViewType: React.Dispatch<React.SetStateAction<string>>,
  data: SegmentedControlItem[],
  value: string,
}

export default function SwitchViewBtn({
  setViewType,
  data,
  value,
}: ISwitchViewBtnProps) {
  return (
    <SegmentedControl
      data={data}
      onChange={setViewType}
      value={value}
    />
  );
}
