/* eslint-disable no-use-before-define */
import { ReactNode, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ActionIcon, Button, Container, Grid, Group, LoadingOverlay, ScrollArea, SimpleGrid, SimpleGridBreakpoint, Spoiler, Stack, Table, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { FaArrowCircleLeft, FaPen, FaQuestionCircle, FaRegClock } from 'react-icons/fa';
import PageTitle from '../_Shared/PageTitle';
import TableCheckMark from '../_Shared/TableCheckMark';
import TaskTicket from '../TaskTickets/TaskTicketsElements/TaskTicket';
import SectionTitle from '../_Shared/SectionTitle';
import CopyButtonWithIcon from '../_Shared/CopyButtonWithIcon';
import { formatSaleId, NullValueLabel } from '../../utils/stringUtils';
import CreateTaskForm from '../TaskTickets/CreateTaskForm/CreateTaskForm';
import { ISaleByID, ISaleIDSoldProducts } from '../../clientApi/SaleApi/saleApiTypes';
import EmptyStateTitle from '../_Shared/EmptyStateTitle';
import { FormattedDate, DateAndTime, TimeRange } from '../_Shared/DateViewComponents/DateAndTime';
import { useStores } from '../../App';
import PhoneLink from '../_Shared/PhoneLink';

const ContainerWidth = 900;

export default function SaleViewPage() {
  const {
    profileStore: { profilePermissions: { Sales: salesPermission } },
    clientApi: { saleApi },
  } = useStores();

  const { saleId } = useParams() as unknown as { saleId: string };

  const breakpoints: SimpleGridBreakpoint[] = [
    { maxWidth: ContainerWidth, cols: 1, spacing: 'md' },
  ];

  const [loading, setLoading] = useState(false);
  const [sale, setSale] = useState<ISaleByID | null>(null);
  const [isCorrectSaleId, setIsCorrectSaleId] = useState(true);

  async function fetchItem() {
    setLoading(true);
    const id = +saleId;
    if (Number.isFinite(id)) {
      const data = await saleApi.getSaleById(id);
      if (data !== null) {
        setSale(data);
      } else {
        setIsCorrectSaleId(false);
      }
    } else {
      setIsCorrectSaleId(false);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchItem();
  }, []);

  return (
    <>
      <Grid align="flex-end" pb="sm">
        <Grid.Col sm="auto">
          <Link to="/sales" relative="route" style={{ textDecoration: 'none', float: 'right' }}>
            <Button variant="subtle" leftIcon={<FaArrowCircleLeft />}>
              К продажам
            </Button>
          </Link>
        </Grid.Col>
        <Grid.Col sm="content">
          <PageTitle paddingBottom={0}>{`Продажа ${formatSaleId(saleId)}`}</PageTitle>
        </Grid.Col>
        <Grid.Col sm="auto">
          <Group spacing={4} noWrap align="center">
            {isCorrectSaleId && salesPermission === 'read-and-edit' && (
              <Link to="edit" relative="path" style={{ textDecoration: 'none', float: 'left' }}>
                <Button variant="subtle" leftIcon={<FaPen />}>
                  Изменить
                </Button>
              </Link>
            )}
            {isCorrectSaleId && (
              <Link to="audit" relative="path" style={{ textDecoration: 'none', float: 'left' }}>
                <ActionIcon color="blue" variant="subtle">
                  <FaRegClock size={16} />
                </ActionIcon>
              </Link>
            )}
          </Group>
        </Grid.Col>
      </Grid>

      {!isCorrectSaleId && (
        <EmptyStateTitle title={`Продажи с id '${saleId}' не существует`} />
      )}

      <ScrollArea offsetScrollbars style={{ height: '100%', width: '100%', display: 'flex' }}>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Container size={ContainerWidth} px="xs">
          {!loading && sale != null && (
            <Stack>
              <SimpleGrid cols={2} breakpoints={breakpoints}>
                <CommonSection sale={sale} />
                <CustomerSection sale={sale} />
              </SimpleGrid>

              <SoldProductsSection sale={sale} />

              <SimpleGrid cols={2} breakpoints={breakpoints}>
                <DeliverySection sale={sale} />
                <PaymentsSection sale={sale} />
              </SimpleGrid>

              <TaskTicketsSection
                sale={sale}
                fetchItem={fetchItem}
                saleId={+saleId}
              />

            </Stack>
          )}
        </Container>
      </ScrollArea>
    </>
  );
}
interface ISectionProps { sale: ISaleByID }
function CommonSection({ sale }: ISectionProps) {
  return (
    <Stack spacing={0}>
      <SectionTitle>Общая информация</SectionTitle>

      <Table verticalSpacing={2}>
        <tbody>
          <TableRowLabelWithValue label="Дата создания">
            <DateAndTime date={sale.createdDateUtc} isOneLine />
          </TableRowLabelWithValue>
          {sale.finishedDate && (
            <TableRowLabelWithValue label="Дата завершения">
              <DateAndTime date={sale.finishedDate} isOneLine />
            </TableRowLabelWithValue>
          )}
          <TableRowLabelWithValue label="Статус">
            {sale.status.label}
          </TableRowLabelWithValue>
          <TableRowLabelWithValue label="Менеджер">
            {sale.manager.fullName}
          </TableRowLabelWithValue>
          <TableRowLabelWithValue label="Источник">
            {sale.source.label}
          </TableRowLabelWithValue>
        </tbody>
      </Table>

      <Text size="xs">Примечание к продаже:</Text>
      {sale.comment ? (
        <Spoiler maxHeight={65} showLabel="..." hideLabel="Свернуть">
          <Text size="sm">{sale.comment}</Text>
        </Spoiler>
      ) : (
        <Text size="sm">{NullValueLabel}</Text>
      )}
    </Stack>
  );
}

function CustomerSection({ sale }: ISectionProps) {
  return (
    <Stack spacing={0}>
      <SectionTitle>Клиент</SectionTitle>

      <Table verticalSpacing={2}>
        <tbody>
          <TableRowLabelWithValue label="Телефон">
            <>
              <PhoneLink phone={sale.customer.phone} />
              <CopyButtonWithIcon copyValue={sale.customer.phone} />
            </>
          </TableRowLabelWithValue>
          <TableRowLabelWithValue label="Имя">
            {sale.customer.firstName}
          </TableRowLabelWithValue>
          <TableRowLabelWithValue label="Отчество">
            {sale.customer.middleName}
          </TableRowLabelWithValue>
          <TableRowLabelWithValue label="Фамилия">
            {sale.customer.lastName}
          </TableRowLabelWithValue>
        </tbody>
      </Table>

      <Text size="xs">Комментарий о клиенте:</Text>
      {sale.customer.comment ? (
        <Spoiler maxHeight={65} showLabel="..." hideLabel="Свернуть">
          <Text size="sm">{sale.customer.comment}</Text>
        </Spoiler>
      ) : (
        <Text size="sm">{NullValueLabel}</Text>
      )}
    </Stack>
  );
}

function DeliverySection({ sale }: ISectionProps) {
  return (
    <Stack spacing={0}>
      <SectionTitle>Доставка</SectionTitle>

      <Table verticalSpacing={2}>
        <tbody>
          <TableRowLabelWithValue label="Способ доставки">
            {sale.delivery.deliveryType.label}
          </TableRowLabelWithValue>
          <TableRowLabelWithValue label="Курьер">
            {sale.delivery.courier?.fullName ?? NullValueLabel}
          </TableRowLabelWithValue>
          <TableRowLabelWithValue label="Дата доставки">
            <FormattedDate date={sale.delivery.startDateTimeUtc} />
          </TableRowLabelWithValue>
          <TableRowLabelWithValue label="Время доставки">
            <TimeRange
              startDate={sale.delivery.startDateTimeUtc}
              endDate={sale.delivery.endDateTimeUtc}
            />
          </TableRowLabelWithValue>
          {sale.delivery.trackNumber && (
            <TableRowLabelWithValue label="Трек номер">
              {sale.delivery.trackNumber}
            </TableRowLabelWithValue>
          )}
        </tbody>
      </Table>

      <Text size="xs">Адрес:</Text>
      {sale.delivery.address ? (
        <Text size="sm">{sale.delivery.address}</Text>
      ) : (
        <Text size="sm">{NullValueLabel}</Text>
      )}
    </Stack>
  );
}

function SoldProductsSection({ sale }: ISectionProps) {
  const totalAmount = sale.delivery.priceForCustomer
    + sale.soldProducts.reduce((res, it) => res + it.salePriceByn * it.quantity, 0);

  return (
    <Stack spacing={5}>
      <SectionTitle>Товары</SectionTitle>

      {sale.soldProducts.length ? (
        <DataTable<ISaleIDSoldProducts>
          noRecordsText="Товаров нет"
          highlightOnHover
          records={sale.soldProducts}
          columns={[
            {
              accessor: 'productName',
              title: 'Товар',
            },
            {
              accessor: 'quantity',
              title: (
                <Group noWrap spacing={8}>
                  <Text>Кол-во</Text>
                  <div style={{ all: 'initial' }}>
                    <Tooltip label="Заказано / На складе" withArrow>
                      <ThemeIcon radius="xl" size="sm">
                        <FaQuestionCircle />
                      </ThemeIcon>
                    </Tooltip>
                  </div>
                </Group>
              ),
              render: (it) => (
                <Text>
                  {it.quantity}
                  <Text span size="xs">{` / ${it.receiptQueueItems.length}`}</Text>
                </Text>
              ),
            },
            {
              accessor: 'salePriceByn',
              title: 'Цена',
              render: ({ salePriceByn }) => salePriceByn.toFixed(2),
            },
            {
              accessor: 'receiptQueueItems[0].primaryPriceByn',
              title: 'C/c',
              render: ({ receiptQueueItems }) => (receiptQueueItems.length > 0
                ? (receiptQueueItems
                  .reduce((a, b) => a + b.primaryPriceByn, 0) / receiptQueueItems.length)
                  .toFixed(2)
                : NullValueLabel
              ),
            },
            {
              accessor: 'isPostSale',
              title: 'Допродажа',
              render: ({ isPostSale }) => <TableCheckMark type={isPostSale} />,
            },
            {
              accessor: 'storage.label',
              title: 'Где лежит',
            },
            {
              accessor: 'isOrdered',
              title: 'Заказан',
              render: ({ isOrdered }) => <TableCheckMark type={isOrdered} />,
            },
          ]}
        />
      ) : (
        <EmptyStateTitle title="Товаров нет" />
      )}
      {sale.soldProducts.length !== 0 && (
        <Group position="apart" align="flex-start">
          <Group spacing="sm">
            <Text color="gray.7" weight="bold" size="sm">Услуга доставки</Text>
            <Text size="xs">Для клиента:</Text>
            <Text size="sm">
              {`${sale.delivery.priceForCustomer ? sale.delivery.priceForCustomer.toFixed(2) : NullValueLabel}р`}
            </Text>
            <Text size="xs">Себестоимость:</Text>
            <Text size="sm">
              {`${sale.delivery.primaryPrice ? sale.delivery.primaryPrice.toFixed(2) : NullValueLabel}р`}
            </Text>
          </Group>
          <Text color="gray.7" weight="bold" size="sm">{`Итого: ${totalAmount.toFixed(2)}p`}</Text>
        </Group>
      )}
    </Stack>
  );
}

function PaymentsSection({ sale }: ISectionProps) {
  const totalPaymentsAmount = sale.payments
    .reduce((partialSum, it) => partialSum + it.paymentAmount, 0);

  const rows = sale.payments.map((it) => (
    <tr key={`payment_${it.id}`}>
      <td><Text size="sm">{it.paymentMethodType.label}</Text></td>
      <td><Text size="sm">{`${it.paymentAmount.toFixed(2)}р`}</Text></td>
    </tr>
  ));

  return (
    <Stack spacing={0}>
      <SectionTitle>Оплата</SectionTitle>

      <Table verticalSpacing={2} fontSize="md">
        <tbody>
          {rows}
          <tr>
            <td>
              <Text weight="bold" color="gray.7" size="sm">Итого</Text>
            </td>
            <td>
              <Text weight="bold" color="gray.7" size="sm">{`${totalPaymentsAmount.toFixed(2)}р`}</Text>
            </td>
          </tr>
        </tbody>
      </Table>

    </Stack>
  );
}

interface ITaskTicketsSectionProps {
  sale: ISaleByID
  fetchItem: () => Promise<void>,
  saleId: number,
}
function TaskTicketsSection({
  fetchItem,
  sale,
  saleId,
}: ITaskTicketsSectionProps) {
  const {
    profileStore: { profilePermissions: { TaskTickets: taskTicketsPermission } },
  } = useStores();

  return (
    <>
      <Group position="center">
        <SectionTitle>Задачи</SectionTitle>
        {taskTicketsPermission !== 'read-only' && (
          <CreateTaskForm
            fetchItem={fetchItem}
            saleId={saleId}
          />
        )}
      </Group>
      {sale.taskTickets.length === 0 && (
        <Text align="center" italic color="gray.5">Задач пока нет</Text>
      )}
      <Group align="flex-start">
        {sale.taskTickets.map((it) => (
          <TaskTicket
            openChangeItemModal={false}
            item={it}
            key={it.id}
          />
        ))}
      </Group>
    </>
  );
}

interface ITableRowLabelWithValueParams {
  label: string,
  children: string | ReactNode,
}

export function TableRowLabelWithValue({ children, label }: ITableRowLabelWithValueParams) {
  return (
    <tr key={crypto.randomUUID()}>
      <td>
        <Text size="xs" align="end">{`${label}:`}</Text>
      </td>
      <td>
        {typeof children === 'string' && (
          <Text size="sm">{children ?? NullValueLabel}</Text>
        )}
        {typeof children !== 'string' && (
          <Group spacing="xs" align="center">{children}</Group>
        )}
      </td>
    </tr>
  );
}
