import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { SimpleGrid, SimpleGridBreakpoint } from '@mantine/core';
import CommonSectionForm from './CommonSectionForm';
import CustomerSectionForm from './CustomerSectionForm';
import SoldProductsSectionForm from './SoldProductsSectionForm';
import DeliverySectionForm from './DeliverySectionForm';
import PaymentsSectionForm from './PaymentsSectionForm';
import TaskTicketsSectionForm from './TaskTicketsSectionForm';
import FormWrapper from '../../_Shared/FormElements/FormWrapper';
import { useStores } from '../../../App';
import { ICreateSaleAnswer, ICreateSaleBody } from '../../../clientApi/SaleApi/saleApiTypes';

const defaultValues = (managerId: number | null): ICreateSaleBody => ({
  statusId: null,
  managerId,
  sourceId: null,
  commentAboutSale: null,
  customer: {
    customerId: null,
    phone: '+375',
    firstName: null,
    middleName: null,
    lastName: null,
    commentAboutCustomer: null,
  },
  soldProducts: [{
    productId: null,
    name: null,
    storageId: null,
    isOrdered: false,
    quantity: null,
    salePriceByn: null,
    isPostSale: false,
    primaryPrice: null,
  }],
  payments: [{
    paymentAmount: null,
    paymentMethodTypeId: null,
  }],
  delivery: {
    courierId: null,
    deliveryTypeId: null,
    priceForCustomer: null,
    primaryPrice: null,
    address: null,
    trackNumber: null,
    startDateTimeUtc: null,
    endDateTimeUtc: null,
    inputStartTime: null,
    inputEndTime: null,
    pickedDate: null,
  },
  taskTickets: [],
});

function SaleCreateForm() {
  const navigate = useNavigate();
  const breakpoints: SimpleGridBreakpoint[] = [
    { maxWidth: 900, cols: 1, spacing: 'md' },
  ];

  const [isFormLoading, setIsFormLoading] = useState(false);

  const {
    clientApi: { saleApi },
    profileStore: { profileData },
  } = useStores();

  function actionAfterSuccess(response?: ICreateSaleAnswer) {
    const responseId = response ? response.createdItemId : null;
    navigate(responseId ? `/sales/${responseId}` : '/sales');
  }

  async function fetchData(data: ICreateSaleBody) {
    setIsFormLoading(true);
    if (data.delivery.pickedDate !== null) {
      const year = data.delivery.pickedDate.getFullYear();
      const month = data.delivery.pickedDate.getMonth();
      const day = data.delivery.pickedDate.getDate();
      const startTime = data.delivery.inputStartTime;
      const endTime = data.delivery.inputEndTime;
      data.delivery.startDateTimeUtc = new Date(year, month, day, startTime ?? 0);
      data.delivery.endDateTimeUtc = new Date(year, month, day, endTime ?? 23);
    } else {
      data.delivery.startDateTimeUtc = null;
      data.delivery.endDateTimeUtc = null;
    }
    const response = await saleApi.postSale(data);
    setIsFormLoading(false);
    return response;
  }

  return (
    <FormWrapper<ICreateSaleBody, ICreateSaleAnswer>
      isFormLoading={isFormLoading}
      withResetBtn
      actionAfterSuccess={actionAfterSuccess}
      fetchData={fetchData}
      defaultValues={defaultValues(profileData.userId)}
    >
      <SimpleGrid cols={2} breakpoints={breakpoints}>
        <CommonSectionForm />
        <CustomerSectionForm />
      </SimpleGrid>

      <SoldProductsSectionForm />

      <SimpleGrid cols={2} breakpoints={breakpoints}>
        <DeliverySectionForm />
        <PaymentsSectionForm />
        <TaskTicketsSectionForm />
      </SimpleGrid>
    </FormWrapper>
  );
}

export default observer(SaleCreateForm);
