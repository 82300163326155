import { createStyles, NavLink, ThemeIcon } from '@mantine/core';
import { IconType } from 'react-icons/lib';
import CustomNavLink from './CustomNavLink';

const useStyles = createStyles((theme) => ({
  nestedLink: {
    borderLeft: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

interface LinksGroupProps {
  icon: IconType;
  label: string;
  links: { label: string; link: string }[] | undefined;
  noGroupLink: string | undefined;
  onLinkClick?: () => void,
  isNavbarOpen?: boolean,
}

export default function LinksGroup({
  icon: Icon,
  label,
  links,
  noGroupLink,
  onLinkClick,
  isNavbarOpen,
}: LinksGroupProps) {
  const { classes } = useStyles();

  const hasLinks = Array.isArray(links);
  const items = (hasLinks ? links : []).map((link) => (
    <CustomNavLink
      to={link.link}
      label={link.label}
      key={link.label}
      className={classes.nestedLink}
      onClick={onLinkClick}
    />
  ));

  if (isNavbarOpen && hasLinks) {
    return (
      <NavLink
        label={label}
        icon={(
          <ThemeIcon variant="light">
            <Icon />
          </ThemeIcon>
        )}
        childrenOffset={28}
      >
        {items}
      </NavLink>
    );
  }
  if (!isNavbarOpen && hasLinks) {
    return (
      <CustomNavLink
        label={label}
        icon={Icon}
        to={links[0].link}
        onClick={onLinkClick}
      />
    );
  }
  return (
    <CustomNavLink
      label={label}
      icon={Icon}
      to={noGroupLink as string}
      onClick={onLinkClick}
    />
  );
}

LinksGroup.defaultProps = {
  onLinkClick: undefined,
  isNavbarOpen: true,
};
