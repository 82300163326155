import { FaBoxes, FaCashRegister, FaChartBar, FaCheckCircle, FaCog, FaDonate, FaExclamationTriangle, FaListAlt, FaShippingFast, FaShoppingBasket, FaStore, FaTasks, FaTimesCircle, FaUserFriends } from 'react-icons/fa';
import { IProfileData } from '../clientApi/ProfileApi/profileApiTypes';
import Analytics from '../components/Analytics/Analytics';
import Cashbox from '../components/Cashbox/Cashbox';
import Deliveries from '../components/Delivery/Deliveries/Deliveries';
import DeliveriesForCurrentUser from '../components/Delivery/DeliveriesForCurrentUser/DeliveriesForCurrentUser';
import DeliveryTypeLookup from '../components/Lookups/DefaultLookups/DeliveryTypeLookup';
import ProductStorageLookup from '../components/Lookups/DefaultLookups/ProductStorageLookup';
import ProductSupplierLookup from '../components/Lookups/DefaultLookups/ProductSupplierLookup';
import SaleSourceLookup from '../components/Lookups/DefaultLookups/SaleSourceLookup';
import SpendingCategoryLookup from '../components/Lookups/DefaultLookups/SpendingCategoryLookup';
import PaymentMethodsLookup from '../components/Lookups/PaymentMethodsLookup/PaymentMethodsLookup';
import Receipts from '../components/Receipts/Receipts';
import MySalary from '../components/Salary/MySalary';
import UserSalary from '../components/Salary/UserSalary';
import Salaries from '../components/Salary/Salaries';
import SaleAudit from '../components/Sales/SaleAudit';
import SaleCreatePage from '../components/Sales/SaleCreatePage';
import SaleEditPage from '../components/Sales/SaleEditPage';
import Sales from '../components/Sales/Sales';
import SaleViewPage from '../components/Sales/SaleViewPage';
import Storage from '../components/Storage/Storage';
import TaskTickets from '../components/TaskTickets/TaskTickets';
import Users from '../components/Users/Users';
import PasswordMsg from '../components/_Shared/NotificationComponents/PasswordMsg';
import { IAllPermissions, ICanBeNested } from './types';
import ErrorMsg from '../components/_Shared/NotificationComponents/ErrorMsg';
import beep from '../assets/correct.mp3';
import ToDoCountMsg from '../components/_Shared/NotificationComponents/ToDoCountMsg';
import SaleUpdateCommonPropertyErrorMsg from '../components/_Shared/NotificationComponents/SaleUpdateCommonPropertyErrorMsg';

export const defaultProfileData: IProfileData = {
  userId: null,
  email: '',
  fullName: '',
  roleKey: '',
  roleLabel: '',
  avatarBase64: null,
  permissions: [],
  userClientSettings: '',
};

export const simpleLookupList = {
  deliveryType: 'delivery-types',
  paymentMethod: 'payment-methods',
  productStorage: 'product-storages',
  productSupplier: 'product-suppliers',
  saleSource: 'sale-sources',
  spendingCategory: 'spending-category',
};

export const nestedLink: ICanBeNested = {
  Deliveries: ['AllDeliveries', 'MyDeliveries'],
  Salary: ['AllSalaries', 'MySalary'],
};

export const allPermissions: IAllPermissions = {
  Sales: {
    label: 'Продажи',
    icon: FaShoppingBasket,
    link: '/sales',
  },
  Receipts: {
    label: 'Приходы',
    icon: FaBoxes,
    link: '/receipts',
  },
  Storage: {
    label: 'Склад',
    icon: FaStore,
    link: '/stock',
  },
  Users: {
    label: 'Пользователи',
    icon: FaUserFriends,
    link: '/users',
  },
  TaskTickets: {
    label: 'Задачи',
    icon: FaListAlt,
    link: '/tasks',
  },
  Deliveries: {
    label: 'Доставки',
    icon: FaShippingFast,
    AllDeliveries: {
      label: 'Все доставки',
      link: '/deliveries',
    },
    MyDeliveries: {
      label: 'Мои доставки',
      link: '/deliveries/for-current-user',
    },
  },
  Cashier: {
    label: 'Касса',
    icon: FaCashRegister,
    link: '/cashbox',
  },
  Salary: {
    label: 'Зарплата',
    icon: FaDonate,
    AllSalaries: {
      label: 'Все зарплаты',
      link: '/salary',
    },
    MySalary: {
      label: 'Моя зарплата',
      link: '/salary/for-current-user',
    },
  },
  Lookups: {
    label: 'Настройки',
    icon: FaCog,
    links: [
      { label: 'Способы оплаты', link: '/payment-methods' },
      { label: 'Где лежит', link: '/product-storages' },
      { label: 'Поставщики', link: '/product-suppliers' },
      { label: 'Источники продаж', link: '/sale-sources' },
      { label: 'Категории расходов', link: '/spending-category' },
      { label: 'Способы доставки', link: '/delivery-types' },
    ],
  },
  Analytics: {
    label: 'Аналитика',
    icon: FaChartBar,
    link: '/analytics',
  },
};

export const permissionsRoutes = {
  Sales: [
    { id: 10, path: 'sales', element: <Sales /> },
    { id: 11, path: 'sales/:saleId', element: <SaleViewPage /> },
    { id: 12, path: 'sales/create', element: <SaleCreatePage /> },
    { id: 13, path: 'sales/:saleId/edit', element: <SaleEditPage /> },
    { id: 13, path: 'sales/:saleId/audit', element: <SaleAudit /> },
  ],
  Receipts: { id: 20, path: 'receipts', element: <Receipts /> },
  Storage: { id: 30, path: 'stock', element: <Storage /> },
  Users: { id: 40, path: 'users', element: <Users /> },
  TaskTickets: { id: 50, path: 'tasks', element: <TaskTickets /> },
  AllDeliveries: { id: 60, path: 'deliveries', element: <Deliveries /> },
  MyDeliveries: { id: 70, path: 'deliveries/for-current-user', element: <DeliveriesForCurrentUser /> },
  Cashier: { id: 80, path: 'cashbox', element: <Cashbox /> },
  AllSalaries: [
    { id: 90, path: 'salary', element: <Salaries /> },
    { id: 91, path: 'salary/:userId', element: <UserSalary /> },
  ],
  MySalary: { id: 100, path: 'salary/for-current-user', element: <MySalary /> },
  Analytics: { id: 110, path: 'analytics', element: <Analytics /> },
  Lookups: [
    { id: 120, path: 'payment-methods', element: <PaymentMethodsLookup /> },
    { id: 121, path: 'product-storages', element: <ProductStorageLookup /> },
    { id: 122, path: 'product-suppliers', element: <ProductSupplierLookup /> },
    { id: 123, path: 'sale-sources', element: <SaleSourceLookup /> },
    { id: 124, path: 'spending-category', element: <SpendingCategoryLookup /> },
    { id: 125, path: 'delivery-types', element: <DeliveryTypeLookup /> },
  ],
};

const todoCountRequestIntervalMin = 5;
export const todoCountRequestIntervalMs = todoCountRequestIntervalMin * 60 * 1000;
export const todoCountNotificationsAutoCloseIntervalMs = todoCountRequestIntervalMs + 250;

export function simpleSuccess(message: string) {
  return {
    title: 'OK',
    message,
    color: 'gray.0',
    icon: <FaCheckCircle color="green" size={28} />,
  };
}

function simpleError(message: string, data: string) {
  return {
    title: 'Ошибка',
    autoClose: false,
    color: 'gray.0',
    message: <ErrorMsg message={message} data={data} />,
    icon: <FaTimesCircle color="red" size={28} />,
  };
}

function warningError(message: string) {
  return {
    title: 'Ошибка',
    color: 'gray.0',
    message,
    icon: <FaExclamationTriangle color="orange" size={28} />,
  };
}

export const notificationsData = {
  simpleSaveSuccess: simpleSuccess('Успешно сохранено'),
  simpleDeleteSuccess: simpleSuccess('Успешно удалено'),
  simpleAddedSuccess: simpleSuccess('Успешно добавлено'),
  passwordMsg: (password: string, fullName: string) => ({
    color: 'gray.0',
    title: 'OK',
    autoClose: false,
    icon: <FaCheckCircle color="green" size={28} />,
    message: <PasswordMsg password={password} fullName={fullName} />,
  }),
  serverErrorMsg: (data: string) => simpleError('Ошибка сервера', data),
  rawErrorMsg: (data: string) => simpleError('Ошибка при загрузке данных', data),
  toDoCountMsg: (count: number) => ({
    title: 'Есть незавершённые задачи',
    color: 'gray.0',
    autoClose: todoCountNotificationsAutoCloseIntervalMs,
    icon: <FaTasks color="orange" size={28} />,
    message: <ToDoCountMsg count={count} />,
  }),
  validationErrorMsg: warningError('Пожалуйста, перепроверьте введённые данные'),
  permissionErrorMsg: warningError('Нет прав на выполнение запроса'),
  saleValidationErrorMsg: (saleId: number, data: string[]) => ({
    title: 'Ошибка',
    color: 'gray.0',
    message: <SaleUpdateCommonPropertyErrorMsg saleId={saleId} data={data} />,
    icon: <FaExclamationTriangle color="orange" size={28} />,
  }),
};

export const deliverySaleStatusLookup = [21, 22, 23];

export const audioElement = new Audio(beep);
