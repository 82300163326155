import { useFormContext } from 'react-hook-form';
import { Grid, Stack } from '@mantine/core';
import { ErrorMessage } from '@hookform/error-message';
import SectionTitle from '../../_Shared/SectionTitle';
import SaleStatusInput from '../../_Shared/FormElements/lookupInputs/SaleStatusInput';
import SaleSourceInput from '../../_Shared/FormElements/lookupInputs/SaleSourceInput';
import ManagerInput from '../../_Shared/FormElements/lookupInputs/ManagerInput';
import CommentAboutSaleInput from '../../_Shared/FormElements/textInputs/CommentAboutSaleInput';

export default function CommonSectionForm() {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Stack spacing="xs" sx={{ minWidth: '300px' }}>
      <SectionTitle>Общая информация</SectionTitle>
      <ErrorMessage errors={errors} name="" />

      <Grid gutter="xs">
        <Grid.Col sm={6}>
          <SaleStatusInput />
        </Grid.Col>
        <Grid.Col sm={6}>
          <SaleSourceInput />
        </Grid.Col>
      </Grid>

      <ManagerInput />
      <CommentAboutSaleInput required={false} />
    </Stack>
  );
}
