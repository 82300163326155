import { SelectItem } from '@mantine/core';
import { makeAutoObservable } from 'mobx';
import { ISaleStatusLookupItem, IUserRolesLookupItem } from '../../clientApi/LookupsApi/lookupsApiTypes';
import { deliverySaleStatusLookup } from '../const';
import RootStore from '../RootStore';

export default class LookupsStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  saleStatusLookup: ISaleStatusLookupItem[] = [];

  selectSaleStatusLookup: SelectItem[] = [];

  deliverySelectSaleStatusLookup: SelectItem[] = [];

  userRolesLookup: IUserRolesLookupItem[] = [];

  selectUserRolesLookup: SelectItem[] = [];

  getSaleStatusLookup = async () => {
    const data = await this.rootStore.clientApi.lookupsApi.getSaleStatusLookup();
    if (data[0] !== null) {
      this.saleStatusLookup = data[0].items;
      this.selectSaleStatusLookup = data[0]
        .items
        .map((item) => ({ value: item.id.toString(), label: item.label }));
      this.deliverySelectSaleStatusLookup = data[0]
        .items
        .filter((it) => deliverySaleStatusLookup.includes(it.id))
        .map((item) => ({ value: item.id.toString(), label: item.label }));
    }
  };

  getUserRolesLookup = async () => {
    const data = await this.rootStore.clientApi.lookupsApi.getUserRolesLookup();
    if (data[0] !== null) {
      this.userRolesLookup = data[0].items;
      this.selectUserRolesLookup = data[0]
        .items
        .map((item) => ({ value: item.key, label: item.label }));
    }
  };

  initAllLookups = () => {
    this.getSaleStatusLookup();
    this.getUserRolesLookup();
    this.rootStore.deliveryTypeLookupStore.getDeliveryTypeLookup();
    this.rootStore.paymentMethodLookupStore.getPaymentMethodLookup();
    this.rootStore.productStorageLookupStore.getProductStorageLookup();
    this.rootStore.productSupplierLookupStore.getProductSupplierLookup();
    this.rootStore.saleSourceLookupStore.getSaleSourceLookup();
    this.rootStore.spendingCategoryLookupStore.getSpendingCategoryLookup();
  };
}
