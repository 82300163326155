import { NavLink, Stack, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

interface ISaleUpdateCommonPropertyErrorMsgProps {
  saleId: number,
  data: string[],
}

export default function SaleUpdateCommonPropertyErrorMsg({
  data,
  saleId,
}: ISaleUpdateCommonPropertyErrorMsgProps) {
  return (
    <Stack spacing={0} align="flex-start">
      {data.map((it) => (
        <Text key={it}>{it}</Text>
      ))}
      <NavLink
        component={Link}
        to={`/sales/${saleId}`}
        active
        variant="subtle"
        label="На страницу продажи"
        p="0px 4px"
        styles={{
          root: {
            width: 'auto',
            display: 'inline-flex',
          },
        }}
      />
    </Stack>
  );
}
