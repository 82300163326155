/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { ActionIcon, Box, Group, Text, Tooltip } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { FaAngleRight, FaExternalLinkAlt, FaPen } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import TableSaleItems from './TableSaleItems';
import { getCustomerFullName } from '../../../utils/stringUtils';
import SaleStatusMultiSelect from '../../_Shared/Filters/SaleStatusMultiSelect';
import FilterInTableCell from '../../_Shared/Filters/FilterInTableCell';
import FilterByTextInput from '../../_Shared/Filters/FilterByTextInput';
import UsersMultiSelect from '../../_Shared/Filters/UsersMultiSelect';
import FilterByProduct from '../../_Shared/Filters/FilterByProduct';
import { DateAndTime } from '../../_Shared/DateViewComponents/DateAndTime';
import DeliveryTypeMultiSelect from '../../_Shared/Filters/DeliveryTypeMultiSelect';
import FilterByRangeDate from '../../_Shared/Filters/FilterByRangeDate';
import { ISaleShortViewModel } from '../../../clientApi/SaleApi/saleApiTypes';
import { useStores } from '../../../App';
import PhoneLink from '../../_Shared/PhoneLink';
import UpdateSaleStatus from './SaleActions/UpdateSaleStatus';
import UpdateSaleDeliveryType from './SaleActions/UpdateSaleDeliveryType';
import UpdateSaleDeliveryDate from './SaleActions/UpdateSaleDeliveryDate';
import UpdateSaleDeliveryTrackNumber from './SaleActions/UpdateSaleDeliveryTrackNumber';

interface ITableSalesProps {
  items: ISaleShortViewModel[],
  loading: boolean,
  totalItemsCount: number,
  PageSize: number,
  PageNumber: number,
  setPageNumber: React.Dispatch<React.SetStateAction<number>>,
  setStatusesFilter: React.Dispatch<React.SetStateAction<number[] | null>>,
  setPhoneFilter: React.Dispatch<React.SetStateAction<string | null>>,
  setFilterByManagerIds: React.Dispatch<React.SetStateAction<number[] | null>>,
  setFilterByDeliveryDate: React.Dispatch<React.SetStateAction<(string | null)[]>>,
  setFilterByDeliveryTypeIds: React.Dispatch<React.SetStateAction<number[] | null>>,
  setFilterByCourierIds: React.Dispatch<React.SetStateAction<number[] | null>>,
  setProductIdFilter: React.Dispatch<React.SetStateAction<number | null>>,
  FilterByManagerIds: number[] | null,
  statusesFilter: number[] | null,
  FilterByDeliveryDate: (string | null)[],
  FilterByDeliveryTypeIds: number[] | null,
  FilterByCourierIds: number[] | null,
  phoneFilter: string | null,
  setProductFilterLabel: React.Dispatch<React.SetStateAction<string>>,
  productFilterLabel: string,
  expandedRecordIds: number[],
  setExpandedRecordIds: React.Dispatch<React.SetStateAction<number[]>>,
}

export default function TableSales({
  items,
  loading,
  totalItemsCount,
  PageSize,
  PageNumber,
  setPageNumber,
  setStatusesFilter,
  setPhoneFilter,
  setFilterByManagerIds,
  setFilterByDeliveryDate,
  setFilterByDeliveryTypeIds,
  setFilterByCourierIds,
  setProductIdFilter,
  FilterByManagerIds,
  statusesFilter,
  FilterByDeliveryDate,
  FilterByDeliveryTypeIds,
  FilterByCourierIds,
  phoneFilter,
  setProductFilterLabel,
  productFilterLabel,
  expandedRecordIds,
  setExpandedRecordIds,
}: ITableSalesProps) {
  const { profileStore: { profilePermissions: { Sales: salesPermission } } } = useStores();

  return (
    <Box sx={{ overflow: 'hidden', height: '100%' }}>
      <DataTable<ISaleShortViewModel>
        // @ts-ignore
        customRowAttributes={(it) => ({
          onDoubleClick: (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            window.open(`${window.location.href}/${it.id}/edit`);
          },
        })}
        verticalSpacing="xs"
        horizontalSpacing={4}
        styles={{
          header: {
            verticalAlign: 'bottom',
            zIndex: 3,
          },
        }}
        noRecordsText="По данному запросу записей не найдено"
        highlightOnHover
        fetching={loading}
        records={items}
        totalRecords={totalItemsCount}
        recordsPerPage={PageSize}
        page={PageNumber}
        onPageChange={(p) => setPageNumber(p)}
        columns={[
          {
            accessor: '',
            width: '30px',
            render: () => <FaAngleRight />,
          },
          {
            accessor: 'id',
            title: 'ID',
          },
          {
            accessor: 'createdDateUtc',
            width: '120px',
            title: 'Дата создания',
            render: (i) => <DateAndTime date={i.createdDateUtc} />,
          },
          {
            accessor: 'customer',
            width: '160px',
            title: (
              <FilterInTableCell title="Покупатель">
                <FilterByTextInput
                  placeholder="Поиск по номеру телефона"
                  setFilterValue={setPhoneFilter}
                  value={phoneFilter}
                />
              </FilterInTableCell>
            ),
            render: ({ customer }) => (
              <>
                <Text lineClamp={1}>{getCustomerFullName(customer)}</Text>
                <PhoneLink phone={customer.phone} />
              </>
            ),
          },
          {
            accessor: 'manager.fullName',
            width: '160px',
            title: (
              <FilterInTableCell title="Менеджер">
                <UsersMultiSelect
                  placeholder="Менеджер"
                  setFilterValue={setFilterByManagerIds}
                  value={FilterByManagerIds}
                />
              </FilterInTableCell>
            ),
          },
          {
            accessor: 'status.label',
            width: '160px',
            title: (
              <FilterInTableCell title="Статус">
                <SaleStatusMultiSelect
                  placeholder="Статус продажи"
                  setFilterValue={setStatusesFilter}
                  value={statusesFilter}
                />
              </FilterInTableCell>
            ),
            render: ({ id, status }) => (
              <UpdateSaleStatus
                saleId={id}
                currentValue={status.id}
              />
            ),
          },
          {
            accessor: 'delivery.deliveryDate',
            width: '120px',
            title: (
              <FilterInTableCell title="Дата доставки">
                <FilterByRangeDate
                  placeholder="Дата доставки"
                  setFilterValue={setFilterByDeliveryDate}
                  value={FilterByDeliveryDate}
                />
              </FilterInTableCell>
            ),
            render: ({ id, delivery: { deliveryDate } }) => (
              <UpdateSaleDeliveryDate
                currentValue={deliveryDate}
                saleId={id}
              />
            ),
          },
          {
            accessor: 'delivery.deliveryType.label',
            width: '160px',
            title: (
              <FilterInTableCell title="Способ доставки">
                <DeliveryTypeMultiSelect
                  placeholder="Способ доставки"
                  setFilterValue={setFilterByDeliveryTypeIds}
                  value={FilterByDeliveryTypeIds}
                />
              </FilterInTableCell>
            ),
            render: ({ id, delivery }) => (
              <UpdateSaleDeliveryType
                saleId={id}
                currentValue={delivery.deliveryType.id}
              />
            ),
          },
          {
            accessor: 'delivery.courier.fullName',
            width: '160px',
            title: (
              <FilterInTableCell title="Курьер">
                <UsersMultiSelect
                  placeholder="Курьер"
                  setFilterValue={setFilterByCourierIds}
                  value={FilterByCourierIds}
                />
              </FilterInTableCell>
            ),
          },
          {
            accessor: 'delivery.trackNumber',
            title: 'Трек номер',
            width: '160px',
            render: ({ id, delivery: { trackNumber } }) => (
              <UpdateSaleDeliveryTrackNumber
                saleId={id}
                currentValue={trackNumber}
              />
            ),
          },
          {
            accessor: 'comment',
            width: '220px',
            title: (
              <FilterInTableCell title="Комментарий">
                <FilterByProduct
                  placeholder="Поиск по названию товара"
                  setFilterValue={setProductIdFilter}
                  setFilterLabel={setProductFilterLabel}
                  filterLabel={productFilterLabel}
                />
              </FilterInTableCell>
            ),
            render: ({ comment }) => (
              <Tooltip withArrow label={comment}>
                <Text size="sm" lineClamp={2}>{comment}</Text>
              </Tooltip>
            ),
          },
          {
            accessor: 'about',
            title: '',
            render: ({ id }) => (
              <Tooltip.Group openDelay={500} closeDelay={100}>
                <Group spacing={4} noWrap>
                  <Tooltip withArrow label="Подробнее">
                    <Link to={`/sales/${id}`}>
                      <ActionIcon color="blue" size="xl">
                        <FaExternalLinkAlt size={20} />
                      </ActionIcon>
                    </Link>
                  </Tooltip>
                  {salesPermission === 'read-and-edit' && (
                    <Tooltip withArrow label="Редактировать">
                      <Link to={`/sales/${id}/edit`}>
                        <ActionIcon color="blue" size="xl">
                          <FaPen size={20} />
                        </ActionIcon>
                      </Link>
                    </Tooltip>
                  )}
                </Group>
              </Tooltip.Group>
            ),
          },
        ]}
        rowExpansion={{
          allowMultiple: true,
          expanded: {
            recordIds: expandedRecordIds,
            onRecordIdsChange: setExpandedRecordIds,
          },
          content: TableSaleItems,
        }}
      />
    </Box>
  );
}
