import { addressUsers, getAddressBlockUser, getAddressChangeRoleUser, getAddressResetPassUser, getAddressUnblockUser, getAddressUpdateUserSalaryRates } from '../../const/const';
import HttpClient from '../HttpClient';
import { ICreateUserAnswer, ICreateUserBody, ICreateUserBodySalaryRates, INewRoleBody, IUserPassword, IUserResponse } from './usersApiTypes';

export default class UsersApi {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getUsers() {
    return this.httpClient.Get<IUserResponse>(addressUsers);
  }

  async postUser(body: ICreateUserBody) {
    return this
      .httpClient
      .Post<ICreateUserAnswer, ICreateUserBody>(
        addressUsers,
        body,
      );
  }

  async postBlockUser(id: number) {
    return this
      .httpClient
      .Post<null, undefined>(
        getAddressBlockUser(id),
        undefined,
        false,
      );
  }

  async postUnblockUser(id: number) {
    return this
      .httpClient
      .Post<null, undefined>(
        getAddressUnblockUser(id),
        undefined,
        false,
      );
  }

  async putUserRole(id: number, body: INewRoleBody) {
    return this
      .httpClient
      .Put<null, INewRoleBody>(
        getAddressChangeRoleUser(id),
        body,
      );
  }

  async putUserPassword(id: number) {
    return this
      .httpClient
      .Put<IUserPassword, undefined>(getAddressResetPassUser(id), undefined, false);
  }

  async putUserSalaryRates(id: number, body: ICreateUserBodySalaryRates) {
    return this
      .httpClient
      .Put<null, ICreateUserBodySalaryRates>(
        getAddressUpdateUserSalaryRates(id),
        body,
      );
  }
}
