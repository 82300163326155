import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Box, Button, Container, Grid, Group, Text } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { FaArrowCircleLeft } from 'react-icons/fa';
import PageTitle from '../_Shared/PageTitle';
import UpdateContentBtn from '../_Shared/UpdateContentBtn';
import { getDateStringForQueryParams } from '../../const/utils';
import { formatSaleId, NullValueLabel } from '../../utils/stringUtils';
import { useStores } from '../../App';
import { DateAndTime } from '../_Shared/DateViewComponents/DateAndTime';
import { ISaleProfitViewModel } from '../../clientApi/SalariesApi/salariesApiTypes';
import FilterByDate from '../_Shared/Filters/FilterByDate';

const year = new Date().getFullYear();
const month = new Date().getMonth();
const date1 = getDateStringForQueryParams(new Date(year, month, 1));
const date2 = getDateStringForQueryParams(new Date(year, month + 1, 0));

function UserSalary() {
  const { userId } = useParams() as unknown as { userId: string };
  const { usersStore, clientApi: { salariesApi } } = useStores();

  const [startDate, setStartDate] = useState<string | null>(date1);
  const [endDate, setEndDate] = useState<string | null>(date2);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState<ISaleProfitViewModel[]>([]);
  const [salary, setSalary] = useState(0);

  const userFullName = usersStore.selectUsersData.find((it) => it.value === userId)?.label;

  async function fetchItems() {
    setIsLoading(true);
    const [response] = await salariesApi.getSalariesByUserId(+userId, {
      EndDate: endDate,
      StartDate: startDate,
    });
    if (response !== null) {
      setItems(response.saleProfitItems);
      setSalary(response.salarySum);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchItems();
  }, [startDate, endDate]);

  return (
    <div>
      <Grid align="flex-end" pb="sm">
        <Grid.Col sm="auto">
          <Link to="/salary" relative="route" style={{ textDecoration: 'none', float: 'right' }}>
            <Button variant="subtle" leftIcon={<FaArrowCircleLeft />}>
              К зарплатам
            </Button>
          </Link>
        </Grid.Col>
        <Grid.Col sm="content">
          <PageTitle paddingBottom={0} text="Зарплата" />
        </Grid.Col>
        <Grid.Col sm="auto" />
      </Grid>

      <Container>
        <Text weight="bold" align="center" color="dimmed">
          {`Пользователь: ${userFullName ?? NullValueLabel}`}
        </Text>
      </Container>
      <Container>
        <Grid>
          <Grid.Col span="auto">
            <FilterByDate
              placeholder="Выберите дату"
              label="Начало периода"
              setFilterValue={setStartDate}
              value={startDate}
              clearable={false}
            />
          </Grid.Col>
          <Grid.Col span="auto">
            <FilterByDate
              placeholder="Выберите дату"
              label="Конец периода"
              setFilterValue={setEndDate}
              value={endDate}
              clearable={false}
            />
          </Grid.Col>
        </Grid>
      </Container>

      <Container>
        <Text weight="bold" align="center" p="md" color="dimmed">
          {`Сумма за период: ${salary}р`}
        </Text>
      </Container>
      <Group position="right">
        <UpdateContentBtn
          action={fetchItems}
          isDisabled={isLoading}
        />
      </Group>
      <Box sx={{ overflow: 'hidden', height: '100%' }}>
        <DataTable<ISaleProfitViewModel>
          noRecordsText="По данному запросу записей не найдено"
          highlightOnHover
          fetching={isLoading}
          records={items}
          idAccessor="saleId"
          columns={[
            {
              accessor: 'dateUtc',
              title: 'Дата завершения продажи',
              render: (it) => <DateAndTime date={it.dateUtc} isOneLine />,
            },
            { accessor: 'profit', title: 'Заработок' },
            {
              accessor: 'saleId',
              title: 'ID продажи',
              render: (it) => (
                <Link to={`/sales/${it.saleId}`} relative="route">{formatSaleId(it.saleId)}</Link>
              ),
            },
          ]}
        />
      </Box>
    </div>
  );
}

export default observer(UserSalary);
