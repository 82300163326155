import { addressLogin, addressLogout } from '../../const/const';
import HttpClient from '../HttpClient';
import { IPostLoginBody } from './loginApiTypes';

export default class LoginApi {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async postLogin(body: IPostLoginBody) {
    const data = await this
      .httpClient
      .Post<null, IPostLoginBody>(addressLogin, body, false);
    return data;
  }

  async postLogout() {
    const data = await this
      .httpClient
      .Post<null, undefined>(addressLogout, undefined, false);
    return data;
  }
}
