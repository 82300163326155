import { Group, Tooltip } from '@mantine/core';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStores } from '../../../../App';
import { IUser } from '../../types';
import BlockUser from './BlockUser';
import ChangeUserRole from './CnangeUserRole';
import ResetUserPassword from './ResetUserPassword';
import UnblockUser from './UnblockUser';

interface IUserActionsProps {
  user: IUser,
}

function UserActions({
  user,
}: IUserActionsProps) {
  const { profileStore: { profileData } } = useStores();

  const [block, setBlock] = useState(user.isBlocked);
  const [currentRole, setCurrentRole] = useState(user.roleLabel);

  return (
    <Tooltip.Group openDelay={500} closeDelay={100}>
      <Group spacing={4} position="right" noWrap>
        <ChangeUserRole
          isDisable={block}
          id={user.id}
          fullName={user.fullName}
          currentRole={currentRole}
          setCurrentRole={setCurrentRole}
        />
        <ResetUserPassword
          isDisable={block}
          id={user.id}
          fullName={user.fullName}
        />
        {profileData.userId !== user.id && block && (
          <UnblockUser
            setBlock={setBlock}
            id={user.id}
            fullName={user.fullName}
          />
        )}
        {profileData.userId !== user.id && !block && (
          <BlockUser
            setBlock={setBlock}
            id={user.id}
            fullName={user.fullName}
          />
        )}
      </Group>
    </Tooltip.Group>
  );
}

export default observer(UserActions);
