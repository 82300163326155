import { Button, Container, createStyles, Group, Text, Title } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 540,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
  },
}));

export default function ErrorPage() {
  const { classes } = useStyles();

  return (
    <Container>
      <Title className={classes.title}>Ошибка...</Title>
      <Text color="dimmed" size="lg" align="center" className={classes.description}>
        При загрузке данных произошла ошибка.
        Проверьте Ваше подключение к сети и попробуйте перезагрузить страницу.
      </Text>
      <Group position="center">
        <Button variant="white" size="md" onClick={() => document.location.reload()}>
          Перезагрузить страницу
        </Button>
      </Group>
    </Container>
  );
}
