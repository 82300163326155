import { Container, NumberInput } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { IUpdateExchangeRateReceiptBody } from '../../clientApi/ReceiptsApi/receiptsApiTypes';
import { actionOnFocusNumberInput } from '../../const/utils';

function UpdateExchangeRateFormContent() {
  const {
    control,
    formState: { errors },
  } = useFormContext<IUpdateExchangeRateReceiptBody>();

  return (
    <Container>
      <Controller
        name="exchangeRate"
        control={control}
        rules={{ required: 'Это поле должно быть заполнено' }}
        render={({ field: { value, onChange } }) => (
          <NumberInput
            value={value === null ? undefined : value}
            onChange={(val) => onChange(val === undefined ? null : val)}
            decimalSeparator=","
            step={0.1}
            precision={5}
            label="Курс валют"
            withAsterisk
            error={errors.exchangeRate?.message}
            onFocus={actionOnFocusNumberInput}
          />
        )}
      />
    </Container>
  );
}

export default observer(UpdateExchangeRateFormContent);
