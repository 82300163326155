import { Text } from '@mantine/core';

interface IEmptyStateTitleProps {
  title?: string,
  pt?: number,
}

export default function EmptyStateTitle({
  title,
  pt,
}: IEmptyStateTitleProps) {
  return (
    <Text
      pt={pt}
      align="center"
      italic
      color="gray.5"
    >
      {title}
    </Text>
  );
}

EmptyStateTitle.defaultProps = {
  title: 'Записей нет',
  pt: 0,
};
