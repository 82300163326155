import { addressPaymentMethodLookup, addressSaleStatusLookup, addressSimpleLookup, addressSimpleLookupWithId, addressUserRoles } from '../../const/const';
import HttpClient from '../HttpClient';
import { IPaymentMethodsLookup, ISaleStatusLookup, ISendPaymentLookupBody, ISendSimpleLookupBody, ISimpleLookup, IUserRolesLookup } from './lookupsApiTypes';

export default class LookupsApi {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getSaleStatusLookup() {
    const data = await this.httpClient.Get<ISaleStatusLookup>(addressSaleStatusLookup);
    return data;
  }

  async getUserRolesLookup() {
    const data = await this.httpClient.Get<IUserRolesLookup>(addressUserRoles);
    return data;
  }

  async getPaymentMethodsLookup() {
    const data = await this.httpClient.Get<IPaymentMethodsLookup>(addressPaymentMethodLookup);
    return data;
  }

  async postPaymentMethodsLookup(path: string, body: ISendPaymentLookupBody) {
    const data = await this
      .httpClient
      .Post<null, ISendPaymentLookupBody>(
        addressSimpleLookup(path),
        body,
      );
    return data;
  }

  async putPaymentMethodsLookup(path: string, body: ISendPaymentLookupBody, id: number) {
    const data = await this
      .httpClient
      .Put<null,
        ISendPaymentLookupBody
      >(addressSimpleLookupWithId(path, id), body);
    return data;
  }

  async getSimpleLookup(path: string) {
    const data = await this.httpClient.Get<ISimpleLookup>(addressSimpleLookup(path));
    return data;
  }

  async postSimpleLookup(path: string, body: ISendSimpleLookupBody) {
    const data = await this
      .httpClient
      .Post<null, ISendSimpleLookupBody>(
        addressSimpleLookup(path),
        body,
      );
    return data;
  }

  async putSimpleLookup(path: string, body: ISendSimpleLookupBody, id: number) {
    const data = await this
      .httpClient
      .Put<null,
        ISendSimpleLookupBody
      >(addressSimpleLookupWithId(path, id), body);
    return data;
  }

  async deleteSimpleLookup(path: string, id: number) {
    const data = await this
      .httpClient
      .Delete(addressSimpleLookupWithId(path, id));
    return data;
  }
}
