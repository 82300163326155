import { Tooltip, Box } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { observer } from 'mobx-react';
import { useStores } from '../../../App';
import { ICashboxItem } from '../../../clientApi/CashboxApi/cashboxApiTypes';
import { CurrencyTypes } from '../../../const/const';
import { DateAndTime } from '../../_Shared/DateViewComponents/DateAndTime';
import UpdateCashboxItemBtn from './UpdateCashboxItemBtn';

interface ITableCashboxProps {
  items: ICashboxItem[],
  loading: boolean,
  totalItems: number,
  setPageNumber: React.Dispatch<React.SetStateAction<number>>,
  pageNumber: number,
  fetchItems: () => void,
}

function TableCashbox({
  items,
  loading,
  totalItems,
  setPageNumber,
  pageNumber,
  fetchItems,
}: ITableCashboxProps) {
  const { profileStore: { profilePermissions: { Cashier: cashboxPermission } } } = useStores();

  function getCommentElement(comment: string) {
    return comment.length < 25
      ? comment
      : (
        <Tooltip multiline withArrow label={comment}>
          <p className="overflow-ellipsis-text">{comment}</p>
        </Tooltip>
      );
  }

  return (
    <Box sx={{ height: '100%', overflow: 'hidden' }}>
      <DataTable<ICashboxItem>
        minHeight={200}
        noRecordsText="По данному запросу записей не найдено"
        highlightOnHover
        fetching={loading}
        records={items}
        totalRecords={totalItems}
        recordsPerPage={10}
        page={pageNumber}
        onPageChange={setPageNumber}
        columns={[
          {
            accessor: 'id',
            title: 'ID',
          },
          {
            accessor: 'categoryName',
            title: 'Категория',
          },
          {
            accessor: 'amount',
            title: 'Сумма',
            render: (i) => `${i.amount} ${CurrencyTypes[i.currencyId]}`,
          },
          {
            accessor: 'paymentMethod.label',
            title: 'Метод оплаты',
          },
          {
            accessor: 'createdDateUtc',
            title: 'Дата создания',
            render: (i) => <DateAndTime date={i.createdDateUtc} isOneLine />,
          },
          {
            accessor: 'creatorUser',
            title: 'Создал',
            render: (i) => i.creatorUser.fullName,
          },
          {
            accessor: 'comment',
            title: 'Комментарий',
            width: 200,
            render: (i) => getCommentElement(i.comment),
          },
          {
            accessor: 'updateCashboxBtn',
            title: '',
            hidden: cashboxPermission !== 'read-and-edit',
            render: (item) => (
              <UpdateCashboxItemBtn
                id={item.id}
                item={item}
                fetchItems={fetchItems}
              />
            ),
          },
        ]}
      />
    </Box>
  );
}

export default observer(TableCashbox);
