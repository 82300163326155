import { createStyles, Group, Avatar, Text, Tooltip, Container, Center, Stack } from '@mantine/core';
import { observer } from 'mobx-react';
import { FaAt, FaSpellCheck, FaUserCog } from 'react-icons/fa';
import ChangeProfileImgBtn from './ProfileActions/ChangeImg/ChangeProfileImgBtn';
import ChangeProfilePassBtn from './ProfileActions/ChangePass/ChangeProfilePassBtn';
import ChangeProfileNameBtn from './ProfileActions/ChangeName/ChangeProfileNameBtn';
import PageTitle from '../_Shared/PageTitle';
import { useStores } from '../../App';

const useStyles = createStyles((theme) => ({
  icon: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[5],
  },

  name: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

function Profile() {
  const { classes } = useStyles();
  const { profileStore: { profileData: {
    avatarBase64,
    roleLabel,
    fullName,
    email,
  } } } = useStores();

  return (
    <>
      <PageTitle text="Профиль" />
      <Container>
        <Center>
          <Group py="xs" noWrap spacing={10} mt={3}>
            <Avatar src={avatarBase64} size={128} radius={128} />
            <div>
              <Tooltip.Group openDelay={500} closeDelay={100}>
                <Stack spacing="xs">
                  <ChangeProfileImgBtn />
                  <ChangeProfileNameBtn />
                  <ChangeProfilePassBtn />
                </Stack>
              </Tooltip.Group>
            </div>
          </Group>
        </Center>
        <Center>
          <div>
            <Group py="xs" noWrap spacing={10} mt={3}>
              <FaUserCog size={24} className={classes.icon} />
              <Text size="xl" weight="bold" color="dimmed" className={classes.name}>Роль:</Text>
              <Text size="xl" className={classes.name}>{roleLabel}</Text>
            </Group>
            <Group py="xs" noWrap spacing={10} mt={3}>
              <FaSpellCheck size={24} className={classes.icon} />
              <Text size="xl" weight="bold" color="dimmed" className={classes.name}>Имя:</Text>
              <Text size="xl" className={classes.name}>{fullName}</Text>
            </Group>
            <Group py="xs" noWrap spacing={10} mt={3}>
              <FaAt size={24} className={classes.icon} />
              <Text size="xl" weight="bold" color="dimmed" className={classes.name}>Email:</Text>
              <Text size="xl" className={classes.name}>{email}</Text>
            </Group>
          </div>
        </Center>
      </Container>
    </>
  );
}

export default observer(Profile);
