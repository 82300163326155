import { Box, Loader } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { showNotification } from '@mantine/notifications';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useStores } from '../../../../App';
import { styleDatePickerActualDay } from '../../../../const/utils';
import { notificationsData } from '../../../../store/const';
import { NullValueLabel } from '../../../../utils/stringUtils';

interface IUpdateSaleDeliveryDateProps {
  saleId: number,
  currentValue: string | null,
}

function UpdateSaleDeliveryDate({
  saleId,
  currentValue,
}: IUpdateSaleDeliveryDateProps) {
  const {
    clientApi: { saleApi },
  } = useStores();

  const [isLoading, setIsLoading] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);

  async function onChange(value: Date | null) {
    setIsLoading(true);
    const [, errorData] = await saleApi.putSaleUpdateCommonProperties(
      {
        deliveryDate: value,
        deliveryDateSpecified: true,
      },
      saleId,
    );
    if (errorData === null) {
      setIsValidationError(false);
    }
    if (errorData) {
      setIsValidationError(true);
      showNotification(notificationsData.saleValidationErrorMsg(
        saleId,
        errorData.map((it) => it[1]),
      ));
    }
    setIsLoading(false);
  }

  return (
    <Box
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
      }}
      onDoubleClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
    >
      <DatePicker
        clearable
        placeholder={NullValueLabel}
        defaultValue={currentValue === null ? null : new Date(currentValue)}
        icon={isLoading ? <Loader size="xs" /> : null}
        onChange={onChange}
        dayStyle={styleDatePickerActualDay}
        error={isValidationError}
        styles={{
          input: {
            border: 'none',
          },
          cell: {
            padding: '0 !important',
          },
          dropdown: {
            padding: '6px 8px',
          },
          calendarHeader: {
            marginBottom: '4px',
          },
          weekdayCell: {
            padding: '4px 4px !important',
          },
          day: {
            width: '29px',
            height: '29px',
            lineHeight: '29px',
            fontSize: '13px',
          },
          disabled: {
            ':disabled': {
              cursor: 'pointer',
              background: 'white',
              border: isValidationError ? '1px red solid' : 'none',
              color: isValidationError ? 'red' : 'black',
              opacity: '1',
            },
          },
        }}
        inputFormat="DD.MM.YYYY"
      />
    </Box>
  );
}

export default observer(UpdateSaleDeliveryDate);
