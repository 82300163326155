import { SelectItem } from '@mantine/core';
import { makeAutoObservable } from 'mobx';
import { ISendSimpleLookupBody, ISimpleLookupItem } from '../../clientApi/LookupsApi/lookupsApiTypes';
import { simpleLookupList } from '../const';
import RootStore from '../RootStore';

export default class SpendingCategoryLookupStore {
  rootStore: RootStore;

  path: string;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.path = simpleLookupList.spendingCategory;
  }

  isLoading = false;

  isFormLoading = false;

  items: ISimpleLookupItem[] = [];

  selectItems: SelectItem[] = [];

  getSpendingCategoryLookup = async () => {
    this.isLoading = true;
    const data = await this
      .rootStore
      .clientApi
      .lookupsApi
      .getSimpleLookup(this.path);
    if (data[0] !== null) {
      this.items = data[0].items;
      this.selectItems = data[0]
        .items
        .map((item) => ({ value: item.id.toString(), label: item.label }));
    }
    this.isLoading = false;
  };

  postSpendingCategoryLookup = async (body: ISendSimpleLookupBody) => {
    this.isFormLoading = true;
    const data = await this
      .rootStore
      .clientApi
      .lookupsApi
      .postSimpleLookup(this.path, body);
    this.isFormLoading = false;
    return data[1];
  };

  putSpendingCategoryLookup = async (body: ISendSimpleLookupBody, id: number) => {
    this.isFormLoading = true;
    const data = await this
      .rootStore
      .clientApi
      .lookupsApi
      .putSimpleLookup(this.path, body, id);
    this.isFormLoading = false;
    return data[1];
  };

  deleteSpendingCategoryLookup = async (id: number) => {
    this.isFormLoading = true;
    await this
      .rootStore
      .clientApi
      .lookupsApi
      .deleteSimpleLookup(this.path, id);
    this.isFormLoading = false;
  };
}
