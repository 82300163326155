import { Button, Group, NumberInput, ScrollArea, Select, Textarea } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Controller, FormProvider, Path, SubmitHandler, useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import AddReceiptItemForm from './UpdateReceiptItemForm';
import { actionOnFocusNumberInput, styleDatePickerActualDay } from '../../../const/utils';
import { IReceipt, ISendReceiptBody } from '../../../clientApi/ReceiptsApi/receiptsApiTypes';
import { useStores } from '../../../App';
import { requiredMsg } from '../../../const/const';
import { IExcelFileDataItem } from '../AddReceipts/XlsxInput';
import InputClearCross from '../../_Shared/InputClearCross';

interface IUpdateReceiptsFormProps {
  setOpened: React.Dispatch<React.SetStateAction<boolean>>,
  setIsConfirmOpen: React.Dispatch<React.SetStateAction<boolean>>,
  fetchItems: () => void,
  excelFileData: IExcelFileDataItem[],
  setExcelFileData: React.Dispatch<React.SetStateAction<IExcelFileDataItem[]>>,
  setIsBatchSearchLoading: React.Dispatch<React.SetStateAction<boolean>>,
  item: IReceipt,
  id: number,
}

function UpdateReceiptsForm({
  setOpened,
  setIsConfirmOpen,
  fetchItems,
  excelFileData,
  setExcelFileData,
  setIsBatchSearchLoading,
  item,
  id,
}: IUpdateReceiptsFormProps) {
  const {
    clientApi: { receiptsApi },
    productSupplierLookupStore: { selectItems },
  } = useStores();

  const defaultValues: ISendReceiptBody = {
    supplierId: item.supplierId,
    comment: item.comment,
    exchangeRate: item.exchangeRate,
    receiptDate: new Date(item.receiptDate),
    receiptItems: item.receiptItems.map((it) => ({
      productId: it.productId,
      quantity: it.quantity,
      primaryCost: it.specifiedPrimaryCost,
      primaryCostCurrencyId: it.specifiedPrimaryCostCurrencyId,
      name: it.productName,
    })),
  };

  const {
    handleSubmit,
    control,
    reset,
    formState,
    formState: { errors },
    setError,
    ...other
  } = useForm<ISendReceiptBody>({ defaultValues });

  const [isFormLoading, setIsFormLoading] = useState(false);

  const onSubmit: SubmitHandler<ISendReceiptBody> = async (data) => {
    setIsFormLoading(true);
    const [, errorData] = await receiptsApi.putReceipt(data, id);
    if (Array.isArray(errorData)) {
      setIsConfirmOpen(false);
      errorData.forEach((it) => setError(it[0] as Path<ISendReceiptBody>, { message: it[1] }));
    } else if (errorData === null) {
      setExcelFileData([]);
      setIsConfirmOpen(false);
      setOpened(false);
      reset(defaultValues);
      fetchItems();
    }
    setIsFormLoading(false);
  };

  return (
    <FormProvider {...{ handleSubmit, control, reset, formState, setError, ...other }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', width: '-webkit-fill-available' }}
      >
        <ScrollArea
          offsetScrollbars
          styles={{
            thumb: {
              '::before': {
                minWidth: 'auto',
              },
            },
            root: {
              minHeight: '400px',
            },
          }}
        >
          <Controller
            name="receiptDate"
            control={control}
            rules={{ required: requiredMsg }}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                value={value === undefined ? null : value}
                onChange={(val) => onChange(val === null ? undefined : val)}
                label="Дата поставки"
                withAsterisk
                placeholder="Выберите дату"
                dayStyle={styleDatePickerActualDay}
                error={errors.receiptDate?.message}
              />
            )}
          />
          <Controller
            name="exchangeRate"
            control={control}
            rules={{ required: requiredMsg }}
            render={({ field: { value, onChange } }) => (
              <NumberInput
                value={value}
                onChange={(val) => onChange(val)}
                label="Курс валют"
                withAsterisk
                step={0.1}
                min={0}
                precision={5}
                error={errors.exchangeRate?.message}
                onFocus={actionOnFocusNumberInput}
              />
            )}
          />
          <Controller
            name="supplierId"
            control={control}
            rules={{ required: requiredMsg }}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value ? value.toString() : null}
                onChange={(val) => onChange(val !== null ? +val : undefined)}
                label="Поставщик"
                withAsterisk
                data={selectItems}
                error={errors.supplierId?.message}
                clearable
              />
            )}
          />
          <Controller
            name="comment"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Textarea
                value={value || ''}
                onChange={({ currentTarget: { value: val } }) => onChange(val)}
                label="Комментарий"
                autosize
                minRows={2}
                maxRows={4}
                error={errors.comment?.message}
                rightSection={<InputClearCross action={() => onChange('')} />}
              />
            )}
          />
          <AddReceiptItemForm
            excelFileData={excelFileData}
            setIsBatchSearchLoading={setIsBatchSearchLoading}
          />
        </ScrollArea>
        <Group position="apart">
          <Button variant="subtle" onClick={() => reset(defaultValues)}>
            Сбросить
          </Button>
          <Group>
            <Button
              variant="subtle"
              onClick={() => {
                setExcelFileData([]);
                setOpened(false);
              }}
            >
              Отмена
            </Button>
            <Button loading={isFormLoading} type="submit" leftIcon={<FaSave />}>Сохранить</Button>
          </Group>
        </Group>
      </form>
    </FormProvider>
  );
}

export default observer(UpdateReceiptsForm);
