/* eslint-disable no-unused-vars */
import { Button, Container, Group, Space } from '@mantine/core';
import { ReactNode } from 'react';
import { DeepPartial, FieldValues, FormProvider, Path, SubmitHandler, UnPackAsyncDefaultValues, useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { TErrors } from '../../../clientApi/apiTypes';

interface IFormWrapperProps<IFetchData, IResponse> {
  withResetBtn?: boolean,
  isResetAfterClose?: boolean,
  children: ReactNode,
  actionAfterSuccess: (response?: IResponse) => void,
  isFormLoading: boolean,
  fetchData: (data: IFetchData) => Promise<[null | IResponse, null | TErrors | false]>,
  defaultValues?: DeepPartial<IFetchData>,
  onCancelAction?: () => void,
  submitBtnTitle?: string,
  submitBtnIcon?: ReactNode,
}

export default function FormWrapper<IForm, IResponse>({
  withResetBtn,
  isResetAfterClose,
  children,
  fetchData,
  actionAfterSuccess,
  onCancelAction,
  isFormLoading,
  defaultValues,
  submitBtnTitle,
  submitBtnIcon,
}: IFormWrapperProps<IForm, IResponse>) {
  const methods = useForm({ defaultValues });

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const response = await fetchData(data as IForm);
    if (Array.isArray(response[1])) {
      response[1].forEach((it) => methods
        .setError(it[0] as Path<UnPackAsyncDefaultValues<IForm>>, { message: it[1] }));
    } else if (response[1] === null) {
      actionAfterSuccess(response[0] || undefined);
    }
  };

  function onCancel() {
    if (isResetAfterClose) {
      methods.reset(defaultValues);
    }
    if (onCancelAction !== undefined) {
      onCancelAction();
    } else {
      actionAfterSuccess();
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
        <Space h="xl" />
        {withResetBtn ? (
          <Container size="xs" px="xs">
            <Group position="apart">
              <Button variant="subtle" onClick={() => methods.reset(defaultValues)}>
                Сбросить
              </Button>
              <Group>
                <Button variant="subtle" onClick={onCancel}>Отмена</Button>
                <Button loading={isFormLoading} type="submit" leftIcon={submitBtnIcon}>{submitBtnTitle}</Button>
              </Group>
            </Group>
          </Container>
        ) : (
          <Container size="xs" px="xs">
            <Group position="right">
              <Button variant="subtle" onClick={onCancel}>Отмена</Button>
              <Button loading={isFormLoading} type="submit" leftIcon={submitBtnIcon}>{submitBtnTitle}</Button>
            </Group>
          </Container>
        )}
      </form>
    </FormProvider>
  );
}

FormWrapper.defaultProps = {
  withResetBtn: false,
  isResetAfterClose: false,
  defaultValues: undefined,
  onCancelAction: undefined,
  submitBtnTitle: 'Сохранить',
  submitBtnIcon: <FaSave />,
};
