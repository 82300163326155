import { ActionIcon, Button, Container, Group, Modal, Space, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { FaExclamationTriangle, FaPen, FaQuestionCircle } from 'react-icons/fa';
import { IReceipt } from '../../../clientApi/ReceiptsApi/receiptsApiTypes';
import XlsxInput, { IExcelFileDataItem } from '../AddReceipts/XlsxInput';
import XlsxInputExampleTable from '../AddReceipts/XlsxInputExampleTable';
import UpdateReceiptsForm from './UpdateReceiptsForm';

interface IUpdateReceiptsBtnProps {
  fetchItems: () => void,
  id: number,
  item: IReceipt,
}

export default function UpdateReceiptsBtn({
  fetchItems,
  item,
  id,
}: IUpdateReceiptsBtnProps) {
  const [opened, setOpened] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isBatchSearchLoading, setIsBatchSearchLoading] = useState(false);

  const [excelFileData, setExcelFileData] = useState<IExcelFileDataItem[]>([]);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setIsConfirmOpen(true)}
        size="lg"
        styles={{
          modal: {
            maxHeight: '100%',
          },
          body: {
            maxHeight: 'calc(100vh - 185px)',
            display: 'flex',
          },
        }}
        title={(
          <Group spacing={8}>
            <Text align="center" weight="bold">
              Обновить приход
            </Text>
            <Tooltip
              label={<XlsxInputExampleTable />}
              withArrow
              openDelay={500}
              closeDelay={100}
              styles={{ tooltip: { background: 'white', boxShadow: '0 0 10px -3px gray' } }}
            >
              <ThemeIcon radius="xl" size="sm">
                <FaQuestionCircle />
              </ThemeIcon>
            </Tooltip>
            <XlsxInput
              setExcelFileData={setExcelFileData}
              isBatchSearchLoading={isBatchSearchLoading}
            />
          </Group>
        )}
      >
        <UpdateReceiptsForm
          setOpened={setOpened}
          fetchItems={fetchItems}
          excelFileData={excelFileData}
          setIsConfirmOpen={setIsConfirmOpen}
          setExcelFileData={setExcelFileData}
          setIsBatchSearchLoading={setIsBatchSearchLoading}
          item={item}
          id={id}
        />
      </Modal>
      <Modal
        opened={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        title={(
          <Text align="center" weight="bold">
            Подтверждение
          </Text>
        )}
      >
        <Container>
          <Group spacing={20} noWrap>
            <FaExclamationTriangle size={40} color="orange" />
            <Stack spacing={4}>
              <Text>При закрытии формы введённые данные не сохранятся!</Text>
              <Text>Вы уверены что хотите закрыть форму?</Text>
            </Stack>
          </Group>
        </Container>
        <Space h="xl" />
        <Container size="xs" px="xs">
          <Group position="right">
            <Button variant="subtle" onClick={() => setIsConfirmOpen(false)}>Отмена</Button>
            <Button
              onClick={() => {
                setExcelFileData([]);
                setIsConfirmOpen(false);
                setOpened(false);
              }}
            >
              Да
            </Button>
          </Group>
        </Container>
      </Modal>
      <Tooltip withArrow label="Изменить" openDelay={500} closeDelay={100}>
        <ActionIcon color="blue" onClick={() => setOpened(true)}>
          <FaPen size={20} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}
