import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Box, Container, Grid, Group, Text } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import PageTitle from '../_Shared/PageTitle';
import UpdateContentBtn from '../_Shared/UpdateContentBtn';
import { getDateStringForQueryParams } from '../../const/utils';
import { formatSaleId } from '../../utils/stringUtils';
import FilterByDate from '../_Shared/Filters/FilterByDate';
import { ISaleProfitViewModel } from '../../clientApi/SalariesApi/salariesApiTypes';
import { useStores } from '../../App';

const year = new Date().getFullYear();
const month = new Date().getMonth();
const date1 = getDateStringForQueryParams(new Date(year, month, 1));
const date2 = getDateStringForQueryParams(new Date(year, month + 1, 0));

function MySalary() {
  const { clientApi: { salariesApi } } = useStores();

  const [startDate, setStartDate] = useState<string | null>(date1);
  const [endDate, setEndDate] = useState<string | null>(date2);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState<ISaleProfitViewModel[]>([]);
  const [salary, setSalary] = useState(0);

  async function fetchItems() {
    setIsLoading(true);
    const [response] = await salariesApi.getSalariesForCurrentUser({
      EndDate: endDate,
      StartDate: startDate,
    });
    if (response !== null) {
      setItems(response.saleProfitItems);
      setSalary(response.salarySum);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchItems();
  }, [startDate, endDate]);

  return (
    <div>
      <PageTitle text="Зарплата" />
      <Container>
        <Grid>
          <Grid.Col span="auto">
            <FilterByDate
              placeholder="Выберите дату"
              label="Начало периода"
              setFilterValue={setStartDate}
              value={startDate}
              clearable={false}
            />
          </Grid.Col>
          <Grid.Col span="auto">
            <FilterByDate
              placeholder="Выберите дату"
              label="Конец периода"
              setFilterValue={setEndDate}
              value={endDate}
              clearable={false}
            />
          </Grid.Col>
        </Grid>
      </Container>

      <Container>
        <Text weight="bold" align="center" p="md" color="dimmed">
          {`Сумма за период: ${salary}р`}
        </Text>
      </Container>
      <Group position="right">
        <UpdateContentBtn
          action={fetchItems}
          isDisabled={isLoading}
        />
      </Group>
      <Box sx={{ overflow: 'hidden', height: '100%' }}>
        <DataTable<ISaleProfitViewModel>
          noRecordsText="По данному запросу записей не найдено"
          highlightOnHover
          fetching={isLoading}
          records={items}
          idAccessor="saleId"
          columns={[
            {
              accessor: 'dateUtc',
              title: 'Дата завершения продажи',
              render: (it) => (
                `${new Date(it.dateUtc).toLocaleDateString()} ${new Date(it.dateUtc).toLocaleTimeString().slice(0, -3)}`
              ),
            },
            { accessor: 'profit', title: 'Заработок' },
            {
              accessor: 'saleId',
              title: 'ID продажи',
              render: (it) => (
                <Link to={`/sales/${it.saleId}`} relative="route">{formatSaleId(it.saleId)}</Link>
              ),
            },
          ]}
        />
      </Box>
    </div>
  );
}

export default observer(MySalary);
