import HttpClient from '../HttpClient';
import { addressProfile, addressProfileAvatar, addressProfileFullName, addressProfilePassword, addressProfileSettings } from '../../const/const';
import { IProfileAvatar, IProfileData, IProfileFullName, IProfileNewPasswordBody, IUserClientSettings, IFetchUserClientSettings } from './profileApiTypes';

export default class ProfileApi {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getCurrentProfile() {
    const data = await this.httpClient.Get<IProfileData>(addressProfile);
    return data;
  }

  async putProfilePassword(body: IProfileNewPasswordBody) {
    const data = await this
      .httpClient
      .Put<null, IProfileNewPasswordBody>(addressProfilePassword, body);
    return data;
  }

  async putProfileFullName(body: IProfileFullName) {
    const data = await this
      .httpClient
      .Put<null, IProfileFullName>(
        addressProfileFullName,
        body,
      );
    return data;
  }

  async putProfileAvatar(body: IProfileAvatar) {
    const data = await this
      .httpClient
      .Put<null, IProfileAvatar>(
        addressProfileAvatar,
        body,
      );
    return data;
  }

  async putProfileSettings(body: IUserClientSettings) {
    const strBody = { userClientSettings: JSON.stringify(body) };
    const data = await this
      .httpClient
      .Put<null, IFetchUserClientSettings>(addressProfileSettings, strBody, false);
    return data;
  }
}
