import { MantineSize } from '@mantine/core';
import { DateRangePicker, DateRangePickerValue } from '@mantine/dates';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { styleDatePickerActualDay } from '../../../const/utils';

interface IFilterByRangeDateProps {
  label?: string,
  placeholder?: string,
  clearable?: boolean,
  size?: MantineSize,
  icon?: ReactNode,
  setFilterValue: React.Dispatch<React.SetStateAction<(string | null)[]>>,
  value: (string | null)[],
}

function FilterByRangeDate({
  label,
  placeholder,
  clearable,
  size,
  icon,
  setFilterValue,
  value,
}: IFilterByRangeDateProps) {
  return (
    <DateRangePicker
      allowSingleDateInRange
      label={label}
      placeholder={placeholder}
      clearable={clearable}
      size={size}
      icon={icon}
      onChange={(date) => setFilterValue(date.map((it) => (it ? it.toLocaleDateString().split('.').reverse().join('-') : null)))}
      dayStyle={styleDatePickerActualDay}
      value={value.map((it) => (it === null ? null : new Date(it))) as DateRangePickerValue}
      styles={{
        cell: {
          padding: '0 !important',
        },
      }}
      inputFormat="DD.MM"
    />
  );
}

FilterByRangeDate.defaultProps = {
  label: undefined,
  placeholder: undefined,
  clearable: true,
  size: 'xs',
  icon: <FaRegCalendarAlt />,
};

export default observer(FilterByRangeDate);
