import { Select } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { useStores } from '../../../../App';
import { requiredMsg } from '../../../../const/const';

interface ISaleSourceInputProps {
  required?: boolean,
}

function SaleSourceInput({
  required,
}: ISaleSourceInputProps) {
  const { saleSourceLookupStore: { selectItems } } = useStores();

  const {
    control,
    formState: { errors },
  } = useFormContext<{ sourceId: number | null }>();

  return (
    <Controller
      name="sourceId"
      control={control}
      rules={{ required: required && requiredMsg }}
      render={({ field: { value, onChange } }) => (
        <Select
          value={value ? value.toString() : null}
          onChange={(val) => onChange(val ? +val : null)}
          clearable
          data={selectItems}
          label="Источник"
          withAsterisk={required}
          error={errors.sourceId?.message}
        />
      )}
    />
  );
}

SaleSourceInput.defaultProps = {
  required: true,
};

export default observer(SaleSourceInput);
