import { addressTaskTickets, addressTaskTicketsToDoCount, addressTaskTicketsWithId } from '../../const/const';
import HttpClient from '../HttpClient';
import { ICreateTaskTicketAnswer, ICreateTaskTicketBody, IFetchGetTaskTicketsProps, IGetTaskTicketsToDoCountForUserResponse, ITaskTicketsResponse, IUpdateTaskTicketBody } from './taskTicketsApiTypes';

export default class TaskTicketsApi {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getTaskTickets(params: IFetchGetTaskTicketsProps) {
    const data = await this.httpClient.Get<ITaskTicketsResponse>(addressTaskTickets, { ...params });
    return data;
  }

  async postTaskTicket(body: ICreateTaskTicketBody) {
    const data = await this
      .httpClient
      .Post<ICreateTaskTicketAnswer, ICreateTaskTicketBody>(
        addressTaskTickets,
        body,
      );
    return data;
  }

  async putTaskTicket(body: IUpdateTaskTicketBody, id: number) {
    const data = await this
      .httpClient
      .Put<null, IUpdateTaskTicketBody>(
        addressTaskTicketsWithId(id),
        body,
      );
    return data;
  }

  async getTaskTicketsToDoCount() {
    const data = await this
      .httpClient
      .Get<IGetTaskTicketsToDoCountForUserResponse>(addressTaskTicketsToDoCount);
    return data;
  }
}
