import { MantineSize } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { styleDatePickerActualDay } from '../../../const/utils';

interface IFilterByDateProps {
  label?: string,
  placeholder?: string,
  clearable?: boolean,
  size?: MantineSize,
  icon?: ReactNode,
  setFilterValue: React.Dispatch<React.SetStateAction<string | null>>,
  value: string | null,
}

function FilterByDate({
  label,
  placeholder,
  clearable,
  size,
  icon,
  setFilterValue,
  value,
}: IFilterByDateProps) {
  return (
    <DatePicker
      label={label}
      placeholder={placeholder}
      clearable={clearable}
      size={size}
      icon={icon}
      onChange={(date) => setFilterValue(date ? date.toLocaleDateString().split('.').reverse().join('-') : null)}
      dayStyle={styleDatePickerActualDay}
      value={value === null ? null : new Date(value)}
      styles={{
        cell: {
          padding: '0 !important',
        },
      }}
    />
  );
}

FilterByDate.defaultProps = {
  label: undefined,
  placeholder: undefined,
  clearable: true,
  size: 'xs',
  icon: <FaRegCalendarAlt />,
};

export default observer(FilterByDate);
