import { Group, Text, Button, Modal } from '@mantine/core';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useStores } from '../../../App';
import { IPostResponse, ISendCashboxBody } from '../../../clientApi/CashboxApi/cashboxApiTypes';
import FormWrapper from '../../_Shared/FormElements/FormWrapper';
import AddCashboxItemFormContent from './AddCashboxItemFormContent';

const defaultValues = {
  categoryId: undefined,
  amount: undefined,
  currencyId: null,
  comment: null,
  paymentMethodTypeId: null,
};

interface IAddCashboxItemBtnProps {
  fetchItems: () => void,
}

function AddCashboxItemBtn({
  fetchItems,
}: IAddCashboxItemBtnProps) {
  const { clientApi: { cashboxApi } } = useStores();

  const [isOpened, setIsOpened] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  async function fetchData(data: ISendCashboxBody) {
    setIsFormLoading(true);
    const response = await cashboxApi.postCashbox(data);
    setIsFormLoading(false);
    return response;
  }

  async function actionAfterSuccess() {
    setIsOpened(false);
    fetchItems();
  }

  return (
    <>
      <Modal
        opened={isOpened}
        onClose={() => setIsOpened(false)}
        title={(
          <Text align="center" weight="bold">
            Добавить запись в кассу
          </Text>
        )}
      >
        <FormWrapper<ISendCashboxBody, IPostResponse>
          isFormLoading={isFormLoading}
          defaultValues={defaultValues}
          withResetBtn
          fetchData={fetchData}
          actionAfterSuccess={actionAfterSuccess}
          onCancelAction={() => setIsOpened(false)}
        >
          <AddCashboxItemFormContent />
        </FormWrapper>
      </Modal>
      <Group position="right">
        <Button onClick={() => setIsOpened(true)} variant="subtle" leftIcon={<FaPlus />}>
          Добавить
        </Button>
      </Group>
    </>
  );
}

export default observer(AddCashboxItemBtn);
