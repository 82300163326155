import { Container, Group, NumberInput, Select, Button, ActionIcon, Grid, Stack } from '@mantine/core';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FaAsterisk, FaDollarSign, FaPlus, FaTrash } from 'react-icons/fa';
import { IoPricetags } from 'react-icons/io5';
import { useStores } from '../../../App';
import { ISendReceiptBody } from '../../../clientApi/ReceiptsApi/receiptsApiTypes';
import { dataCurrencySelect, requiredMsg } from '../../../const/const';
import { actionOnFocusNumberInput } from '../../../const/utils';
import ReceiptItemSoldProductForm from './ReceiptItemSoldProductForm';
import { IExcelFileDataItem } from './XlsxInput';

interface IAddReceiptItemProps {
  excelFileData: IExcelFileDataItem[],
  setIsBatchSearchLoading: React.Dispatch<React.SetStateAction<boolean>>,
}

function AddReceiptItemForm({
  excelFileData,
  setIsBatchSearchLoading,
}: IAddReceiptItemProps) {
  const {
    control,
    setError,
    formState: { errors },
  } = useFormContext<ISendReceiptBody>();

  const { clientApi: { productsApi } } = useStores();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'receiptItems',
  });

  const [productsErrors, setProductsErrors] = useState<(null | string)[]>([]);

  async function excelDataHandling() {
    setIsBatchSearchLoading(true);
    if (excelFileData.length !== 0) {
      const [data] = await productsApi.putProductsBatchSearch({
        searchModels: excelFileData,
      });
      if (data !== null) {
        data.results.forEach((it, i) => {
          if (it.id !== null && it.productName !== null) {
            append({
              productId: it.id,
              quantity: excelFileData[i].quantity,
              primaryCost: excelFileData[i].primaryCost,
              primaryCostCurrencyId: excelFileData[i].primaryCostCurrencyId,
              name: it.productName,
            });
          }
          if (it.error !== null) {
            append({
              quantity: excelFileData[i].quantity,
              primaryCost: excelFileData[i].primaryCost,
              primaryCostCurrencyId: excelFileData[i].primaryCostCurrencyId,
            });
            setError(`receiptItems.${fields.length + i}.productId`, { message: it.error });
          }
          setProductsErrors((s) => s.concat(it.error));
        });
      }
    }
    setIsBatchSearchLoading(false);
  }

  useEffect(() => {
    excelDataHandling();
  }, [excelFileData]);

  return (
    <Stack spacing={0} align="baseline">
      {fields.map((fieldItem, index) => (
        <Container key={fieldItem.id} px={4}>
          <Grid align="center" py="xs">
            <Grid.Col span="auto">
              <ReceiptItemSoldProductForm
                i={index}
                productError={productsErrors[index]}
              />
            </Grid.Col>
            <Grid.Col span="content" px={0}>
              <ActionIcon
                variant="subtle"
                color="red"
                onClick={() => {
                  remove(index);
                  setProductsErrors((s) => s.slice(0, index).concat(s.slice(index + 1)));
                }}
              >
                <FaTrash />
              </ActionIcon>
            </Grid.Col>
          </Grid>

          <Group spacing="xs" grow pb="xs" align="start">
            <Controller
              name={`receiptItems.${index}.quantity`}
              control={control}
              rules={{ required: requiredMsg, min: { value: 1, message: requiredMsg } }}
              render={({ field }) => (
                <NumberInput
                  {...field}
                  placeholder="Кол-во"
                  icon={<FaAsterisk />}
                  withAsterisk
                  min={1}
                  step={1}
                  error={errors.receiptItems && errors.receiptItems[index]?.quantity?.message}
                  onFocus={actionOnFocusNumberInput}
                />
              )}
            />
            <Controller
              name={`receiptItems.${index}.primaryCost`}
              control={control}
              rules={{ required: requiredMsg, min: { value: 0.01, message: requiredMsg } }}
              render={({ field }) => (
                <NumberInput
                  {...field}
                  placeholder="Цена"
                  icon={<IoPricetags />}
                  withAsterisk
                  step={0.1}
                  precision={2}
                  error={!!errors?.receiptItems
                    && errors.receiptItems?.[index]?.primaryCost?.message}
                  onFocus={actionOnFocusNumberInput}
                />
              )}
            />
            <Controller
              name={`receiptItems.${index}.primaryCostCurrencyId`}
              control={control}
              rules={{ required: requiredMsg }}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value !== undefined ? value.toString() : null}
                  onChange={(val) => onChange(val !== null ? +val : undefined)}
                  placeholder="Валюта"
                  icon={<FaDollarSign />}
                  withAsterisk
                  data={dataCurrencySelect}
                  error={errors.receiptItems
                    && errors.receiptItems[index]?.primaryCostCurrencyId?.message}
                />
              )}
            />
          </Group>
        </Container>
      ))}
      <Button
        variant="subtle"
        compact
        leftIcon={<FaPlus />}
        onClick={() => {
          append({});
          setProductsErrors((s) => s.concat(null));
        }}
      >
        Товар
      </Button>
    </Stack>
  );
}

export default observer(AddReceiptItemForm);
