/* eslint-disable react/require-default-props */
import { Title } from '@mantine/core';
import { ReactNode } from 'react';

export interface IPageTitle {
  children?: ReactNode,
  text?: string,
  paddingBottom?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number,
}

export default function PageTitle({ children = '', text = '', paddingBottom = 'md' } : IPageTitle) {
  return (
    <Title order={1} size="h2" align="center" color="gray.6" pb={paddingBottom}>{`${children}${text}`}</Title>
  );
}
