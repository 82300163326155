import { ActionIcon, Button, Grid, Space, Stack, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SectionTitle from '../../_Shared/SectionTitle';
import SoldProductQuantityInput from '../../_Shared/FormElements/numberInputs/SoldProductQuantityInput';
import SoldProductSalePriceBynInput from '../../_Shared/FormElements/numberInputs/SoldProductSalePriceBynInput';
import SoldProductIsPostSaleInput from '../../_Shared/FormElements/checkboxInputs/SoldProductIsPostSaleInput';
import SoldProductStorageInput from '../../_Shared/FormElements/lookupInputs/SoldProductStorageInput';
import SoldProductIsOrderedInput from '../../_Shared/FormElements/checkboxInputs/SoldProductIsOrderedInput';
import DeliveryPriceForCustomerInput from '../../_Shared/FormElements/numberInputs/DeliveryPriceForCustomerInput';
import DeliveryPrimaryPriceInput from '../../_Shared/FormElements/numberInputs/DeliveryPrimaryPriceInput';
import { IUpdateSaleBody, IUpdateSaleBodySoldProduct } from '../../../clientApi/SaleApi/saleApiTypes';
import SoldProductNameInput from '../../_Shared/FormElements/textInputs/SoldProductNameInput';
import { NullValueLabel } from '../../../utils/stringUtils';

const defaultValues : IUpdateSaleBodySoldProduct = {
  id: 0,
  name: null,
  productId: null,
  storageId: null,
  isOrdered: false,
  quantity: 0,
  salePriceByn: 0,
  isPostSale: false,
  primaryPrice: null,
};

function SoldProductsSectionForm() {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<IUpdateSaleBody>();
  const { fields, append, remove } = useFieldArray({ control, name: 'soldProducts' });

  const sumByProducts = watch('soldProducts').reduce((res, it) => res + (it.salePriceByn || 0) * (it.quantity || 0), 0);
  const totalAmount = (watch('delivery.priceForCustomer') || 0) + sumByProducts;
  const soldProducts = watch('soldProducts');

  return (
    <Stack spacing="xs" sx={{ minWidth: '600px' }}>
      <Space h={8} />
      <Grid align="center">
        <Grid.Col sm="auto" />
        <Grid.Col sm="content">
          <SectionTitle>Товары</SectionTitle>
        </Grid.Col>
        <Grid.Col sm="auto">
          <Button variant="subtle" compact leftIcon={<FaPlus />} onClick={() => append(defaultValues)}>
            Добавить
          </Button>
        </Grid.Col>
      </Grid>
      <ErrorMessage
        errors={errors}
        name="soldProducts"
        render={(e) => <Text color="red" size="sm">{e.message}</Text>}
      />

      {fields.map((f, i) => (
        <div key={f.id}>
          <Grid gutter="xs">
            <Grid.Col sm={6}>
              <SoldProductNameInput i={i} />
            </Grid.Col>
            <Grid.Col sm={2}>
              <SoldProductQuantityInput required={false} i={i} />
            </Grid.Col>
            <Grid.Col sm={2}>
              <SoldProductSalePriceBynInput required={false} i={i} />
            </Grid.Col>
            <Grid.Col sm={2}>
              <Text weight="500" color="gray.9" size="sm">C/c</Text>
              <Text size="sm" mt="xs">
                {soldProducts[i].primaryPrice?.toFixed(2) || NullValueLabel}
              </Text>
            </Grid.Col>
          </Grid>

          <Grid gutter="xs">
            <Grid.Col sm="content">
              <SoldProductIsPostSaleInput i={i} />
            </Grid.Col>
            <Grid.Col sm={4}>
              <SoldProductStorageInput i={i} />
            </Grid.Col>
            <Grid.Col sm="content">
              <SoldProductIsOrderedInput i={i} />
            </Grid.Col>
            <Grid.Col sm="auto">
              <ActionIcon size={32} variant="subtle" color="red" mt="xl" onClick={() => remove(i)}>
                <FaTrash />
              </ActionIcon>
            </Grid.Col>
          </Grid>
        </div>
      ))}

      <Grid gutter="sm">
        <Grid.Col sm="content">
          <Text color="gray.7" weight="bold" size="sm" mt="xl">Услуга доставки:</Text>
        </Grid.Col>
        <Grid.Col sm={2}>
          <DeliveryPriceForCustomerInput required={false} />
        </Grid.Col>
        <Grid.Col sm={2}>
          <DeliveryPrimaryPriceInput required={false} />
        </Grid.Col>
        <Grid.Col sm="auto">
          <Text color="gray.7" weight="bold" size="sm" mt="xl" align="right">
            {`Итого: ${totalAmount.toFixed(2)}p`}
          </Text>
        </Grid.Col>
      </Grid>
    </Stack>
  );
}

export default observer(SoldProductsSectionForm);
