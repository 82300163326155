import { ActionIcon } from '@mantine/core';
import { observer } from 'mobx-react';
import { FaExpandAlt } from 'react-icons/fa';

interface IOpenAllCardsBtnProps {
  action: () => void,
  isDisabled: boolean,
}

function OpenAllCardsBtn({
  action,
  isDisabled,
}: IOpenAllCardsBtnProps) {
  return (
    <ActionIcon color="blue" size={34} onClick={action} disabled={isDisabled}>
      <FaExpandAlt size={20} />
    </ActionIcon>
  );
}

export default observer(OpenAllCardsBtn);
