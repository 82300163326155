import { observer } from 'mobx-react';
import { useStores } from '../../../App';
import { saleSourceFormInfo } from '../const';
import LookupContent from './LookupContent/LookupContent';

function SaleSourceLookup() {
  const { saleSourceLookupStore: store } = useStores();

  return (
    <LookupContent
      title="Источники продаж"
      formInfo={saleSourceFormInfo}
      isLoading={store.isLoading}
      isFormLoading={store.isFormLoading}
      items={store.items}
      fetchAddItem={store.postSaleSourceLookup}
      fetchChangeItem={store.putSaleSourceLookup}
      fetchDeleteItem={store.deleteSaleSourceLookup}
      fetchItems={store.getSaleSourceLookup}
    />
  );
}

export default observer(SaleSourceLookup);
