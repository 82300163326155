import { IconType } from 'react-icons';
import { Navigate } from 'react-router-dom';
import { IProfileDataPermission } from '../clientApi/ProfileApi/profileApiTypes';
import ErrorPage from '../components/_Shared/ErrorPages/ErrorPage';
import PageNotFound from '../components/_Shared/ErrorPages/PageNotFound';
import { allPermissions, nestedLink, permissionsRoutes } from './const';
import { TNavLinksData, IRoutesData, ILinkGroupItem, INestedLink, INestedLinkItem } from './types';

export function getNavLinkData(data: IProfileDataPermission[]): TNavLinksData {
  const result: TNavLinksData = [];
  const permissions = Object.entries(allPermissions);
  permissions.forEach(([name, item]) => {
    if (data.find((it) => it.key === name)) {
      if ('link' in item) {
        result.push({
          label: item.label as string,
          icon: item.icon as IconType,
          link: item.link as string,
        });
      }
      if ('links' in item) {
        result.push({
          label: item.label as string,
          icon: item.icon as IconType,
          links: item.links as ILinkGroupItem[],
        });
      }
    } else if (name in nestedLink) {
      const links: ILinkGroupItem[] = [];
      nestedLink[name].forEach((a) => {
        if (data.find((it) => it.key === a)) {
          links.push({
            label: ((item as INestedLink)[a] as INestedLinkItem).label,
            link: ((item as INestedLink)[a] as INestedLinkItem).link,
          });
        }
      });
      if (links.length !== 0) {
        result.push({
          label: item.label as string,
          icon: item.icon as IconType,
          links,
        });
      }
    }
  });
  return result;
}

// [key as keyof Options]
export function getRoutesData(data: IProfileDataPermission[]): IRoutesData[] {
  const result: IRoutesData[] = [];
  if (data.length === 0) {
    result.push(
      { id: 0, path: '*', element: <ErrorPage /> },
    );
  } else {
    Object.entries(permissionsRoutes)
      .forEach(([name, rout]) => {
        if (data.find((it) => it.key === name)) {
          if (Array.isArray(rout)) {
            rout.forEach((a) => result.push(a));
          } else {
            result.push(rout);
          }
        }
      });
    const firstPath = result[0].path;
    result.push(
      { id: 1, path: '/', element: <Navigate to={firstPath} replace /> },
      { id: 2, path: '*', element: <PageNotFound /> },
    );
  }
  return result;
}
