import { Text } from '@mantine/core';

interface ITitleModalProps {
  title: string,
}

export default function TitleModal({
  title,
}: ITitleModalProps) {
  return (
    <Text align="center" weight="bold">
      {title}
    </Text>
  );
}
