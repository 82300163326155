import { Modal } from '@mantine/core';
import TitleModal from './TitleModal';

interface IChangeItemModalProps {
  opened: boolean,
  closeModal: () => void,
  title: string,
  children: JSX.Element,
}

export default function ChangeItemModal({
  opened,
  closeModal,
  title,
  children,
}: IChangeItemModalProps) {
  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      title={<TitleModal title={title} />}
    >
      {children}
    </Modal>
  );
}
