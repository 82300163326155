import { Box, Group, Tooltip } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { observer } from 'mobx-react';
import { useStores } from '../../../App';
import { IPaymentMethodsLookupItem } from '../../../clientApi/LookupsApi/lookupsApiTypes';
import { CashTypes } from '../../../const/const';
import { DateAndTime } from '../../_Shared/DateViewComponents/DateAndTime';
import PageTitle from '../../_Shared/PageTitle';
import AddPaymentLookupBtn from './AddPaymentLookupBtn';
import DeletePaymentLookupBtn from './DeletePaymentLookupBtn';
import UpdatePaymentLookupBtn from './UpdatePaymentLookupBtn';

function PaymentMethodsLookup() {
  const {
    paymentMethodLookupStore: { items, isLoading },
    profileStore: { profilePermissions: { Lookups: lookupsPermission } },
  } = useStores();

  return (
    <>
      <PageTitle text="Способы оплаты" />
      <Group position="right">
        {lookupsPermission !== 'read-only' && (
          <AddPaymentLookupBtn />
        )}
      </Group>
      <Box sx={{ height: '100%', overflow: 'hidden' }}>
        <DataTable<IPaymentMethodsLookupItem>
          noRecordsText="По данному запросу записей не найдено"
          highlightOnHover
          fetching={isLoading}
          records={items}
          columns={[
            {
              accessor: 'id',
              title: 'ID',
            },
            {
              accessor: 'label',
              title: 'Название',
            },
            {
              accessor: 'createdDateUtc',
              title: 'Дата создания',
              render: (i) => <DateAndTime date={i.createdDateUtc} isOneLine />,
            },
            {
              accessor: 'creatorUser.fullName',
              title: 'Создал',
            },
            {
              accessor: 'fee',
              title: 'Комиссия (%)',
            },
            {
              accessor: 'cashType',
              title: 'Тип',
              render: (i) => CashTypes[i.cashType],
            },
            {
              accessor: 'updateLookupBtn',
              title: '',
              textAlignment: 'right',
              hidden: lookupsPermission !== 'read-and-edit',
              render: (i) => (
                <Tooltip.Group openDelay={500} closeDelay={100}>
                  <Group spacing={4} position="right" noWrap>
                    <UpdatePaymentLookupBtn
                      id={i.id}
                      item={i}
                    />
                    <DeletePaymentLookupBtn
                      id={i.id}
                      currentLabel={i.label}
                    />
                  </Group>
                </Tooltip.Group>
              ),
            },
          ]}
        />
      </Box>
    </>
  );
}

export default observer(PaymentMethodsLookup);
