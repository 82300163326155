import { Group, Space } from '@mantine/core';
import { observer } from 'mobx-react';
import AddUser from './TableUsers/UserActions/AddUser';
import TableUsers from './TableUsers/TableUsers';
import PageTitle from '../_Shared/PageTitle';
import UpdateContentBtn from '../_Shared/UpdateContentBtn';
import { useStores } from '../../App';

function Users() {
  const {
    usersStore: { getUsers, isLoading },
    profileStore: { profilePermissions: { Users: usersPermission } },
  } = useStores();

  return (
    <>
      <PageTitle text="Пользователи" />
      <Group position="right" spacing={4}>
        {usersPermission !== 'read-only' && (
          <AddUser />
        )}
        <UpdateContentBtn
          action={getUsers}
          isDisabled={isLoading}
        />
      </Group>
      <Space h={15} />
      <TableUsers />
    </>
  );
}

export default observer(Users);
