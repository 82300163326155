import { ActionIcon } from '@mantine/core';
import { observer } from 'mobx-react';
import { FaUndoAlt } from 'react-icons/fa';

interface IUpdateContentBtn {
  action: () => void,
  isDisabled: boolean,
}

function UpdateContentBtn({
  action,
  isDisabled,
}: IUpdateContentBtn) {
  return (
    <ActionIcon color="blue" size={34} onClick={action} disabled={isDisabled}>
      <FaUndoAlt size={20} />
    </ActionIcon>
  );
}

export default observer(UpdateContentBtn);
