import { Modal, Container, TextInput, Select, Space, Group, Button, Text, NumberInput, MultiSelect, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Controller, Path, SubmitHandler, useForm } from 'react-hook-form';
import { FaPlus, FaSave } from 'react-icons/fa';
import { useStores } from '../../../../App';
import { ICreateUserBody } from '../../../../clientApi/UsersApi/usersApiTypes';
import { actionOnFocusNumberInput } from '../../../../const/utils';
import { notificationsData } from '../../../../store/const';
import InputClearCross from '../../../_Shared/InputClearCross';

const defaultValues: ICreateUserBody = {
  salaryRates: {
    saleSourcesIds: [],
  },
};

function AddUser() {
  const {
    handleSubmit,
    reset,
    control,
    watch,
    setError,
    formState: { errors },
  } = useForm<ICreateUserBody>({ defaultValues });

  const {
    saleSourceLookupStore: { selectItems },
    lookupsStore: { selectUserRolesLookup },
    usersStore: { isFormLoading, postUser, getUsers },
  } = useStores();

  const [opened, setOpened] = useState(false);

  const chosenRole = watch('role');

  function onClose() {
    reset(defaultValues);
    setOpened(false);
  }

  const addUser: SubmitHandler<ICreateUserBody> = async (data) => {
    const [response, errorData] = await postUser(data);
    if (response !== null) {
      onClose();
      showNotification(notificationsData.passwordMsg(
        response.tempPassword,
        `${data.firstName} ${data.lastName}`,
      ));
      getUsers();
    } else if (Array.isArray(errorData)) {
      errorData.forEach((it) => setError(it[0] as Path<ICreateUserBody>, { message: it[1] }));
    }
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        title={(
          <Text align="center" weight="bold">
            Добавление нового пользователя
          </Text>
        )}
      >
        <form onSubmit={handleSubmit(addUser)}>
          <Container>
            <Stack>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: 'Это поле должно быть заполнено' }}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    value={value || ''}
                    onChange={({ currentTarget: { value: val } }) => onChange(val || undefined)}
                    label="Имя"
                    withAsterisk
                    icon="И"
                    error={errors.firstName?.message}
                    rightSection={<InputClearCross action={() => onChange(undefined)} />}
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                rules={{ required: 'Это поле должно быть заполнено' }}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    value={value || ''}
                    onChange={({ currentTarget: { value: val } }) => onChange(val || undefined)}
                    label="Фамилия"
                    withAsterisk
                    icon="Ф"
                    error={errors.lastName?.message}
                    rightSection={<InputClearCross action={() => onChange(undefined)} />}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                rules={{ required: 'Это поле должно быть заполнено' }}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    value={value || ''}
                    onChange={({ currentTarget: { value: val } }) => onChange(val || undefined)}
                    label="Почта"
                    withAsterisk
                    icon="@"
                    error={errors.email?.message}
                    rightSection={<InputClearCross action={() => onChange(undefined)} />}
                  />
                )}
              />
              <Controller
                name="role"
                control={control}
                rules={{ required: 'Это поле должно быть заполнено' }}
                render={({ field: { onChange, ...other } }) => (
                  <Select
                    {...other}
                    onChange={(val) => {
                      onChange(val);
                    }}
                    label="Роль"
                    withAsterisk
                    clearable
                    icon="P"
                    data={selectUserRolesLookup}
                    error={errors.role?.message}
                  />
                )}
              />
              {chosenRole === 'Manager' && (
                <>
                  <Controller
                    name="salaryRates.rateFromSales"
                    control={control}
                    rules={{ required: chosenRole !== 'Manager' ? false : 'Это поле должно быть заполнено' }}
                    render={({ field }) => (
                      <NumberInput
                        {...field}
                        label="Процент от продаж"
                        withAsterisk
                        error={errors.salaryRates?.rateFromSales?.message}
                        onFocus={actionOnFocusNumberInput}
                      />
                    )}
                  />
                  <Controller
                    name="salaryRates.rateFromPostSales"
                    control={control}
                    rules={{ required: chosenRole !== 'Manager' ? false : 'Это поле должно быть заполнено' }}
                    render={({ field }) => (
                      <NumberInput
                        {...field}
                        label="Процент от допродаж"
                        withAsterisk
                        error={errors.salaryRates?.rateFromPostSales?.message}
                        onFocus={actionOnFocusNumberInput}
                      />
                    )}
                  />
                </>
              )}
              {chosenRole === 'Advertiser' && (
                <>
                  <Controller
                    name="salaryRates.rateFromSaleSources"
                    control={control}
                    rules={{ required: chosenRole !== 'Advertiser' ? false : 'Это поле должно быть заполнено' }}
                    render={({ field }) => (
                      <NumberInput
                        {...field}
                        label="Процент от источника"
                        withAsterisk
                        error={errors.salaryRates?.rateFromSaleSources?.message}
                        onFocus={actionOnFocusNumberInput}
                      />
                    )}
                  />
                  <Controller
                    name="salaryRates.saleSourcesIds"
                    control={control}
                    rules={{ required: chosenRole !== 'Advertiser' ? false : 'Это поле должно быть заполнено' }}
                    render={({ field: { value, onChange } }) => (
                      <MultiSelect
                        value={value === null ? undefined : value.map((it) => it.toString())}
                        onChange={(val) => onChange(val.map((it) => Number(it)))}
                        label="Источники продаж"
                        withAsterisk
                        clearable
                        error={errors.salaryRates?.saleSourcesIds?.message}
                        data={selectItems}
                      />
                    )}
                  />
                </>
              )}
            </Stack>
          </Container>
          <Space h="xl" />
          <Container size="xs" px="xs">
            <Group position="apart">
              <Button variant="subtle" onClick={() => reset(defaultValues)}>
                Сбросить
              </Button>
              <Group>
                <Button variant="subtle" onClick={onClose}>Отмена</Button>
                <Button loading={isFormLoading} type="submit" leftIcon={<FaSave />}>Сохранить</Button>
              </Group>
            </Group>
          </Container>
        </form>
      </Modal>
      <Button onClick={() => setOpened(true)} variant="subtle" leftIcon={<FaPlus />}>
        Добавить
      </Button>
    </>
  );
}

export default observer(AddUser);
