import { forwardRef } from 'react';
import { Badge, Text, AutocompleteItem, Group } from '@mantine/core';
import { IProductVendorViewModel, IProductViewModel } from '../../../clientApi/ProductsApi/productsApiTypes';

interface IProduct {
  id: number;
  article: string;
  name: string;
  category: IProductVendorViewModel;
  vendor: IProductVendorViewModel;
  availableQuantity: number;
}

export class SelectProductItemProps implements AutocompleteItem, IProduct {
  id: number;

  article: string;

  name: string;

  category: IProductVendorViewModel;

  vendor: IProductVendorViewModel;

  availableQuantity: number;

  public get key(): number { return this.id; }

  public get value(): string { return this.name; }

  constructor(product: IProductViewModel) {
    this.id = product.id;
    this.article = product.article;
    this.name = product.name;
    this.category = product.category;
    this.vendor = product.vendor;
    this.availableQuantity = product.availableQuantity;
  }
}

export const AutocompleteProductItem = forwardRef<HTMLDivElement, IProductViewModel>(
  ({ id, name, category, availableQuantity, ...others }: IProductViewModel, ref) => (
    <div id={id.toString()} ref={ref} {...others}>
      <Text>{name}</Text>
      <Group position="apart">
        <Text span size="xs" color="dimmed">{category.name}</Text>
        <Badge color={availableQuantity > 0 ? 'teal' : 'yellow'}>{availableQuantity}</Badge>
      </Group>
    </div>
  ),
);
