import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStores } from '../../../App';
import { ITaskTicket, IUpdateTaskTicketBody } from '../../../clientApi/TaskTicketsApi/taskTicketsApiTypes';
import FormWrapper from '../../_Shared/FormElements/FormWrapper';
import UpdateTaskFormContent from './UpdateTaskFormContent';

interface IUpdateTaskFormProps {
  item: ITaskTicket,
  closeModal: () => void,
  fetchItems: () => Promise<void>,
}

function UpdateTaskForm({
  item,
  fetchItems,
  closeModal,
}: IUpdateTaskFormProps) {
  const { clientApi: { taskTicketsApi } } = useStores();

  const [isFormLoading, setIsFormLoading] = useState(false);
  const defaultValues: IUpdateTaskTicketBody = {
    id: item.id,
    header: item.header || undefined,
    description: item.description || undefined,
    priorityId: item.priority.id,
    startDateTimeUtc: new Date(item.startDateTimeUtc),
    assigneeUserId: item.assigneeUser?.userId,
    isTaskForAll: item.isTaskForAll,
    isFinished: item.isFinished,
  };

  async function fetchData(data: IUpdateTaskTicketBody) {
    setIsFormLoading(true);
    const response = await taskTicketsApi.putTaskTicket(data, item.id);
    setIsFormLoading(false);
    return response;
  }

  function actionAfterSuccess() {
    closeModal();
    fetchItems();
  }

  return (
    <FormWrapper<IUpdateTaskTicketBody, null>
      isFormLoading={isFormLoading}
      defaultValues={defaultValues}
      withResetBtn
      fetchData={fetchData}
      actionAfterSuccess={actionAfterSuccess}
      onCancelAction={closeModal}
    >
      <UpdateTaskFormContent />
    </FormWrapper>
  );
}

export default observer(UpdateTaskForm);
