import { Stack, TextInput } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { IProfileFullName } from '../../../../clientApi/ProfileApi/profileApiTypes';
import { requiredMsg } from '../../../../const/const';
import InputClearCross from '../../../_Shared/InputClearCross';

function ChangeProfileNameFormContent() {
  const {
    control,
    formState: { errors },
  } = useFormContext<IProfileFullName>();

  return (
    <Stack>
      <Controller
        name="firstName"
        control={control}
        rules={{ required: requiredMsg }}
        render={({ field: { value, onChange } }) => (
          <TextInput
            value={value || ''}
            onChange={({ currentTarget: { value: val } }) => onChange(val || null)}
            label="Имя"
            withAsterisk
            error={errors.firstName?.message}
            rightSection={<InputClearCross action={() => onChange(null)} />}
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        rules={{ required: requiredMsg }}
        render={({ field: { value, onChange } }) => (
          <TextInput
            value={value || ''}
            onChange={({ currentTarget: { value: val } }) => onChange(val || null)}
            label="Фамилия"
            withAsterisk
            error={errors.lastName?.message}
            rightSection={<InputClearCross action={() => onChange(null)} />}
          />
        )}
      />
    </Stack>
  );
}

export default observer(ChangeProfileNameFormContent);
