import { ActionIcon, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { FaKey } from 'react-icons/fa';
import ChangeProfilePassModal from './ChangeProfilePassModal';

export default function ChangeProfilePassBtn() {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <ChangeProfilePassModal
        isOpened={isOpened}
        setIsOpened={setIsOpened}
      />
      <Tooltip withArrow label="Изменить пароль">
        <ActionIcon size="lg" variant="outline" onClick={() => setIsOpened(true)}>
          <FaKey size={20} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}
