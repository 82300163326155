import { Text, Modal } from '@mantine/core';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStores } from '../../../../App';
import { IImgInput } from '../../../../clientApi/ProfileApi/profileApiTypes';
import FormWrapper from '../../../_Shared/FormElements/FormWrapper';
import { getBase64FormatImg } from '../../utils';
import ChangeProfileImgFormContent from './ChangeProfileImgFormContent';

const defaultValues = {
  newAvatarBase64: null,
};

interface IChangeProfileImgModalProps {
  isOpened: boolean,
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>,
}

function ChangeProfileImgModal({
  isOpened,
  setIsOpened,
}: IChangeProfileImgModalProps) {
  const [isFormLoading, setIsFormLoading] = useState(false);

  const { profileStore } = useStores();

  async function fetchData(data: IImgInput) {
    setIsFormLoading(true);
    const newAvatarBase64 = await getBase64FormatImg(data.newAvatarBase64 as File);
    const response = await profileStore.putProfileAvatar({ newAvatarBase64 });
    setIsFormLoading(false);
    return response;
  }

  return (
    <Modal
      opened={isOpened}
      onClose={() => setIsOpened(false)}
      title={(
        <Text align="center" weight="bold">
          Смена аватарки
        </Text>
      )}
    >
      <FormWrapper<IImgInput, null>
        defaultValues={defaultValues}
        isFormLoading={isFormLoading}
        fetchData={fetchData}
        actionAfterSuccess={() => setIsOpened(false)}
      >
        <ChangeProfileImgFormContent />
      </FormWrapper>
    </Modal>
  );
}

export default observer(ChangeProfileImgModal);
