/* eslint-disable no-unused-vars */
import { Button, Container, Group, Modal, Space, Text, TextInput } from '@mantine/core';
import { useState } from 'react';
import { Controller, Path, SubmitHandler, useForm } from 'react-hook-form';
import { FaPlus, FaSave } from 'react-icons/fa';
import { TErrors } from '../../../../../../clientApi/apiTypes';
import { ISendSimpleLookupBody } from '../../../../../../clientApi/LookupsApi/lookupsApiTypes';
import { requiredMsg } from '../../../../../../const/const';
import InputClearCross from '../../../../../_Shared/InputClearCross';
import { IAddLookupForm } from '../../../../types';

const defaultValues = {
  label: '',
};

interface IAddLookupBtnProps {
  isFormLoading: boolean,
  addForm: IAddLookupForm,
  fetchAddItem: (body: ISendSimpleLookupBody) => Promise<TErrors | null | false>;
  fetchItems: () => Promise<void>,
}

export default function AddLookupBtn({
  isFormLoading,
  addForm,
  fetchAddItem,
  fetchItems,
}: IAddLookupBtnProps) {
  const [visible, setVisible] = useState(false);

  const {
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm<ISendSimpleLookupBody>({ defaultValues });

  function switchModal() {
    setVisible((s) => !s);
    reset(defaultValues);
  }

  const addLookup: SubmitHandler<ISendSimpleLookupBody> = async (data) => {
    const dataErrors = await fetchAddItem(data);
    if (Array.isArray(dataErrors)) {
      dataErrors
        .forEach((it) => setError(it[0] as Path<ISendSimpleLookupBody>, { message: it[1] }));
    } else if (dataErrors === null) {
      setVisible(false);
      reset(defaultValues);
      fetchItems();
    }
  };

  return (
    <>
      <Modal
        opened={visible}
        onClose={() => setVisible(false)}
        title={(
          <Text align="center" weight="bold">
            {addForm.formTitle}
          </Text>
        )}
      >
        <form onSubmit={handleSubmit(addLookup)}>
          <Container>
            <Controller
              name="label"
              control={control}
              rules={{ required: requiredMsg }}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  value={value}
                  onChange={({ currentTarget: { value: val } }) => onChange(val)}
                  label={addForm.inputLabel}
                  withAsterisk
                  icon={addForm.inputIcon}
                  error={errors.label?.message}
                  rightSection={<InputClearCross action={() => onChange('')} />}
                />
              )}
            />
          </Container>
          <Space h="xl" />
          <Container size="xs" px="xs">
            <Group position="right">
              <Button variant="subtle" onClick={switchModal}>Отмена</Button>
              <Button loading={isFormLoading} type="submit" leftIcon={<FaSave />}>Сохранить</Button>
            </Group>
          </Container>
        </form>
      </Modal>
      <Group position="right">
        <Button onClick={() => setVisible(true)} variant="subtle" leftIcon={<FaPlus />}>
          Добавить
        </Button>
      </Group>
    </>
  );
}
