import { ActionIcon, Box, Button, Container, Group, Modal, Space, Text, Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { FaExclamationTriangle, FaLock } from 'react-icons/fa';
import { useStores } from '../../../../App';
import { simpleSuccess } from '../../../../store/const';
import { IBlockUser } from '../../types';

function UnblockUser({ setBlock, id, fullName }: IBlockUser) {
  const [opened, setOpened] = useState(false);
  function switchModal() {
    setOpened((state) => !state);
  }

  const { usersStore: { isFormLoading, postUnblockUser } } = useStores();

  async function unblockUser() {
    const [, errorData] = await postUnblockUser(id);
    if (errorData === null) {
      setBlock((block) => !block);
      showNotification(simpleSuccess(`Пользователь ${fullName} разблокирован`));
      setOpened(false);
    }
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={switchModal}
        title={(
          <Text align="center" weight="bold">
            Подтверждение
          </Text>
        )}
      >
        <Container>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <FaExclamationTriangle size={40} color="orange" />
            <Text>
              {`Вы уверены что хотите разблокировать пользователя ${fullName}?`}
            </Text>
          </Box>
        </Container>
        <Space h="xl" />
        <Container size="xs" px="xs">
          <Group position="right">
            <Button variant="subtle" onClick={switchModal}>Нет</Button>
            <Button loading={isFormLoading} onClick={unblockUser}>Да</Button>
          </Group>
        </Container>
      </Modal>
      <Tooltip withArrow label="Разблокировать">
        <ActionIcon color="blue" onClick={switchModal}>
          <FaLock size={20} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}

export default observer(UnblockUser);
