import { TextInput, Loader } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStores } from '../../../../App';
import { notificationsData } from '../../../../store/const';
import { NullValueLabel } from '../../../../utils/stringUtils';

interface IUpdateSaleDeliveryTrackNumberProps {
  saleId: number,
  currentValue: string | null,
}

function UpdateSaleDeliveryTrackNumber({
  saleId,
  currentValue,
}: IUpdateSaleDeliveryTrackNumberProps) {
  const {
    clientApi: { saleApi },
  } = useStores();

  const [isLoading, setIsLoading] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);

  const [oldValue, setOldValue] = useState(currentValue);

  async function onChange(value: string | null) {
    setIsLoading(true);
    setOldValue(value);
    const [, errorData] = await saleApi.putSaleUpdateCommonProperties(
      {
        trackNumber: value,
        trackNumberSpecified: true,
      },
      saleId,
    );
    if (errorData === null) {
      setIsValidationError(false);
    }
    if (errorData) {
      setIsValidationError(true);
      showNotification(notificationsData.saleValidationErrorMsg(
        saleId,
        errorData.map((it) => it[1]),
      ));
    }
    setIsLoading(false);
  }

  return (
    <TextInput
      placeholder={NullValueLabel}
      onBlur={({ currentTarget: { value } }) => {
        const trimmedValue = value.trim() ? value : null;
        if (trimmedValue !== oldValue) {
          onChange(trimmedValue);
        }
      }}
      onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
      onDoubleClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
      defaultValue={currentValue === null ? '' : currentValue}
      icon={isLoading ? <Loader size="xs" /> : null}
      error={isValidationError}
      styles={{
        input: {
          border: 'none',
        },
      }}
    />
  );
}

export default observer(UpdateSaleDeliveryTrackNumber);
