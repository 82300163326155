import { observer } from 'mobx-react';
import React, { createContext, useContext, useMemo } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import ClientApi from './clientApi/ApiClient';
import Login from './components/Login/Login';
import MainLayout from './components/MainLayout/MainLayout';
import Profile from './components/Profile/Profile';
import RootStore from './store/RootStore';

let StoresContext: React.Context<RootStore>;
export function useStores() {
  return useContext(StoresContext);
}

function App() {
  const navigate = useNavigate();
  const InitStoresContext = useMemo(() => {
    const clientApi = new ClientApi(navigate);
    const rootStore = new RootStore(clientApi);
    StoresContext = createContext(rootStore);
    return rootStore;
  }, []);

  const { profileStore: { routesData } } = useStores();
  return (
    <StoresContext.Provider value={InitStoresContext}>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          {routesData.map((it) => (
            <Route path={it.path} element={it.element} key={it.id} />
          ))}
          <Route path="profile" element={<Profile />} />
          <Route path="*" element="" />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </StoresContext.Provider>
  );
}

export default observer(App);
