export function getBase64FormatImg(file: File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
  });
}

export function checkSizeImg(file: File | null) {
  return (file as File).size <= 110000 ? true : 'Размер файла превышает 100kb';
}

export function checkTypeImg(file: File | null) {
  const { type } = file as File;
  return type === 'image/png' || type === 'image/jpeg' ? true : 'Неверный формат файла';
}

export function checkNewPass(newPass1: string | null, newPass2: string | null) {
  return newPass1 === newPass2 ? true : 'Пароль повторён неверно';
}
