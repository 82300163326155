import { Text, Modal } from '@mantine/core';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useStores } from '../../../../App';
import { IProfileFullName } from '../../../../clientApi/ProfileApi/profileApiTypes';
import FormWrapper from '../../../_Shared/FormElements/FormWrapper';
import ChangeProfileNameFormContent from './ChangeProfileNameFormContent';

interface IChangeProfileNameModalProps {
  isOpened: boolean,
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>,
}

const defaultValues = {
  firstName: null,
  lastName: null,
};

function ChangeProfileNameModal({
  isOpened,
  setIsOpened,
}: IChangeProfileNameModalProps) {
  const [isFormLoading, setIsFormLoading] = useState(false);

  const { profileStore } = useStores();

  async function fetchData(data: IProfileFullName) {
    setIsFormLoading(true);
    const response = await profileStore.putProfileFullName(data);
    setIsFormLoading(false);
    return response;
  }

  return (
    <Modal
      opened={isOpened}
      onClose={() => setIsOpened(false)}
      title={(
        <Text align="center" weight="bold">
          Смена имени
        </Text>
      )}
    >
      <FormWrapper<IProfileFullName, null>
        defaultValues={defaultValues}
        isFormLoading={isFormLoading}
        fetchData={fetchData}
        actionAfterSuccess={() => setIsOpened(false)}
      >
        <ChangeProfileNameFormContent />
      </FormWrapper>
    </Modal>
  );
}

export default observer(ChangeProfileNameModal);
