import { showNotification } from '@mantine/notifications';
import { NavigateFunction } from 'react-router-dom';
import { IQueryStringParams } from '../const/types';
import { downloadFile, getUrlWithQuery } from '../const/utils';
import { notificationsData } from '../store/const';
import { IBadPutResponse, TErrors, TMethod } from './apiTypes';

const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;

export default class HttpClient {
  navigate: NavigateFunction;

  constructor(
    navigate: NavigateFunction,
  ) {
    this.navigate = navigate;
  }

  async flow<ResponseType, BodyType>(
    method: TMethod,
    url: string,
    body?: BodyType,
    isShowNotification = true,
    isShowErrorNotification = true,
    fileName: string | null = null,
  ): Promise<[ResponseType | null, TErrors | null | false]> {
    const data: [ResponseType | null, TErrors | null | false] = [null, null];
    try {
      const request = await fetch(url, {
        method,
        credentials: 'include',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: body === undefined
          ? null
          : JSON.stringify(body, (_, value) => {
            if (typeof value === 'string' && dateFormat.test(value)) {
              const date = new Date(value);
              const year = date.getFullYear();
              const month = date.getMonth();
              const day = date.getDate();
              const hours = date.getHours();
              const minutes = date.getMinutes();
              return new Date(Date.UTC(year, month, day, hours, minutes)).toJSON().slice(0, -1);
            }
            return value;
          }),
      });
      const { status } = request;

      if (status === 200) {
        try {
          if (fileName !== null) {
            const blobFile = await request.blob();
            downloadFile(blobFile, fileName);
          } else {
            data[0] = await request.json();
          }
        } catch (error) {
          //
        } finally {
          if (isShowNotification) {
            if (method === 'DELETE') showNotification(notificationsData.simpleDeleteSuccess);
            if (method === 'POST') showNotification(notificationsData.simpleAddedSuccess);
            if (method === 'PUT') showNotification(notificationsData.simpleSaveSuccess);
          }
        }
      } else if (status === 400) {
        const response: IBadPutResponse = await request.json();
        data[1] = Object.entries(response.errors);
        if (isShowErrorNotification) {
          showNotification(notificationsData.validationErrorMsg);
        }
      } else if (status === 401) {
        this.navigate('/login');
      } else if (status === 403) {
        data[1] = false;
        showNotification(notificationsData.permissionErrorMsg);
      } else if (status === 404) {
        data[1] = false;
      } else {
        data[1] = false;
        showNotification(notificationsData.serverErrorMsg(JSON.stringify({
          addressRequest: url,
          body: body === undefined ? 'body is undefined' : body,
          status,
          method,
        }, null, '  ')));
      }

      // if (status === 500) {
      //   showNotification(notificationsData.serverErrorMsg(JSON.stringify({
      //     addressRequest: url,
      //     body: body === undefined ? 'body is undefined' : body,
      //     status,
      //     method,
      //   }, null, '  ')));
      // }
    } catch (error) {
      data[1] = false;
      showNotification(notificationsData.rawErrorMsg(JSON.stringify({
        addressRequest: url,
        body: body === undefined ? 'body is undefined' : body,
        method,
        error: {
          name: (error as Error).name,
          message: (error as Error).message,
        },
      }, null, '  ')));
    }
    return data;
  }

  async Get<ResponseType>(
    path: string,
    params?: IQueryStringParams,
    isGetFile: string | null = null,
  ) {
    const url = getUrlWithQuery(path, params);
    const data = await this.flow<ResponseType, undefined>('GET', url, undefined, false, true, isGetFile);
    return data;
  }

  async Post<ResponseType, BodyType>(
    path: string,
    body: BodyType,
    isShowNotification = true,
  ) {
    const data = await this.flow<ResponseType, BodyType>('POST', path, body, isShowNotification);
    return data;
  }

  async Put<ResponseType, BodyType>(
    path: string,
    body: BodyType,
    isShowNotification = true,
    isShowErrorNotification = true,
  ) {
    const data = await this.flow<ResponseType, BodyType>('PUT', path, body, isShowNotification, isShowErrorNotification);
    return data;
  }

  async Delete(
    path: string,
  ) {
    const data = await this.flow<null, undefined>(
      'DELETE',
      path,
      undefined,
    );
    return data;
  }
}
