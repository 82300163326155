import { FaCheck, FaTimes } from 'react-icons/fa';

interface ITableCheckMarkProps {
  type: boolean,
}

export default function TableCheckMark({
  type,
}: ITableCheckMarkProps) {
  return type ? (
    <FaCheck size={15} color="green" />
  ) : (
    <FaTimes size={15} color="red" />
  );
}
