import { Grid, Space, Stack } from '@mantine/core';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SectionTitle from '../../_Shared/SectionTitle';
import DeliveryTypeInput from '../../_Shared/FormElements/lookupInputs/DeliveryTypeInput';
import DeliveryCourierInput from '../../_Shared/FormElements/lookupInputs/DeliveryCourierInput';
import DeliveryStartDateTimeInput from '../../_Shared/FormElements/dateInput/DeliveryStartDateTimeInput';
import DeliveryEndDateTimeInput from '../../_Shared/FormElements/dateInput/DeliveryEndDateTimeInput';
import DeliveryAddressInput from '../../_Shared/FormElements/textInputs/DeliveryAddressInput';
import DeliveryTrackNumberInput from '../../_Shared/FormElements/textInputs/DeliveryTrackNumberInput';

export default function DeliverySectionForm() {
  const { formState: { errors } } = useFormContext();
  return (
    <Stack spacing="xs" sx={{ minWidth: '300px' }}>
      <Space h={8} />
      <SectionTitle>Доставка</SectionTitle>
      <ErrorMessage errors={errors} name="delivery" />

      <DeliveryTypeInput />
      <DeliveryCourierInput required={false} />

      <Grid>
        <Grid.Col sm={6}>
          <DeliveryStartDateTimeInput required={false} />
        </Grid.Col>
        <Grid.Col sm={6}>
          <DeliveryEndDateTimeInput required={false} />
        </Grid.Col>
      </Grid>

      <DeliveryTrackNumberInput required={false} />

      <DeliveryAddressInput required={false} />
    </Stack>
  );
}
