import { Link } from 'react-router-dom';

interface IToDoCountMsgProps {
  count: number,
}

export default function ToDoCountMsg({
  count,
}: IToDoCountMsgProps) {
  return (
    <>
      <p>{`Количество актуальных задач: ${count}`}</p>
      <Link to="/tasks" style={{ textDecoration: 'none', color: 'rgb(76, 110, 245)' }}>Перейти к задачам</Link>
    </>
  );
}
