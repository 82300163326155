import { Card, Stack, Group, ActionIcon, Spoiler, Text, Tooltip, Popover } from '@mantine/core';
import { FaExternalLinkAlt, FaPen } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useStores } from '../../../App';
import { ISaleShortViewModel } from '../../../clientApi/SaleApi/saleApiTypes';
import { getCustomerFullName } from '../../../utils/stringUtils';
import PhoneLink from '../../_Shared/PhoneLink';

interface IKanbanSalesCardProps {
  item: ISaleShortViewModel,
}

export default function KanbanSalesCard({
  item,
}: IKanbanSalesCardProps) {
  const { profileStore: { profilePermissions: { Sales: salesPermission } } } = useStores();

  return (
    <Card
      style={{ width: '280px' }}
      shadow="sm"
      radius="md"
      withBorder
      p={8}
    >
      <Stack spacing={4}>
        <Group position="apart" spacing={4} noWrap align="flex-start">
          <Text>
            <Text span size="xs">Менеджер: </Text>
            {item.manager.fullName}
          </Text>
          <Tooltip.Group openDelay={500} closeDelay={100}>
            <Group spacing={4} noWrap>
              <Tooltip withArrow label="Подробнее">
                <Link to={`/sales/${item.id}`} style={{ textDecoration: 'none' }}>
                  <ActionIcon color="blue" size={24}>
                    <FaExternalLinkAlt size={16} />
                  </ActionIcon>
                </Link>
              </Tooltip>
              {salesPermission === 'read-and-edit' && (
                <Tooltip withArrow label="Редактировать">
                  <Link to={`/sales/${item.id}/edit`} style={{ textDecoration: 'none' }}>
                    <ActionIcon color="blue" size={24}>
                      <FaPen size={16} />
                    </ActionIcon>
                  </Link>
                </Tooltip>
              )}
            </Group>
          </Tooltip.Group>
        </Group>
        <Text>
          <Text span size="xs">Клиент: </Text>
          {getCustomerFullName(item.customer)}
        </Text>
        <Text>
          <Text span size="xs">Телефон: </Text>
          <PhoneLink phone={item.customer.phone} />
        </Text>
        <Text color="gray.7" align="center" weight={700}>Товары</Text>
        <Spoiler maxHeight={65} showLabel="Развернуть" hideLabel="Свернуть">
          {item.soldProducts.map((it) => (
            <Stack key={it.id} spacing={0} style={{ borderBottom: '1px rgb(233, 236, 239) solid', borderTop: '1px rgb(233, 236, 239) solid' }}>
              <Popover offset={1} width="target" withArrow shadow="md">
                <Popover.Target>
                  <Text lineClamp={1} size="sm">{it.productName}</Text>
                </Popover.Target>
                <Popover.Dropdown px={6} py={4}>
                  <Text size="xs">{it.productName}</Text>
                </Popover.Dropdown>
              </Popover>
              <Group position="apart" noWrap>
                <Text>
                  <Text span size="xs">Кол-во: </Text>
                  {it.quantity}
                </Text>
                <Text>
                  <Text span size="xs">Цена: </Text>
                  {`${it.salePriceByn.toFixed(2)}p`}
                </Text>
              </Group>
            </Stack>
          ))}
        </Spoiler>
      </Stack>
    </Card>
  );
}
