import { Select } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { useStores } from '../../../../App';
import { ISendDeliveryAddForm } from '../../../../clientApi/SaleApi/saleApiTypes';
import { requiredMsg } from '../../../../const/const';

interface IDeliveryTypeInputProps {
  required?: boolean,
}

interface IDeliveryInput {
  delivery: ISendDeliveryAddForm,
}

function DeliveryTypeInput({
  required,
}: IDeliveryTypeInputProps) {
  const { deliveryTypeLookupStore: { selectItems } } = useStores();

  const {
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<IDeliveryInput>();

  return (
    <Controller
      name="delivery.deliveryTypeId"
      control={control}
      rules={{ required: required && requiredMsg }}
      render={({ field: { value, onChange } }) => (
        <Select
          value={value ? value.toString() : null}
          onChange={(val) => {
            if (val !== null) {
              const label = selectItems.find((it) => it.value === val)?.label as string;
              if (label.toLowerCase().includes('самовывоз')) {
                setValue('delivery.priceForCustomer', 0);
                setValue('delivery.primaryPrice', 0);
                clearErrors(['delivery.priceForCustomer', 'delivery.primaryPrice']);
              }
            }
            onChange(val ? +val : null);
          }}
          clearable
          data={selectItems}
          label="Способ доставки"
          withAsterisk={required}
          error={errors.delivery?.deliveryTypeId?.message}
        />
      )}
    />
  );
}

DeliveryTypeInput.defaultProps = {
  required: true,
};

export default observer(DeliveryTypeInput);
