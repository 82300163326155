import { Group, Stack, Text } from '@mantine/core';
import CopyButtonWithIcon from '../CopyButtonWithIcon';

interface ErrorMsgProps {
  message: string,
  data: string,
}

export default function ErrorMsg({
  message,
  data,
}: ErrorMsgProps) {
  return (
    <Stack spacing={2}>
      <Text>{message}</Text>
      <Group noWrap spacing={4}>
        <Text size="xs" color="gray.5">Информация об ошибке</Text>
        <CopyButtonWithIcon copyValue={data} />
      </Group>
    </Stack>
  );
}
