/* eslint-disable no-unused-vars */
import { Group, Text, Tooltip } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { observer } from 'mobx-react';
import { useStores } from '../../App';
import { IReceipt } from '../../clientApi/ReceiptsApi/receiptsApiTypes';
import { DateAndTime } from '../_Shared/DateViewComponents/DateAndTime';
import TableReceiptItems from './TableReceiptItems';
import UpdateExchangeRateBtn from './UpdateExchangeRateBtn';
import UpdateReceiptsBtn from './UpdateReceipts/UpdateReceiptsBtn';

interface TableReceiptsProps {
  items: IReceipt[],
  isLoading: boolean,
  page: number,
  pageSize: number,
  totalItemsCount: number,
  onPageChange: (page: number) => void,
  fetchItems: () => void,
  expandedRecordIds: number[],
  setExpandedRecordIds: React.Dispatch<React.SetStateAction<number[]>>,
}

function TableReceipts({
  items,
  isLoading,
  page,
  totalItemsCount,
  pageSize,
  onPageChange,
  fetchItems,
  expandedRecordIds,
  setExpandedRecordIds,
}: TableReceiptsProps) {
  const { profileStore: { profilePermissions: { Receipts: receiptsPermission } } } = useStores();

  function getCommentElement(comment: string | undefined) {
    return comment === undefined || comment === null || comment.length < 25
      ? <p className="overflow-ellipsis-text">{comment}</p>
      : (
        <Tooltip multiline withArrow label={comment}>
          <p className="overflow-ellipsis-text">{comment}</p>
        </Tooltip>
      );
  }

  return (
    <DataTable<IReceipt>
      noRecordsText="По данному запросу записей не найдено"
      fetching={isLoading}
      records={items}
      columns={[
        {
          accessor: 'receiptDate',
          title: 'Дата',
          render: ({ createdDateUtc }) => <DateAndTime date={createdDateUtc} isOneLine />,
        },
        { accessor: 'supplierName', title: 'Поставщик' },
        {
          accessor: 'exchangeRate',
          title: 'USD/BYN',
          render: (item) => (
            <Group spacing={5}>
              <Text>{item.exchangeRate}</Text>
              {receiptsPermission === 'read-and-edit' && (
                <UpdateExchangeRateBtn
                  exchangeRate={item.exchangeRate}
                  id={item.id}
                  fetchItems={fetchItems}
                />
              )}
            </Group>
          ),
        },
        {
          accessor: 'creatorUser.fullName',
          title: 'Принял',
        },
        {
          accessor: 'comment',
          title: 'Комментарий',
          width: 200,
          render: (record) => getCommentElement(record.comment),
        },
        {
          accessor: 'updateReceiptBtn',
          title: '',
          hidden: receiptsPermission !== 'read-and-edit',
          render: (item) => (
            <UpdateReceiptsBtn
              fetchItems={fetchItems}
              item={item}
              id={item.id}
            />
          ),
        },
      ]}
      rowExpansion={{
        allowMultiple: true,
        expanded: {
          recordIds: expandedRecordIds,
          onRecordIdsChange: setExpandedRecordIds,
        },
        collapseProps: {
          animateOpacity: false,
        },
        content: TableReceiptItems,
      }}
      totalRecords={totalItemsCount}
      recordsPerPage={pageSize}
      page={page}
      onPageChange={onPageChange}
      paginationText={({ from, to, totalRecords }) => `Записи ${from} - ${to} из ${totalRecords}`}
    />
  );
}

export default observer(TableReceipts);
