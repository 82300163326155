import { observer } from 'mobx-react';
import { useStores } from '../../../App';
import { deliveryTypeFormInfo } from '../const';
import LookupContent from './LookupContent/LookupContent';

function DeliveryTypeLookup() {
  const { deliveryTypeLookupStore: store } = useStores();

  return (
    <LookupContent
      title="Способы доставки"
      formInfo={deliveryTypeFormInfo}
      isLoading={store.isLoading}
      isFormLoading={store.isFormLoading}
      items={store.items}
      fetchAddItem={store.postDeliveryTypeLookup}
      fetchChangeItem={store.putDeliveryTypeLookup}
      fetchDeleteItem={store.deleteDeliveryTypeLookup}
      fetchItems={store.getDeliveryTypeLookup}
    />
  );
}

export default observer(DeliveryTypeLookup);
