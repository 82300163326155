import { ActionIcon, Text } from '@mantine/core';

interface InputClearCrossProps {
  action: () => void,
}

export default function InputClearCross({
  action,
}: InputClearCrossProps) {
  return (
    <ActionIcon onClick={action} style={{ background: 'none' }}>
      <Text color="gray.5" size="lg">
        &times;
      </Text>
    </ActionIcon>
  );
}
