import { showNotification } from '@mantine/notifications';
import { makeAutoObservable } from 'mobx';
import { audioElement, notificationsData, todoCountRequestIntervalMs } from './const';
import RootStore from './RootStore';

export default class TaskTicketsStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  intervalId = 0;

  alertActualTaskToDoCount = async () => {
    const [data] = await this.rootStore.clientApi.taskTicketsApi.getTaskTicketsToDoCount();
    if (data !== null) {
      const { count } = data;
      if (count > 0) {
        showNotification(notificationsData.toDoCountMsg(count));
        audioElement.play();
      }
    }
  };

  startToDoCountInterval = () => {
    this.intervalId = +setInterval(this.alertActualTaskToDoCount, todoCountRequestIntervalMs);
    this.alertActualTaskToDoCount();
  };

  stopToDoCountInterval = () => {
    clearInterval(this.intervalId);
  };
}
