import { ActionIcon, createStyles, Navbar, ScrollArea } from '@mantine/core';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useStores } from '../../../App';
import LinksGroup from './LinksGroup/LinksGroup';
import Logo from './Logo/Logo';
import UserMenu from './UserMenu/UserMenu';

export const transitionDelay = '0.3s';

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
    transition: transitionDelay,
    overflow: 'hidden',
  },

  header: {
    minWidth: 'max-content',
    transition: transitionDelay,
    paddingBottom: theme.spacing.md,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
  headerIsClose: {
    marginLeft: -theme.spacing.md,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    transition: transitionDelay,
  },
  linksIsClose: {
    minWidth: 'max-content',
  },

  linksInner: {
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
  },

  footer: {
    marginLeft: -theme.spacing.md / 2,
    marginRight: -theme.spacing.md / 2,
    marginBottom: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  arrow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  arrowIcon: {
    transition: transitionDelay,
  },
  arrowIconIsClose: {
    transform: 'rotate(180deg)',
    marginRight: '-6px',
  },
}));

function NavBar() {
  const { classes, cx } = useStyles();
  const { profileStore: { navLinksData } } = useStores();

  const [isNavbarOpen, setIsNavbarOpen] = useState(true);

  const links = navLinksData.map((item) => (
    <LinksGroup
      icon={item.icon}
      label={item.label}
      key={item.label}
      links={item.links}
      noGroupLink={item.link}
      isNavbarOpen={isNavbarOpen}
    />
  ));

  return (
    <Navbar
      zIndex={10}
      height="100%"
      width={{ sm: isNavbarOpen ? 250 : 50 }}
      p="md"
      className={classes.navbar}
    >
      <Navbar.Section className={cx(classes.header, { [classes.headerIsClose]: !isNavbarOpen })}>
        <Logo isNavbarOpen={isNavbarOpen} />
      </Navbar.Section>

      <Navbar.Section
        grow
        className={cx(classes.links, { [classes.linksIsClose]: !isNavbarOpen })}
        component={ScrollArea}
      >
        <div className={classes.linksInner}>{links}</div>
      </Navbar.Section>

      <Navbar.Section className={classes.arrow}>
        <ActionIcon
          variant="subtle"
          color="blue"
          onClick={() => setIsNavbarOpen((s) => !s)}
          className={cx(classes.arrowIcon, { [classes.arrowIconIsClose]: !isNavbarOpen })}
        >
          <FaArrowLeft />
        </ActionIcon>
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <UserMenu isNavbarOpen={isNavbarOpen} />
      </Navbar.Section>
    </Navbar>
  );
}

export default observer(NavBar);
