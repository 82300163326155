import { PasswordInput, Stack } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { IProfileNewPasswordBody } from '../../../../clientApi/ProfileApi/profileApiTypes';
import { requiredMsg } from '../../../../const/const';
import { checkNewPass } from '../../utils';

function ChangeProfilePasswordFormContent() {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<IProfileNewPasswordBody>();

  return (
    <Stack>
      <Controller
        name="oldPassword"
        control={control}
        rules={{ required: requiredMsg }}
        render={({ field: { value, onChange } }) => (
          <PasswordInput
            value={value || ''}
            onChange={({ currentTarget: { value: val } }) => onChange(val || null)}
            label="Введите текущий пароль"
            withAsterisk
            error={errors.oldPassword?.message}
          />
        )}
      />
      <Controller
        name="newPassword"
        control={control}
        rules={{ required: requiredMsg }}
        render={({ field: { value, onChange } }) => (
          <PasswordInput
            value={value || ''}
            onChange={({ currentTarget: { value: val } }) => onChange(val || null)}
            label="Введите новый пароль"
            withAsterisk
            error={errors.newPassword?.message}
          />
        )}
      />
      <Controller
        name="repeatNewPassword"
        control={control}
        rules={{
          required: requiredMsg,
          validate: (v) => checkNewPass(v, getValues('newPassword')),
        }}
        render={({ field: { value, onChange } }) => (
          <PasswordInput
            value={value || ''}
            onChange={({ currentTarget: { value: val } }) => onChange(val || null)}
            label="Повторите пароль"
            withAsterisk
            error={errors.repeatNewPassword?.message}
          />
        )}
      />
    </Stack>
  );
}

export default observer(ChangeProfilePasswordFormContent);
