import { TextInput } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { requiredMsg } from '../../../../const/const';
import InputClearCross from '../../InputClearCross';

interface IDeliveryTrackNumberInputProps {
  required?: boolean,
}

interface IDeliveryTrackNumberInput {
  delivery: {
    trackNumber: string | null,
  },
}

function DeliveryTrackNumberInput({
  required,
}: IDeliveryTrackNumberInputProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<IDeliveryTrackNumberInput>();

  return (
    <Controller
      name="delivery.trackNumber"
      control={control}
      rules={{ required: required && requiredMsg }}
      render={({ field: { value, onChange } }) => (
        <TextInput
          value={value || ''}
          onChange={({ currentTarget: { value: val } }) => onChange(val || null)}
          label="Трек номер"
          withAsterisk={required}
          error={errors.delivery?.trackNumber?.message}
          rightSection={<InputClearCross action={() => onChange(null)} />}
        />
      )}
    />
  );
}

DeliveryTrackNumberInput.defaultProps = {
  required: true,
};

export default observer(DeliveryTrackNumberInput);
