import { Button, Container, Group, Modal, Space, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { FaExclamationTriangle, FaPlus, FaQuestionCircle } from 'react-icons/fa';
import AddReceiptsForm from './AddReceiptsForm';
import XlsxInput, { IExcelFileDataItem } from './XlsxInput';
import XlsxInputExampleTable from './XlsxInputExampleTable';

interface IAddReceiptsBtnProps {
  fetchItems: () => Promise<void>,
}

export default function AddReceiptsBtn({
  fetchItems,
}: IAddReceiptsBtnProps) {
  const [opened, setOpened] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isBatchSearchLoading, setIsBatchSearchLoading] = useState(false);

  const [excelFileData, setExcelFileData] = useState<IExcelFileDataItem[]>([]);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setIsConfirmOpen(true)}
        size="lg"
        styles={{
          modal: {
            maxHeight: '100%',
          },
          body: {
            maxHeight: 'calc(100vh - 185px)',
            display: 'flex',
          },
        }}
        title={(
          <Group spacing={8}>
            <Text align="center" weight="bold">
              Добавить приход
            </Text>
            <Tooltip
              label={<XlsxInputExampleTable />}
              withArrow
              openDelay={500}
              closeDelay={100}
              styles={{ tooltip: { background: 'white', boxShadow: '0 0 10px -3px gray' } }}
            >
              <ThemeIcon radius="xl" size="sm">
                <FaQuestionCircle />
              </ThemeIcon>
            </Tooltip>
            <XlsxInput
              setExcelFileData={setExcelFileData}
              isBatchSearchLoading={isBatchSearchLoading}
            />
          </Group>
        )}
      >
        <AddReceiptsForm
          setOpened={setOpened}
          fetchItems={fetchItems}
          excelFileData={excelFileData}
          setIsConfirmOpen={setIsConfirmOpen}
          setExcelFileData={setExcelFileData}
          setIsBatchSearchLoading={setIsBatchSearchLoading}
        />
      </Modal>
      <Modal
        opened={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        title={(
          <Text align="center" weight="bold">
            Подтверждение
          </Text>
        )}
      >
        <Container>
          <Group spacing={20} noWrap>
            <FaExclamationTriangle size={40} color="orange" />
            <Stack spacing={4}>
              <Text>При закрытии формы введённые данные не сохранятся!</Text>
              <Text>Вы уверены что хотите закрыть форму?</Text>
            </Stack>
          </Group>
        </Container>
        <Space h="xl" />
        <Container size="xs" px="xs">
          <Group position="right">
            <Button variant="subtle" onClick={() => setIsConfirmOpen(false)}>Отмена</Button>
            <Button
              onClick={() => {
                setExcelFileData([]);
                setIsConfirmOpen(false);
                setOpened(false);
              }}
            >
              Да
            </Button>
          </Group>
        </Container>
      </Modal>
      <Button variant="subtle" leftIcon={<FaPlus />} onClick={() => setOpened(true)}>
        Добавить
      </Button>
    </>
  );
}
