import { addressSales, addressSalesWithId, addressSalesAuditWithId, addressSalesExportCheques, addressSalesCustomers, addressSaleByIdUpdateCommonProperties } from '../../const/const';
import { getFileNameContentForPrintingCheck, getFileName } from '../../const/utils';
import HttpClient from '../HttpClient';
import { ICreateSaleAnswer, ICreateSaleBody, IGetCustomersByQueryResponse, IGetSalesCustomersParams, IGetSalesExportChequesParams, IGetSalesParams, ISaleAudits, ISaleByID, ISalesResponseWithPagination, IUpdateSaleBody, IUpdateSaleCommonPropertiesRequest } from './saleApiTypes';

export default class SaleApi {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getSales(params: IGetSalesParams) {
    const data = await this
      .httpClient
      .Get<ISalesResponseWithPagination>(addressSales, { ...params });
    return data;
  }

  async getSaleById(id: number) {
    const data = await this
      .httpClient
      .Get<ISaleByID>(addressSalesWithId(id));
    return data[0];
  }

  async getSalesCustomers(params: IGetSalesCustomersParams) {
    const data = await this
      .httpClient
      .Get<IGetCustomersByQueryResponse>(addressSalesCustomers, { ...params });
    return data;
  }

  async getSaleAudit(id: number) {
    const data = await this
      .httpClient
      .Get<ISaleAudits>(addressSalesAuditWithId(id));
    return data[0];
  }

  async postSale(body: ICreateSaleBody) {
    const data = await this
      .httpClient
      .Post<ICreateSaleAnswer, ICreateSaleBody>(
        addressSales,
        body,
      );
    return data;
  }

  async putSale(body: IUpdateSaleBody, id: number) {
    const data = await this
      .httpClient
      .Put<null, IUpdateSaleBody>(
        addressSalesWithId(id),
        body,
      );
    return data;
  }

  async getSalesExportCheques(params: IGetSalesExportChequesParams, deliveryType?: string | null) {
    const data = await this
      .httpClient
      .Get<null>(
        addressSalesExportCheques,
        { ...params },
        getFileName(
          'Печать чеков',
          getFileNameContentForPrintingCheck(params.FilterByDate, deliveryType),
          'pdf',
        ),
      );
    return data;
  }

  async putSaleUpdateCommonProperties(body: IUpdateSaleCommonPropertiesRequest, id: number) {
    const data = await this
      .httpClient
      .Put<null, IUpdateSaleCommonPropertiesRequest>(
        addressSaleByIdUpdateCommonProperties(id),
        body,
        true,
        false,
      );
    return data;
  }
}
