import ClientApi from '../clientApi/ApiClient';
import DeliveriesForCurrentUserStore from './deliveriesForCurrentUserStore';
import DeliveriesStore from './deliveriesStore';
import LoginStore from './loginStore';
import DeliveryTypeLookupStore from './lookupStores/deliveryTypeLookupStore';
import LookupsStore from './lookupStores/lookupsStore';
import PaymentMethodLookupStore from './lookupStores/paymentMethodLookupStore';
import ProductStorageLookupStore from './lookupStores/productStorageLookupStore';
import ProductSupplierLookupStore from './lookupStores/productSupplierLookupStore';
import SaleSourceLookupStore from './lookupStores/saleSourceLookupStore';
import SpendingCategoryLookupStore from './lookupStores/spendingCategoryLookupStore';
import ProfileStore from './profileStore';
import TaskTicketsStore from './taskTicketsStore';
import UsersStore from './usersStore';

export default class RootStore {
  profileStore: ProfileStore;

  clientApi: ClientApi;

  loginStore: LoginStore;

  lookupsStore: LookupsStore;

  deliveryTypeLookupStore: DeliveryTypeLookupStore;

  paymentMethodLookupStore: PaymentMethodLookupStore;

  productStorageLookupStore: ProductStorageLookupStore;

  productSupplierLookupStore: ProductSupplierLookupStore;

  saleSourceLookupStore: SaleSourceLookupStore;

  spendingCategoryLookupStore: SpendingCategoryLookupStore;

  usersStore: UsersStore;

  deliveriesStore: DeliveriesStore;

  deliveriesForCurrentUserStore: DeliveriesForCurrentUserStore;

  taskTicketsStore: TaskTicketsStore;

  constructor(
    clientApi: ClientApi,
  ) {
    this.clientApi = clientApi;
    this.profileStore = new ProfileStore(this);
    this.loginStore = new LoginStore(this);
    this.lookupsStore = new LookupsStore(this);
    this.deliveryTypeLookupStore = new DeliveryTypeLookupStore(this);
    this.paymentMethodLookupStore = new PaymentMethodLookupStore(this);
    this.productStorageLookupStore = new ProductStorageLookupStore(this);
    this.productSupplierLookupStore = new ProductSupplierLookupStore(this);
    this.saleSourceLookupStore = new SaleSourceLookupStore(this);
    this.spendingCategoryLookupStore = new SpendingCategoryLookupStore(this);
    this.usersStore = new UsersStore(this);
    this.deliveriesStore = new DeliveriesStore(this);
    this.deliveriesForCurrentUserStore = new DeliveriesForCurrentUserStore(this);
    this.taskTicketsStore = new TaskTicketsStore(this);
  }
}
