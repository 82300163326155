import HttpClient from '../HttpClient';
import { addressDeliveries, addressDeliveriesAssignCourier, addressDeliveriesForCurrentUser, addressDeliveriesUpdateComment, addressDeliveriesUpdateStatus } from '../../const/const';
import { IDeliveries, IGetDeliveriesParams, ISendDeliveryComment, ISendDeliveryCourierID, ISendDeliveryStatusID } from './deliveriesApiTypes';

export default class DeliveriesApi {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getDeliveries(params: IGetDeliveriesParams) {
    const data = await this.httpClient.Get<IDeliveries>(addressDeliveries, { ...params });
    return data;
  }

  async getDeliveriesForCurrentUser() {
    const data = await this.httpClient.Get<IDeliveries>(addressDeliveriesForCurrentUser);
    return data;
  }

  async putAssignCourierDelivery(body: ISendDeliveryCourierID, id: number) {
    const data = await this
      .httpClient
      .Put<null, ISendDeliveryCourierID>(addressDeliveriesAssignCourier(id), body);
    return data;
  }

  async putUpdateCommentDelivery(body: ISendDeliveryComment, id: number) {
    const data = await this
      .httpClient
      .Put<null, ISendDeliveryComment>(addressDeliveriesUpdateComment(id), body);
    return data;
  }

  async putUpdateStatusDelivery(body: ISendDeliveryStatusID, id: number) {
    const data = await this
      .httpClient
      .Put<null, ISendDeliveryStatusID>(addressDeliveriesUpdateStatus(id), body);
    return data;
  }
}
