import { Modal, ActionIcon, Text, Tooltip } from '@mantine/core';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { FaPen } from 'react-icons/fa';
import { useStores } from '../../App';
import { IUpdateExchangeRateReceiptBody } from '../../clientApi/ReceiptsApi/receiptsApiTypes';
import FormWrapper from '../_Shared/FormElements/FormWrapper';
import UpdateExchangeRateFormContent from './UpdateExchangeRateFormContent';

interface IUpdateExchangeRateBtnProps {
  exchangeRate: number,
  id: number,
  fetchItems: () => void,
}

function UpdateExchangeRateBtn({
  exchangeRate,
  id,
  fetchItems,
}: IUpdateExchangeRateBtnProps) {
  const { clientApi: { receiptsApi } } = useStores();

  const [opened, setOpened] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const defaultValues = {
    exchangeRate,
  };

  async function fetchData(data: IUpdateExchangeRateReceiptBody) {
    setIsFormLoading(true);
    const response = await receiptsApi.putUpdateExchangeRate(data, id);
    setIsFormLoading(false);
    return response;
  }

  function actionAfterSuccess() {
    setOpened(false);
    fetchItems();
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={(
          <Text align="center" weight="bold">
            Обновить курс валют
          </Text>
        )}
      >
        <FormWrapper<IUpdateExchangeRateReceiptBody, null>
          isResetAfterClose
          defaultValues={defaultValues}
          isFormLoading={isFormLoading}
          fetchData={fetchData}
          actionAfterSuccess={actionAfterSuccess}
          onCancelAction={() => setOpened(false)}
        >
          <UpdateExchangeRateFormContent />
        </FormWrapper>
      </Modal>
      <Tooltip withArrow label="Изменить курс валют" openDelay={500} closeDelay={100}>
        <ActionIcon color="blue" onClick={() => setOpened(true)}>
          <FaPen size={15} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}

export default observer(UpdateExchangeRateBtn);
