import { cleanNotifications } from '@mantine/notifications';
import { makeAutoObservable } from 'mobx';
import { IPostLoginBody } from '../clientApi/LoginApi/loginApiTypes';
import { defaultProfileData } from './const';
import RootStore from './RootStore';

export default class LoginStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  isLoading = false;

  postLogin = async (body: IPostLoginBody) => {
    this.isLoading = true;
    const data = await this.rootStore.clientApi.loginApi.postLogin(body);
    if (data[1] === null) {
      this.rootStore.clientApi.httpClient.navigate('/');
    }
    this.isLoading = false;
    return data[1];
  };

  postLogout = async () => {
    this.isLoading = true;
    this.rootStore.taskTicketsStore.stopToDoCountInterval();
    cleanNotifications();
    await this.rootStore.clientApi.loginApi.postLogout();
    this.rootStore.profileStore.profileData = defaultProfileData;
    this.rootStore.clientApi.httpClient.navigate('/login');
    this.isLoading = false;
  };
}
