import { Button, Stack, Text } from '@mantine/core';
import { DateRangePickerValue, RangeCalendar } from '@mantine/dates';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { FaFileExport } from 'react-icons/fa';
import { useStores } from '../../App';
import PageTitle from '../_Shared/PageTitle';
import { getDateStringForQueryParams, getFileNameContentByDateRange, styleDatePickerActualDay } from '../../const/utils';

function Analytics() {
  const { clientApi: { analyticsApi } } = useStores();

  const [isLoading, setIsLoading] = useState(false);
  const [FilterRangeDate, setFilterRangeDate] = useState<(string | null)[]>([null, null]);

  async function fetchData() {
    setIsLoading(true);
    await analyticsApi.getAnalyticsExportSales({
      FilterStartDate: FilterRangeDate[0],
      FilterEndDate: FilterRangeDate[1] === null
        ? getDateStringForQueryParams(new Date())
        : FilterRangeDate[1],
    });
    setIsLoading(false);
  }

  return (
    <>
      <PageTitle text="Аналитика" />
      <Stack align="center">
        <RangeCalendar
          allowSingleDateInRange
          onChange={(date) => setFilterRangeDate(date
            .map((it) => (it ? getDateStringForQueryParams(it) : null)))}
          value={FilterRangeDate
            .map((it) => (it === null ? null : new Date(it))) as DateRangePickerValue}
          dayStyle={styleDatePickerActualDay}
        />
        <Text>{getFileNameContentByDateRange(FilterRangeDate[0], FilterRangeDate[1])}</Text>
        <Button
          loading={isLoading}
          leftIcon={<FaFileExport />}
          onClick={fetchData}
        >
          Экспортировать
        </Button>
      </Stack>
    </>
  );
}

export default observer(Analytics);
