import { observer } from 'mobx-react';
import { useStores } from '../../../App';
import { productSupplierFormInfo } from '../const';
import LookupContent from './LookupContent/LookupContent';

function ProductSupplierLookup() {
  const { productSupplierLookupStore: store } = useStores();

  return (
    <LookupContent
      title="Поставщики"
      formInfo={productSupplierFormInfo}
      isLoading={store.isLoading}
      isFormLoading={store.isFormLoading}
      items={store.items}
      fetchAddItem={store.postProductSupplierLookup}
      fetchChangeItem={store.putProductSupplierLookup}
      fetchDeleteItem={store.deleteProductSupplierLookup}
      fetchItems={store.getProductSupplierLookup}
    />
  );
}

export default observer(ProductSupplierLookup);
