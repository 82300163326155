/* eslint-disable no-unused-vars */
import { Box, Group, Tooltip } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { useStores } from '../../../../../App';
import { TErrors } from '../../../../../clientApi/apiTypes';
import { ISendSimpleLookupBody, ISimpleLookupItem } from '../../../../../clientApi/LookupsApi/lookupsApiTypes';
import { DateAndTime } from '../../../../_Shared/DateViewComponents/DateAndTime';
import { IFormInfo } from '../../../types';
import AddLookupBtn from './TableLookupActions/AddLookupBtn';
import DeleteLookupBtn from './TableLookupActions/DeleteLookupBtn';
import UpdateLookupBtn from './TableLookupActions/UpdateLookupBtn';

interface ITableLookupProps {
  formInfo: IFormInfo;
  isLoading: boolean;
  isFormLoading: boolean;
  items: ISimpleLookupItem[];
  fetchAddItem: (body: ISendSimpleLookupBody) => Promise<TErrors | null | false>;
  fetchChangeItem: (body: ISendSimpleLookupBody,
    id: number) => Promise<TErrors | null | false>;
  fetchDeleteItem: (id: number) => Promise<void>;
  fetchItems: () => Promise<void>,
}

export default function TableLookup({
  isLoading,
  isFormLoading,
  items,
  fetchAddItem,
  fetchChangeItem,
  fetchDeleteItem,
  fetchItems,
  formInfo,
}: ITableLookupProps) {
  const { profileStore: { profilePermissions: { Lookups: lookupsPermission } } } = useStores();
  return (
    <>
      {lookupsPermission !== 'read-only' && (
        <AddLookupBtn
          isFormLoading={isFormLoading}
          addForm={formInfo.addForm}
          fetchAddItem={fetchAddItem}
          fetchItems={fetchItems}
        />
      )}
      <Box sx={{ height: '100%', overflow: 'hidden' }}>
        <DataTable<ISimpleLookupItem>
          noRecordsText="По данному запросу записей не найдено"
          highlightOnHover
          fetching={isLoading}
          records={items}
          columns={[
            {
              accessor: 'id',
              title: 'ID',
            },
            {
              accessor: 'label',
              title: 'Название',
            },
            {
              accessor: 'createdDateUtc',
              title: 'Дата создания',
              render: (i) => <DateAndTime date={i.createdDateUtc} isOneLine />,
            },
            {
              accessor: 'creatorUser',
              title: 'Создал',
              render: (i) => i.creatorUser.fullName,
            },
            {
              accessor: 'updateLookupBtn',
              title: '',
              textAlignment: 'right',
              hidden: lookupsPermission !== 'read-and-edit',
              render: (i) => (
                <Tooltip.Group openDelay={500} closeDelay={100}>
                  <Group spacing={4} position="right" noWrap>
                    <UpdateLookupBtn
                      id={i.id}
                      currentLabel={i.label}
                      updateForm={formInfo.updateForm}
                      fetchChangeItem={fetchChangeItem}
                      isFormLoading={isFormLoading}
                      fetchItems={fetchItems}
                    />
                    <DeleteLookupBtn
                      id={i.id}
                      currentLabel={i.label}
                      deleteForm={formInfo.deleteForm}
                      fetchDeleteItem={fetchDeleteItem}
                      isFormLoading={isFormLoading}
                      fetchItems={fetchItems}
                    />
                  </Group>
                </Tooltip.Group>
              ),
            },
          ]}
        />
      </Box>
    </>
  );
}
