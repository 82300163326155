import { Table } from '@mantine/core';
import { Droppable } from 'react-beautiful-dnd';
import { IDeliveriesItem } from '../../../../clientApi/DeliveriesApi/deliveriesApiTypes';
import { TProfilePermissionPage } from '../../../../clientApi/ProfileApi/profileApiTypes';
import DeliveriesForCurrentUserTableRow from './DeliveriesForCurrentUserTableRow';

interface IDeliveriesForCurrentUserTableProps {
  items: IDeliveriesItem[],
  fetchItems: () => Promise<void>,
  deliveriesPermission: TProfilePermissionPage | undefined,
}

const tableTheadStyle = (minWidth: string): React.CSSProperties => ({
  minWidth,
  whiteSpace: 'nowrap',
});

export default function DeliveriesForCurrentUserTable({
  items,
  fetchItems,
  deliveriesPermission,
}: IDeliveriesForCurrentUserTableProps) {
  return (
    <Table
      verticalSpacing={0}
      horizontalSpacing={8}
      highlightOnHover
    >
      <thead style={{ zIndex: '1', position: 'sticky', top: '0', background: 'white' }}>
        <tr>
          <th style={tableTheadStyle('185px')}>Статус заказа</th>
          <th style={tableTheadStyle('185px')}>Способ доставки</th>
          <th style={tableTheadStyle('90px')}>Дата</th>
          <th style={tableTheadStyle('150px')}>Время/Трек</th>
          <th style={tableTheadStyle('220px')}>Адрес и комментарий</th>
          <th style={tableTheadStyle('150px')}>Телефон и ФИО</th>
          <th style={tableTheadStyle('515px')}>Где лежит + [Кол-во] + Товар + Цена</th>
          <th style={tableTheadStyle('85px')}>Итого</th>
        </tr>
      </thead>
      <Droppable droppableId="deliveryDroppableId">
        {(provided) => (
          <tbody
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {items.map((it, index) => (
              <DeliveriesForCurrentUserTableRow
                it={it}
                index={index}
                deliveriesPermission={deliveriesPermission}
                fetchItems={fetchItems}
                key={it.id}
              />
            ))}
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </Table>
  );
}
