import { MantineSize, Autocomplete, Loader } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { observer } from 'mobx-react';
import { ReactNode, useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useStores } from '../../../App';
import { AutocompleteProductItem, SelectProductItemProps } from '../AutoCompleteItemComponents/AutocompleteProductItem';
import InputClearCross from '../InputClearCross';

interface IFilterByProductProps {
  label?: string,
  placeholder?: string,
  size?: MantineSize,
  icon?: ReactNode,
  setFilterValue: React.Dispatch<React.SetStateAction<number | null>>,
  setFilterLabel: React.Dispatch<React.SetStateAction<string>>,
  filterLabel: string,
}

function FilterByProduct({
  label,
  placeholder,
  size,
  icon,
  setFilterValue,
  setFilterLabel,
  filterLabel,
}: IFilterByProductProps) {
  const { clientApi: { productsApi } } = useStores();

  const [value, setValue] = useState(filterLabel);
  const [debouncedValue] = useDebouncedValue(value, 300);

  const [result, setResult] = useState<SelectProductItemProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchProducts(query: string) {
    setIsLoading(true);
    const [response] = await productsApi.getProductBySearchString({ query });
    if (response !== null) {
      setResult(response.products.map((product) => new SelectProductItemProps(product)));
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (debouncedValue.trim().length !== 0) {
      fetchProducts(debouncedValue);
    } else {
      setFilterValue(null);
    }
    setFilterLabel(debouncedValue);
  }, [debouncedValue]);

  return (
    <Autocomplete
      label={label}
      placeholder={placeholder}
      size={size}
      icon={isLoading ? <Loader size="xs" /> : icon}
      limit={10}
      maxDropdownHeight="250px"
      filter={() => true}
      data={result}
      onChange={setValue}
      onItemSubmit={(item) => setFilterValue(Number(item.key))}
      itemComponent={AutocompleteProductItem}
      value={value}
      styles={{
        dropdown: {
          minWidth: '300px',
        },
      }}
      rightSectionWidth={20}
      rightSection={(
        <InputClearCross action={() => {
          setFilterValue(null);
          setFilterLabel('');
          setValue('');
          setResult([]);
        }}
        />
      )}
    />
  );
}

FilterByProduct.defaultProps = {
  label: undefined,
  placeholder: undefined,
  size: 'xs',
  icon: <FaSearch />,
};

export default observer(FilterByProduct);
