import { NavigateFunction } from 'react-router-dom';
import AnalyticsApi from './AnalyticsApi/AnalyticsApi';
import CashboxApi from './CashboxApi/CahboxApi';
import DeliveriesApi from './DeliveriesApi/DeliveriesApi';
import HttpClient from './HttpClient';
import LoginApi from './LoginApi/LoginApi';
import LookupsApi from './LookupsApi/LookupsApi';
import ProductsApi from './ProductsApi/ProductsApi';
import ProfileApi from './ProfileApi/ProfileApi';
import ReceiptsApi from './ReceiptsApi/ReceiptsApi';
import SalariesApi from './SalariesApi/SalariesApi';
import SaleApi from './SaleApi/SaleApi';
import StorageApi from './StorageApi/StorageApi';
import TaskTicketsApi from './TaskTicketsApi/TaskTicketsApi';
import UsersApi from './UsersApi/UsersApi';

export default class ClientApi {
  httpClient: HttpClient;

  profileApi: ProfileApi;

  loginApi: LoginApi;

  lookupsApi: LookupsApi;

  usersApi: UsersApi;

  deliveriesApi: DeliveriesApi;

  saleApi: SaleApi;

  taskTicketsApi: TaskTicketsApi;

  cashboxApi: CashboxApi;

  receiptsApi: ReceiptsApi;

  productsApi: ProductsApi;

  analyticsApi: AnalyticsApi;

  storageApi: StorageApi;

  salariesApi: SalariesApi;

  constructor(
    navigate: NavigateFunction,
  ) {
    this.httpClient = new HttpClient(navigate);
    this.profileApi = new ProfileApi(this.httpClient);
    this.loginApi = new LoginApi(this.httpClient);
    this.lookupsApi = new LookupsApi(this.httpClient);
    this.usersApi = new UsersApi(this.httpClient);
    this.deliveriesApi = new DeliveriesApi(this.httpClient);
    this.saleApi = new SaleApi(this.httpClient);
    this.taskTicketsApi = new TaskTicketsApi(this.httpClient);
    this.cashboxApi = new CashboxApi(this.httpClient);
    this.receiptsApi = new ReceiptsApi(this.httpClient);
    this.productsApi = new ProductsApi(this.httpClient);
    this.analyticsApi = new AnalyticsApi(this.httpClient);
    this.storageApi = new StorageApi(this.httpClient);
    this.salariesApi = new SalariesApi(this.httpClient);
  }
}
