import { Button } from '@mantine/core';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useStores } from '../../../App';
import { ICreateTaskTicketAnswer, ICreateTaskTicketBody } from '../../../clientApi/TaskTicketsApi/taskTicketsApiTypes';
import FormWrapper from '../../_Shared/FormElements/FormWrapper';
import ChangeItemModal from '../../_Shared/Modal/ChangeItemModal';
import CreateTaskFormContent from './CreateTaskFormContent';

interface ICreateTaskFormProps {
  fetchItem: () => Promise<void>,
  saleId: number,
}

function CreateTaskForm({
  fetchItem,
  saleId,
}: ICreateTaskFormProps) {
  const defaultValues: ICreateTaskTicketBody = {
    saleId,
    header: null,
    description: null,
    priorityId: null,
    startDateTimeUtc: new Date(),
    assigneeUserId: null,
    isTaskForAll: false,
  };
  const [isOpened, setIsOpened] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const { clientApi: { taskTicketsApi } } = useStores();

  async function fetchData(data: ICreateTaskTicketBody) {
    setIsFormLoading(true);
    const response = await taskTicketsApi.postTaskTicket(data);
    setIsFormLoading(false);
    return response;
  }

  function actionAfterSuccess() {
    setIsOpened(false);
    fetchItem();
  }

  function onCancelAction() {
    setIsOpened(false);
  }

  return (
    <>
      <ChangeItemModal
        closeModal={onCancelAction}
        opened={isOpened}
        title="Добавление задачи"
      >
        <FormWrapper<ICreateTaskTicketBody, ICreateTaskTicketAnswer>
          isFormLoading={isFormLoading}
          defaultValues={defaultValues}
          withResetBtn
          fetchData={fetchData}
          actionAfterSuccess={actionAfterSuccess}
          onCancelAction={onCancelAction}
        >
          <CreateTaskFormContent />
        </FormWrapper>
      </ChangeItemModal>
      <Button onClick={() => setIsOpened(true)} variant="subtle" leftIcon={<FaPlus />}>
        Добавить
      </Button>
    </>
  );
}

export default observer(CreateTaskForm);
