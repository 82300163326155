import { ActionIcon } from '@mantine/core';
import { AiFillPrinter } from 'react-icons/ai';

interface IPrintBtnProps {
  isDisabled?: boolean,
  isLoading?: boolean,
  onClick: () => void,
}

export default function PrintBtn({
  isDisabled,
  onClick,
  isLoading,
}: IPrintBtnProps) {
  return (
    <ActionIcon
      color="blue"
      size={34}
      disabled={isDisabled}
      onClick={onClick}
      loading={isLoading}
    >
      <AiFillPrinter />
    </ActionIcon>
  );
}

PrintBtn.defaultProps = {
  isLoading: false,
  isDisabled: false,
};
