import { NavLink } from '@mantine/core';
import { FaPhoneAlt } from 'react-icons/fa';

interface IPhoneLinkProps {
  phone: string,
}

export default function PhoneLink({
  phone,
}: IPhoneLinkProps) {
  return (
    <NavLink
      label={phone}
      component="a"
      href={`tel:${phone}`}
      variant="subtle"
      active
      icon={<FaPhoneAlt size={12} />}
      p="0px 4px"
      styles={{
        icon: { marginRight: '4px' },
        root: {
          width: 'auto',
          display: 'inline-flex',
        },
      }}
      noWrap
    />
  );
}
