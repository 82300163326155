import { observer } from 'mobx-react';
import { useStores } from '../../../App';
import { productStorageFormInfo } from '../const';
import LookupContent from './LookupContent/LookupContent';

function ProductStorageLookup() {
  const { productStorageLookupStore: store } = useStores();

  return (
    <LookupContent
      title="Где лежит"
      formInfo={productStorageFormInfo}
      isLoading={store.isLoading}
      isFormLoading={store.isFormLoading}
      items={store.items}
      fetchAddItem={store.postProductStorageLookup}
      fetchChangeItem={store.putProductStorageLookup}
      fetchDeleteItem={store.deleteProductStorageLookup}
      fetchItems={store.getProductStorageLookup}
    />
  );
}

export default observer(ProductStorageLookup);
