import { Text } from '@mantine/core';
import { NullValueLabel } from '../../utils/stringUtils';

interface IBoldLabelWithTextProps {
  label: string,
  text: string | null,
}

export default function BoldLabelWithText({
  label,
  text,
}: IBoldLabelWithTextProps) {
  return (
    <Text sx={{ wordWrap: 'break-word' }}>
      <Text span size="xs" fw={700}>{`${label}: `}</Text>
      <Text span>{text ?? NullValueLabel}</Text>
    </Text>
  );
}
