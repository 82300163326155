import { Text, Group, Loader } from '@mantine/core';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import TableCashbox from './TableCashbox/TableCashbox';
import PageTitle from '../_Shared/PageTitle';
import { ICashboxItem } from '../../clientApi/CashboxApi/cashboxApiTypes';
import { useStores } from '../../App';
import AddCashboxItemBtn from './TableCashbox/AddCashboxItemBtn';
import UpdateContentBtn from '../_Shared/UpdateContentBtn';
import PaymentMethodMultiSelect from '../_Shared/Filters/PaymentMethodMultiSelect';

function Cashbox() {
  const {
    clientApi: { cashboxApi },
    profileStore: { profilePermissions: { Cashier: cashboxPermission } },
  } = useStores();

  const [items, setItems] = useState<ICashboxItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isBalanceLoading, setIsBalanceLoading] = useState(false);
  const [currentlyCashAmountByn, setCurrentlyCashAmountByn] = useState('');
  const [currentlyCashAmountUsd, setCurrentlyCashAmountUsd] = useState('');
  const [summaryNonCashForTodayMonthByn, setSummaryNonCashForTodayMonthByn] = useState('');
  const [summaryNonCashForTodayMonthUsd, setSummaryNonCashForTodayMonthUsd] = useState('');
  const [totalItems, setTotalItems] = useState(0);

  const [PageNumber, setPageNumber] = useState(1);
  const [FilterByPaymentMethodIds, setFilterByPaymentMethodIds] = useState<number[] | null>(null);

  async function fetchCashboxItems() {
    setIsLoading(true);
    const [response] = await cashboxApi.getCashboxItems({
      PageSize: 10,
      PageNumber,
      FilterByPaymentMethodIds,
    });
    if (response !== null) {
      setItems(response.items);
      setTotalItems(response.totalItemsCount);
    }
    setIsLoading(false);
  }

  async function fetchCashboxBalance() {
    setIsBalanceLoading(true);
    const [response] = await cashboxApi.getCashboxBalance();
    if (response !== null) {
      setCurrentlyCashAmountByn(response.currentlyCashAmountByn.toFixed(2));
      setCurrentlyCashAmountUsd(response.currentlyCashAmountUsd.toFixed(2));
      setSummaryNonCashForTodayMonthByn(response.summaryNonCashForTodayMonthByn.toFixed(2));
      setSummaryNonCashForTodayMonthUsd(response.summaryNonCashForTodayMonthUsd.toFixed(2));
    }
    setIsBalanceLoading(false);
  }

  function fetchItems() {
    fetchCashboxItems();
    fetchCashboxBalance();
  }

  useEffect(() => {
    fetchCashboxBalance();
  }, []);

  useEffect(() => {
    fetchCashboxItems();
  }, [PageNumber, FilterByPaymentMethodIds]);

  return (
    <>
      <PageTitle text="Касса" />
      <Group spacing={8}>
        <Text size="sm">Общая сумма (Нал):</Text>
        <Text span>{`${currentlyCashAmountByn} BYN`}</Text>
        <Text span>{`${currentlyCashAmountUsd} USD`}</Text>
        <Text size="sm" ml="xs">Сумма за день (Безнал):</Text>
        <Text span>{`${summaryNonCashForTodayMonthByn} BYN`}</Text>
        <Text span>{`${summaryNonCashForTodayMonthUsd} USD`}</Text>
        {isBalanceLoading && <Loader size="xs" />}
      </Group>
      <Group position="apart" noWrap>
        <PaymentMethodMultiSelect
          setFilterValue={setFilterByPaymentMethodIds}
          value={FilterByPaymentMethodIds}
          placeholder="Способ оплаты"
        />
        <Group spacing={4}>
          {cashboxPermission !== 'read-only' && (
            <AddCashboxItemBtn
              fetchItems={fetchItems}
            />
          )}
          <UpdateContentBtn
            action={fetchItems}
            isDisabled={isLoading}
          />
        </Group>
      </Group>
      <TableCashbox
        items={items}
        loading={isLoading}
        totalItems={totalItems}
        setPageNumber={setPageNumber}
        pageNumber={PageNumber}
        fetchItems={fetchItems}
      />
    </>
  );
}

export default observer(Cashbox);
