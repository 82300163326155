import { ActionIcon, Button, Grid, Group, SimpleGrid, Space, Stack, Text } from '@mantine/core';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { ErrorMessage } from '@hookform/error-message';
import SectionTitle from '../../_Shared/SectionTitle';
import PaymentMethodTypeInput from '../../_Shared/FormElements/lookupInputs/PaymentMethodTypeInput';
import PaymentAmountInput from '../../_Shared/FormElements/numberInputs/PaymentAmountInput';
import { IUpdateSaleBody } from '../../../clientApi/SaleApi/saleApiTypes';

export default function PaymentsSectionForm() {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<IUpdateSaleBody>();

  const { fields, append, remove } = useFieldArray({ control, name: 'payments' });
  const totalPaymentsAmount = watch('payments')
    .reduce((partialSum, it) => partialSum + (it.paymentAmount || 0), 0);

  return (
    <Stack spacing="xs" sx={{ minWidth: '300px' }}>
      <Space h={8} />
      <Grid align="center">
        <Grid.Col sm="auto" />
        <Grid.Col sm="content">
          <SectionTitle>Оплата</SectionTitle>
        </Grid.Col>
        <Grid.Col sm="auto">
          <Button
            variant="subtle"
            compact
            leftIcon={<FaPlus />}
            onClick={() => append({ paymentMethodTypeId: null, paymentAmount: null })}
          >
            Добавить
          </Button>
        </Grid.Col>
      </Grid>
      <ErrorMessage
        errors={errors}
        name="payments"
        render={(e) => <Text color="red" size="sm">{e.message}</Text>}
      />

      {fields.map((f, i) => (
        <SimpleGrid
          cols={2}
          style={{ alignItems: 'center', gridTemplateColumns: '1fr 25px', marginBottom: '10px' }}
          key={f.id}
        >
          <Group position="apart" grow align="flex-start">
            <PaymentMethodTypeInput i={i} />
            <PaymentAmountInput i={i} />
          </Group>
          <ActionIcon size={20} variant="subtle" color="red" onClick={() => remove(i)}>
            <FaTrash />
          </ActionIcon>
        </SimpleGrid>
      ))}

      <Text color="gray.7" weight="bold" size="sm" align="right">
        {`Итого: ${totalPaymentsAmount.toFixed(2)}p`}
      </Text>
    </Stack>
  );
}
