import { makeAutoObservable } from 'mobx';
import RootStore from './RootStore';
import { IProfileAvatar, IProfileData, IProfileFullName, IProfileNewPasswordBody, IUserClientSettings } from '../clientApi/ProfileApi/profileApiTypes';
import { defaultProfileData } from './const';
import { TNavLinksData, IRoutesData, IUserProfileSettings, IProfilePermissions } from './types';
import { getNavLinkData, getRoutesData } from './utils';

export default class ProfileStore {
  rootStore: RootStore;

  profileData: IProfileData;

  profilePermissions: IProfilePermissions;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.profileData = defaultProfileData;
    this.rootStore = rootStore;
    this.profilePermissions = {};
  }

  isLoading = false;

  isFormLoading = false;

  navLinksData: TNavLinksData = [];

  routesData: IRoutesData[] = [];

  userClientSettings: IUserClientSettings = {
    deliveriesOrder: [],
  };

  get index() {
    return this
      .rootStore
      .usersStore
      .usersData
      .findIndex((it) => it.id === this.profileData.userId);
  }

  getCurrentProfile = async () => {
    this.isLoading = true;
    const data = await this.rootStore.clientApi.profileApi.getCurrentProfile();
    if (data[0] !== null) {
      [this.profileData] = data;
      this.navLinksData = getNavLinkData(this.profileData.permissions);
      if (this.profileData.userClientSettings !== '') {
        this.userClientSettings.deliveriesOrder = (JSON
          .parse(this.profileData.userClientSettings) as IUserProfileSettings)
          .deliveriesOrder;
      }
      this.profileData.permissions.forEach((it) => { this.profilePermissions[it.key] = it.mode; });
    }
    this.routesData = getRoutesData(this.profileData.permissions);
    this.isLoading = false;
    return data[0];
  };

  putProfilePassword = async (body: IProfileNewPasswordBody) => {
    const data = await this.rootStore.clientApi.profileApi.putProfilePassword(body);
    return data;
  };

  putProfileFullName = async (body: IProfileFullName) => {
    const data = await this.rootStore.clientApi.profileApi.putProfileFullName(body);
    if (data[1] === null) {
      this.profileData.fullName = `${body.firstName} ${body.lastName}`;
      this.rootStore.usersStore.usersData[this.index].fullName = `${body.firstName} ${body.lastName}`;
    }
    return data;
  };

  putProfileAvatar = async (body: IProfileAvatar) => {
    const data = await this.rootStore.clientApi.profileApi.putProfileAvatar(body);
    if (data[1] === null) {
      this.profileData.avatarBase64 = body.newAvatarBase64;
      this.rootStore.usersStore.usersData[this.index].avatarBase64 = body.newAvatarBase64;
    }
    return data;
  };

  putProfileSettings = async () => {
    await this.rootStore.clientApi.profileApi.putProfileSettings(this.userClientSettings);
  };

  checkAuthorization = async () => {
    const data = await this.getCurrentProfile();
    if (data !== null) {
      this.rootStore.clientApi.httpClient.navigate('/');
    }
  };
}
