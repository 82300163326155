import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button, Container, Grid, ScrollArea } from '@mantine/core';
import { FaArrowCircleLeft } from 'react-icons/fa';
import PageTitle from '../_Shared/PageTitle';
import SaleCreateForm from './SaleEditPage/SaleCreateForm';

function SaleCreatePage() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'flex-start',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Grid align="flex-end" pb="sm">
        <Grid.Col sm="auto">
          <Link to="/sales" relative="route" style={{ textDecoration: 'none', float: 'right' }}>
            <Button variant="subtle" leftIcon={<FaArrowCircleLeft />}>
              К продажам
            </Button>
          </Link>
        </Grid.Col>
        <Grid.Col sm="content">
          <PageTitle paddingBottom={0}>Создание продажи</PageTitle>
        </Grid.Col>
      </Grid>
      <ScrollArea offsetScrollbars sx={{ width: '100%', height: '100%', display: 'flex' }}>
        <Container size={900}>
          <SaleCreateForm />
        </Container>
      </ScrollArea>
    </div>
  );
}

export default observer(SaleCreatePage);
