import { Select } from '@mantine/core';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';
import { useStores } from '../../../../App';
import { requiredMsg } from '../../../../const/const';

interface IPaymentMethodTypeInputProps {
  required?: boolean,
  i: number,
}

interface IPaymentMethodInput {
  payments: {
    paymentMethodTypeId: number | null,
  }[],
}

function PaymentMethodTypeInput({
  required,
  i,
}: IPaymentMethodTypeInputProps) {
  const { paymentMethodLookupStore: { selectItems } } = useStores();

  const {
    control,
    formState: { errors },
  } = useFormContext<IPaymentMethodInput>();

  return (
    <Controller
      name={`payments.${i}.paymentMethodTypeId`}
      control={control}
      rules={{ required: required && requiredMsg }}
      render={({ field: { value, onChange } }) => (
        <Select
          value={value ? value.toString() : null}
          onChange={(val) => onChange(val ? +val : null)}
          clearable
          data={selectItems}
          label="Способ платежа"
          withAsterisk={required}
          error={errors.payments && errors.payments[i]?.paymentMethodTypeId?.message}
        />
      )}
    />
  );
}

PaymentMethodTypeInput.defaultProps = {
  required: true,
};

export default observer(PaymentMethodTypeInput);
