import { Table, Text } from '@mantine/core';

const elements = [
  { code: 8268, manufacturer: 'Forsage', article: 'RC-2356435MGK', name: 'Удлинитель плавающий 75мм 1/2"', quantity: 1, price: 1.97, currency: 'BYN' },
  { code: 3476, manufacturer: 'Rock FORCE', article: 'RF-8044125MPB', name: 'Удлинитель ударный 125мм,1/2"', quantity: 1, price: 2.31, currency: 'BYN' },
  { code: 51260, manufacturer: 'Forsage', article: 'F-6636-6', name: 'Шприц ручной+пневматический для нагнетания густой смазки 600мл в комплекте с жестким(L-180мм) и гибким(L-330мм) наконечником ("под тубу")', quantity: 2, price: 48.00, currency: 'USD' },
  { code: 48132, manufacturer: 'FORCEKRAFT', article: 'FK-6141MPB', name: 'Набор инструментов ударных 14пр., 3/4"(6гр.)(19,22,24,27,30,32,36,41,46,50мм)в кейсе', quantity: 4, price: 12.36, currency: 'BYN' },
  { code: 8774, manufacturer: 'Forsage', article: 'F-8014750U', name: 'Вороток шарнирный 750мм 1/2"', quantity: 1, price: 13.90, currency: 'USD' },
  { code: 11876, manufacturer: 'Rock FORCE', article: 'RF-8044125', name: 'Удлинитель 125мм 1/2"', quantity: 1, price: 25, currency: 'USD' },
];

export default function XlsxInputExampleTable() {
  const rows = elements.map((element) => (
    <tr key={element.name}>
      <td>{element.manufacturer}</td>
      <td>{element.article}</td>
      <td>{element.name}</td>
      <td>{element.quantity}</td>
      <td>{element.price}</td>
      <td>{element.currency}</td>
    </tr>
  ));

  return (
    <div style={{ all: 'initial' }}>
      <Text size="sm" color="gray.5">Пример таблицы excel для загрузки данных в форму</Text>
      <Table
        fontSize="xs"
        highlightOnHover
        horizontalSpacing={5}
        striped
        verticalSpacing={5}
        withBorder
        withColumnBorders
      >
        <thead style={{ whiteSpace: 'nowrap' }}>
          <tr>
            <th>Производитель</th>
            <th>Артикул</th>
            <th>Наименование товара</th>
            <th>Кол-во</th>
            <th>Цена</th>
            <th>Валюта</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
}
