import { Checkbox, Group, Space, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useStores } from '../../App';
import { ITaskTicket } from '../../clientApi/TaskTicketsApi/taskTicketsApiTypes';
import UsersMultiSelect from '../_Shared/Filters/UsersMultiSelect';
import ChangeItemModal from '../_Shared/Modal/ChangeItemModal';
import PageTitle from '../_Shared/PageTitle';
import UpdateContentBtn from '../_Shared/UpdateContentBtn';
import TaskTicketsTable from './TaskTicketsElements/TaskTicketsTable';
import { IItems } from './types';
import UpdateTaskForm from './UpdateTaskForm/UpdateTaskForm';

function TaskTickets() {
  const { clientApi: { taskTicketsApi } } = useStores();

  const [items, setItems] = useState<IItems>({});
  const [opened, setOpened] = useState(false);
  const [idChangedItem, setIdChangedItem] = useState<ITaskTicket | null>(null);
  const [loading, setLoading] = useState(false);

  const PageNumber = 1;
  const PageSize = 1000;
  const [GetOnlyActive, setGetOnlyActive] = useState(true);
  const [GetOnlyFinished, setGetOnlyFinished] = useState(false);
  const [FilterByUserIds, setFilterByUserIds] = useState<null | number[]>(null);

  function openChangeItemModal(item: ITaskTicket) {
    setIdChangedItem(item);
    setOpened(true);
  }
  function closeModal() {
    setOpened(false);
  }

  async function fetchItems() {
    setLoading(true);
    const [response] = await taskTicketsApi.getTaskTickets({
      PageNumber,
      PageSize,
      GetOnlyActive,
      GetOnlyFinished,
      FilterByUserIds,
    });
    if (response !== null) {
      const rebuildItems = response.items.reduce<IItems>((res, it) => {
        const key = it.assigneeUser ? it.assigneeUser.userId.toString() : '0';
        const mapItem = res[key];
        if (mapItem) {
          res[key] = mapItem
            .concat(it)
            .sort((a, b) => +new Date(a.startDateTimeUtc) - +new Date(b.startDateTimeUtc));
        } else {
          res[key] = [it];
        }
        return res;
      }, {});
      setItems(rebuildItems);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchItems();
  }, [GetOnlyActive, GetOnlyFinished, FilterByUserIds]);

  return (
    <>
      <ChangeItemModal
        closeModal={closeModal}
        title="Изменение задачи"
        opened={opened}
      >
        {idChangedItem ? (
          <UpdateTaskForm
            item={idChangedItem}
            fetchItems={fetchItems}
            closeModal={closeModal}
          />
        ) : (
          <Text>Нет данных</Text>
        )}
      </ChangeItemModal>
      <PageTitle text="Задачи" />
      <Group position="apart" noWrap>
        <Group position="apart" grow>
          <Checkbox
            label="Только активные"
            checked={GetOnlyActive}
            onChange={(event) => {
              setGetOnlyActive(event.currentTarget.checked);
              setGetOnlyFinished(false);
            }}
          />
          <Checkbox
            label="Только завершенные"
            checked={GetOnlyFinished}
            onChange={(event) => {
              setGetOnlyFinished(event.currentTarget.checked);
              setGetOnlyActive(false);
            }}
          />
          <UsersMultiSelect
            placeholder="Пользователи"
            setFilterValue={setFilterByUserIds}
            value={FilterByUserIds}
          />
        </Group>
        <UpdateContentBtn
          action={fetchItems}
          isDisabled={loading}
        />
      </Group>
      {Object.keys(items).length === 0 && (
        <Text pt={20} align="center" color="gray">По данным фильтрам задач не найдено</Text>
      )}
      <Space h="xl" />
      <TaskTicketsTable
        loading={loading}
        items={items}
        openChangeItemModal={openChangeItemModal}
      />
    </>
  );
}

export default observer(TaskTickets);
