import { makeAutoObservable } from 'mobx';
import { DropResult } from 'react-beautiful-dnd';
import { IDeliveriesItem } from '../clientApi/DeliveriesApi/deliveriesApiTypes';
import RootStore from './RootStore';

export default class DeliveriesForCurrentUserStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  items: IDeliveriesItem[] = [];

  totalItemsCount = 0;

  openedItems: string[] = [];

  setOpenedItems = (value: string[]) => {
    this.openedItems = value;
  };

  get isOpenAllBtnDisable() {
    return this.totalItemsCount === this.openedItems.length;
  }

  setOpenAll = () => {
    this.openedItems = this.items.map((_, i) => i.toString());
  };

  get isCloseAllBtnDisable() {
    return this.openedItems.length === 0;
  }

  setCloseAll = () => {
    this.openedItems = [];
  };

  isLoading = false;

  isFormLoading = false;

  getDeliveriesForCurrentUser = async () => {
    this.isLoading = true;
    this.openedItems = [];
    const data = await this.rootStore.clientApi.deliveriesApi.getDeliveriesForCurrentUser();
    if (data[0] !== null) {
      this.totalItemsCount = data[0].totalItemsCount;
      this.items = data[0].items.sort((a, b) => {
        const aIndex = this.rootStore.profileStore.userClientSettings.deliveriesOrder.indexOf(a.id);
        const bIndex = this.rootStore.profileStore.userClientSettings.deliveriesOrder.indexOf(b.id);
        if (aIndex === -1) return 1;
        if (bIndex === -1) return -1;
        return aIndex > bIndex ? 1 : -1;
      });
      this.rootStore.profileStore.userClientSettings.deliveriesOrder = this.items
        .map((it) => it.id);
      this.rootStore.profileStore.putProfileSettings();
    }
    this.isLoading = false;
  };

  onDragEnd = (result: DropResult) => {
    if (result.destination) {
      const from = result.source.index;
      const to = result.destination.index;
      const [removed] = this.items.splice(from, 1);
      this.items.splice(to, 0, removed);
      this.rootStore.profileStore.userClientSettings.deliveriesOrder = this.items
        .map((it) => it.id);
      this.rootStore.profileStore.putProfileSettings();
      if (this.openedItems.includes(from.toString()) && !this.openedItems.includes(to.toString())) {
        this.openedItems[this.openedItems.indexOf(from.toString())] = to.toString();
      } else
      if (!this.openedItems.includes(from.toString()) && this.openedItems.includes(to.toString())) {
        this.openedItems[this.openedItems.indexOf(to.toString())] = from.toString();
      }
    }
  };
}
