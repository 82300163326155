import { DataTable } from 'mantine-datatable';
import { observer } from 'mobx-react';
import { Group, Stack, Text } from '@mantine/core';
import { TProfilePermissionPage } from '../../../clientApi/ProfileApi/profileApiTypes';
import { IDeliveriesItem } from '../../../clientApi/DeliveriesApi/deliveriesApiTypes';
import { FormattedDate, TimeRange } from '../../_Shared/DateViewComponents/DateAndTime';
import { getCustomerFullName } from '../../../utils/stringUtils';
import PhoneLink from '../../_Shared/PhoneLink';
import DeliveryUpdateStatusBtn from '../DeliveriesActions/DeliveryUpdateStatusBtn';
import DeliveryAssignCourierBtn from '../DeliveriesActions/DeliveryAssignCourierBtn';
import DeliveryUpdateCommentBtn from '../DeliveriesActions/DeliveryUpdateCommentBtn';
import BoldLabelWithText from '../../_Shared/BoldLabelWithText';

interface IDeliveriesTableProps {
  items: IDeliveriesItem[],
  isLoading: boolean,
  totalItemsCount: number,
  pageSize: number,
  page: number,
  onPageChange: React.Dispatch<React.SetStateAction<number>>,
  fetchItems: () => Promise<void>,
  deliveriesPermission: TProfilePermissionPage | undefined,
}

function DeliveriesTable({
  fetchItems,
  items,
  isLoading,
  totalItemsCount,
  pageSize,
  page,
  onPageChange,
  deliveriesPermission,
}: IDeliveriesTableProps) {
  return (
    <DataTable<IDeliveriesItem>
      verticalSpacing={0}
      horizontalSpacing={8}
      highlightOnHover
      noRecordsText="По данному запросу записей не найдено"
      fetching={isLoading}
      records={items}
      totalRecords={totalItemsCount}
      recordsPerPage={pageSize}
      page={page}
      striped
      onPageChange={(p) => onPageChange(p)}
      columns={[
        {
          accessor: 'status.label',
          title: 'Статус заказа',
          width: '185px',
          render: (it) => (
            <Stack spacing={0}>
              <Group spacing={2} noWrap>
                <Text>{it.status.label}</Text>
                {deliveriesPermission === 'read-and-edit' && (
                  <DeliveryUpdateStatusBtn
                    deliveryID={it.id}
                    currentStatusID={it.status.id}
                    currentStatusLabel={it.status.label}
                    fetchItems={fetchItems}
                  />
                )}
              </Group>
              <BoldLabelWithText label="M" text={it.manager.fullName} />
            </Stack>
          ),
        },
        {
          accessor: 'deliveryType.label',
          title: 'Способ доставки',
          width: '185px',
          render: (it) => (
            <Stack spacing={0}>
              <Text>{it.deliveryType.label}</Text>
              <Group spacing={0} noWrap align="flex-start">
                <BoldLabelWithText label="K" text={it.courier ? it.courier.fullName : null} />
                {deliveriesPermission === 'read-and-edit' && (
                  <DeliveryAssignCourierBtn
                    deliveryID={it.id}
                    currentCourierID={it.courier && it.courier.userId}
                    currentCourierName={it.courier && it.courier.fullName}
                    fetchItems={fetchItems}
                  />
                )}
              </Group>
            </Stack>
          ),
        },
        {
          accessor: 'startDateTimeUtc',
          width: '90px',
          title: 'Дата',
          render: (it) => (
            <FormattedDate date={it.startDateTimeUtc} />
          ),
        },
        {
          accessor: 'trackNumber',
          width: '150px',
          title: 'Время/Трек',
          render: (it) => (
            <Stack spacing={0}>
              {it.startDateTimeUtc !== null && new Date(it.startDateTimeUtc).getHours() !== 0
                && it.endDateTimeUtc !== null && new Date(it.endDateTimeUtc).getHours() !== 0
                && <TimeRange startDate={it.startDateTimeUtc} endDate={it.endDateTimeUtc} />}
              <BoldLabelWithText label="Трек" text={it.trackNumber} />
            </Stack>
          ),
        },
        {
          accessor: 'address',
          title: 'Адрес и комментарий',
          width: '220px',
          render: (it) => (
            <Stack spacing={4}>
              <BoldLabelWithText label="Адрес" text={it.address} />
              <Group spacing={2} noWrap align="flex-start">
                <BoldLabelWithText label="Коммент" text={it.comment} />
                {deliveriesPermission === 'read-and-edit' && (
                  <DeliveryUpdateCommentBtn
                    deliveryID={it.id}
                    currentComment={it.comment}
                    fetchItems={fetchItems}
                  />
                )}
              </Group>
            </Stack>
          ),
        },
        {
          accessor: 'customer.id',
          width: '150px',
          title: 'Телефон и ФИО',
          render: (it) => (
            <Stack spacing={0}>
              <Text>{getCustomerFullName(it.customer)}</Text>
              <PhoneLink phone={it.customer.phone} />
            </Stack>
          ),
        },
        {
          accessor: 'soldProducts',
          title: 'Где лежит + [Кол-во] + Товар + Цена',
          width: '515px',
          render: (it) => (
            <Stack spacing={0}>
              {it.soldProducts.map((el, i) => (
                <Group
                  spacing={8}
                  noWrap
                  key={el.id}
                  position="apart"
                  style={{ borderBottom: i === it.soldProducts.length - 1 ? 'none' : 'solid 1px #dfdfdf' }}
                >
                  <Group spacing={8} noWrap>
                    <Text sx={{ minWidth: '95px' }}>{el.storage.label}</Text>
                    <Text sx={{ minWidth: '40px' }}>{`[${el.quantity}]`}</Text>
                    <Text>{el.productName}</Text>
                  </Group>
                  <Text>{`p.${(el.salePriceByn * el.quantity).toFixed(2)}`}</Text>
                </Group>
              ))}
            </Stack>
          ),
        },
        {
          accessor: 'priceForCustomer',
          title: 'Итого',
          render: (it) => `р.${(it
            .soldProducts
            .reduce((s, a) => s + (a.salePriceByn * a.quantity), 0)
            + it.priceForCustomer).toFixed(2)}`,
        },
      ]}
    />
  );
}

export default observer(DeliveriesTable);
