import { ActionIcon, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { FaRegImage } from 'react-icons/fa';
import ChangeProfileImgModal from './ChangeProfileImgModal';

export default function ChangeProfileImgBtn() {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <ChangeProfileImgModal
        isOpened={isOpened}
        setIsOpened={setIsOpened}
      />
      <Tooltip withArrow label="Изменить изображение">
        <ActionIcon size="lg" variant="outline" onClick={() => setIsOpened(true)}>
          <FaRegImage size={24} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}
