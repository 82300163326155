import { Stack, Group, Text, createStyles } from '@mantine/core';
import { Draggable } from 'react-beautiful-dnd';
import { IDeliveriesItem } from '../../../../clientApi/DeliveriesApi/deliveriesApiTypes';
import { TProfilePermissionPage } from '../../../../clientApi/ProfileApi/profileApiTypes';
import { getCustomerFullName } from '../../../../utils/stringUtils';
import BoldLabelWithText from '../../../_Shared/BoldLabelWithText';
import { FormattedDate, TimeRange } from '../../../_Shared/DateViewComponents/DateAndTime';
import PhoneLink from '../../../_Shared/PhoneLink';
import DeliveryAssignCourierBtn from '../../DeliveriesActions/DeliveryAssignCourierBtn';
import DeliveryUpdateCommentBtn from '../../DeliveriesActions/DeliveryUpdateCommentBtn';
import DeliveryUpdateStatusBtn from '../../DeliveriesActions/DeliveryUpdateStatusBtn';

interface IDeliveriesForCurrentUserTableRowProps {
  it: IDeliveriesItem,
  fetchItems: () => Promise<void>,
  deliveriesPermission: TProfilePermissionPage | undefined,
  index: number,
}

const useStyles = createStyles(() => ({
  evenRow: {
    backgroundColor: '#f8f9fa',
  },
  oddRow: {
    backgroundColor: 'white',
  },
}));

const tableCellStyle = (minWidth: string): React.CSSProperties => ({
  minWidth,
});

export default function DeliveriesForCurrentUserTableRow({
  it,
  fetchItems,
  deliveriesPermission,
  index,
}: IDeliveriesForCurrentUserTableRowProps) {
  const { classes } = useStyles();

  return (
    <Draggable
      key={it.id}
      draggableId={it.id.toString()}
      index={index}
    >
      {(provided) => (
        <tr
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={index % 2 === 0 ? classes.evenRow : classes.oddRow}
        >
          <td style={tableCellStyle('185px')}>
            <Stack spacing={0}>
              <Group spacing={2} noWrap>
                <Text>{it.status.label}</Text>
                {deliveriesPermission === 'read-and-edit' && (
                  <DeliveryUpdateStatusBtn
                    deliveryID={it.id}
                    currentStatusID={it.status.id}
                    currentStatusLabel={it.status.label}
                    fetchItems={fetchItems}
                  />
                )}
              </Group>
              <BoldLabelWithText label="M" text={it.manager.fullName} />
            </Stack>
          </td>
          <td style={tableCellStyle('185px')}>
            <Stack spacing={0}>
              <Text>{it.deliveryType.label}</Text>
              <Group spacing={0} noWrap align="flex-start">
                <BoldLabelWithText label="K" text={it.courier ? it.courier.fullName : null} />
                {deliveriesPermission === 'read-and-edit' && (
                  <DeliveryAssignCourierBtn
                    deliveryID={it.id}
                    currentCourierID={it.courier && it.courier.userId}
                    currentCourierName={it.courier && it.courier.fullName}
                    fetchItems={fetchItems}
                  />
                )}
              </Group>
            </Stack>
          </td>
          <td style={tableCellStyle('90px')}>
            <FormattedDate date={it.startDateTimeUtc} />
          </td>
          <td style={tableCellStyle('150px')}>
            <Stack spacing={0}>
              {it.startDateTimeUtc !== null && new Date(it.startDateTimeUtc).getHours() !== 0
                && it.endDateTimeUtc !== null && new Date(it.endDateTimeUtc).getHours() !== 0
                && <TimeRange startDate={it.startDateTimeUtc} endDate={it.endDateTimeUtc} />}
              <BoldLabelWithText label="Трек" text={it.trackNumber} />
            </Stack>
          </td>
          <td style={tableCellStyle('220px')}>
            <Stack spacing={4}>
              <BoldLabelWithText label="Адрес" text={it.address} />
              <Group spacing={2} noWrap align="flex-start">
                <BoldLabelWithText label="Коммент" text={it.comment} />
                {deliveriesPermission === 'read-and-edit' && (
                  <DeliveryUpdateCommentBtn
                    deliveryID={it.id}
                    currentComment={it.comment}
                    fetchItems={fetchItems}
                  />
                )}
              </Group>
            </Stack>
          </td>
          <td style={tableCellStyle('150px')}>
            <Stack spacing={0}>
              <Text>{getCustomerFullName(it.customer)}</Text>
              <PhoneLink phone={it.customer.phone} />
            </Stack>
          </td>
          <td style={tableCellStyle('515px')}>
            <Stack spacing={0}>
              {it.soldProducts.map((el, i) => (
                <Group
                  spacing={8}
                  noWrap
                  key={el.id}
                  position="apart"
                  style={{ borderBottom: i === it.soldProducts.length - 1 ? 'none' : 'solid 1px #dfdfdf' }}
                >
                  <Group spacing={8} noWrap>
                    <Text sx={{ minWidth: '95px' }}>{el.storage.label}</Text>
                    <Text sx={{ minWidth: '40px' }}>{`[${el.quantity}]`}</Text>
                    <Text>{el.productName}</Text>
                  </Group>
                  <Text>{`p.${(el.salePriceByn * el.quantity).toFixed(2)}`}</Text>
                </Group>
              ))}
            </Stack>
          </td>
          <td style={tableCellStyle('85px')}>
            {`р.${(it
              .soldProducts
              .reduce((s, a) => s + (a.salePriceByn * a.quantity), 0)
              + it.priceForCustomer).toFixed(2)}`}
          </td>
        </tr>
      )}
    </Draggable>
  );
}
