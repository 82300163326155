import { ActionIcon, Text, Modal, Tooltip, Container } from '@mantine/core';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { FaPen } from 'react-icons/fa';
import CourierInput from '../../_Shared/FormElements/lookupInputs/CourierInput';
import FormWrapper from '../../_Shared/FormElements/FormWrapper';
import { useStores } from '../../../App';
import { ISendDeliveryCourierID } from '../../../clientApi/DeliveriesApi/deliveriesApiTypes';
import { NullValueLabel } from '../../../utils/stringUtils';

interface IDeliveryAssignCourierBtnProps {
  deliveryID: number,
  currentCourierID: number | null,
  currentCourierName: string | null,
  fetchItems: () => Promise<void>,
}

function DeliveryAssignCourierBtn({
  deliveryID,
  currentCourierID,
  currentCourierName,
  fetchItems,
}: IDeliveryAssignCourierBtnProps) {
  const { clientApi: { deliveriesApi } } = useStores();

  const [opened, setOpened] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const defaultValues = {
    courierId: currentCourierID,
  };

  async function fetchData(data: ISendDeliveryCourierID) {
    setIsFormLoading(true);
    const response = await deliveriesApi.putAssignCourierDelivery(data, deliveryID);
    setIsFormLoading(false);
    return response;
  }

  function actionAfterSuccess() {
    setOpened(false);
    fetchItems();
  }

  function onCancelAction() {
    setOpened(false);
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={(
          <Text align="center" weight="bold">
            Назначить курьера
          </Text>
        )}
      >
        <FormWrapper<ISendDeliveryCourierID, null>
          isResetAfterClose
          defaultValues={defaultValues}
          isFormLoading={isFormLoading}
          fetchData={fetchData}
          actionAfterSuccess={actionAfterSuccess}
          onCancelAction={onCancelAction}
        >
          <Text>
            {`Текущий курьер: ${currentCourierName || NullValueLabel}`}
          </Text>
          <Container>
            <CourierInput />
          </Container>
        </FormWrapper>
      </Modal>
      <Tooltip withArrow label="Назначить курьера" openDelay={500} closeDelay={100}>
        <ActionIcon color="blue" onClick={() => setOpened(true)}>
          <FaPen size={15} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}

export default observer(DeliveryAssignCourierBtn);
